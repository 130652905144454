import React, {useEffect, useState, useRef} from 'react';
import {useSnackbar} from 'notistack';
import {makeStyles} from '@mui/styles';
import {
  getUserProfile as getUserProfileRequest,
  upsertUserInterest as upsertUserInterestRequest,
} from '../util/ApiCalls/service';
import {
  Grid,
  Typography,
  Card,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  InputAdornment,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HandIcon from '../assets/images/hand.webp';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ImageList from '@mui/material/ImageList';

import './Product.css';

import PowerPergolaModelSource from '../assets/models/PowerPergola.glb';
import PowerPergolaModelPoster from '../assets/models/PowerPergola.png';

import SolaireModelSource from '../assets/models/solaire.glb';
import SolaireModelPoster from '../assets/models/solaire.png';

import Roof1 from '../assets/images/roof1.webp';
import Roof2 from '../assets/images/roof2.webp';
import Roof3 from '../assets/images/roof3.webp';

import PowerPergola1 from '../assets/images/PowerPergola/F1E1.webp';
import PowerPergola2 from '../assets/images/PowerPergola/F2E1.webp';
import PowerPergola3 from '../assets/images/PowerPergola/F3E1.webp';
import PowerPergola4 from '../assets/images/PowerPergola/F4E1.webp';
import PowerPergola5 from '../assets/images/PowerPergola/F5E1.webp';
import PowerPergola6 from '../assets/images/PowerPergola/F6E1.webp';

import Pergola1 from '../assets/images/pergola1.webp';
import Pergola2 from '../assets/images/pergola2.webp';
import Pergola3 from '../assets/images/pergola3.webp';
import Pergola4 from '../assets/images/pergola4.webp';

import PowerRoof from '../assets/images/powerroof_render.webp';
import PowerRoof2 from '../assets/images/powerroof.png';

import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {Box} from '@mui/system';

import CircularProgress from '@mui/material/CircularProgress';
import PowerGazeboWarranty from '../assets/documents/PowerGazeboWarranty.docx';

import rotateImg from '../assets/images/360View.png';
import vrImg from '../assets/images/VR-lmage.jpg';
import SliderDialog from '../components/SliderDialog';

import FileViewer from 'react-file-viewer';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import {
  getAllUserDocuments as getAllUserDocumentsRequest,
  getFileSas as getFileSasRequest,
} from '../util/ApiCalls/service.jsx';
import {setUserProfileData as setUserProfileDataAction} from '../constants/reduxActions';

const useStyle = makeStyles(() => ({
  root: {
    padding: 0,
  },
  cardRoot: {
    width: '100%',
    padding: 10,
  },
  variantCardRoot: {
    margin: 10,
    padding: 10,
    textAlign: 'center',
  },
  variantImage: {
    height: 100,
    width: '100%',
  },
}));

const Product = ({solarProducts, userProfileData, setUserProfileData}) => {
  const {variant: variantName} = useParams();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const classes = useStyle();

  const [currentImage, setCurrentImage] = useState(null);

  const [currentSkuIndex, setCurrentSkuIndex] = useState(0);

  const [variant, setVariant] = useState(null);
  const changeImage = (item, index) => {
    setCurrentImage(index);
  };

  const [warrantyDocumentDialog, setWarrantyDocumentDialog] = useState(false);

  useEffect(() => {
    if (variant && variant.sku && variant.sku.length > 0) {
      if (variant.sku[0].data_3d) {
        setCurrentImage(-1);
      } else {
        setCurrentImage(0);
      }
    }
  }, [variant]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [variant, currentImage]);

  useEffect(() => {
    if (solarProducts) {
      const tempVar =
        solarProducts.find(variant => variant.name === variantName) || null;

      setVariant(tempVar);
    }
  }, [solarProducts]);

  useEffect(() => {
    variant && variant.sku && variant.sku[currentSkuIndex].data_3d
      ? setCurrentImage(-1)
      : setCurrentImage(0);
  }, [currentSkuIndex]);

  function handleKeyDown(event) {
    if (variant && variant.sku && variant.sku.length > 0) {
      const hasAR = variant.sku[currentSkuIndex].data_3d;
      const imagesCount = variant.sku[currentSkuIndex].image.length;
      switch (event.key) {
        case 'ArrowLeft':
          // Left pressed

          if (currentImage === -1) {
            setCurrentImage(imagesCount - 1);
          } else {
            if (currentImage > 0) {
              setCurrentImage(currentImage - 1);
            }
          }

          break;
        case 'ArrowRight':
          // Right pressed

          if (currentImage + 1 < imagesCount) {
            setCurrentImage(currentImage + 1);
          } else {
            if (hasAR) {
              setCurrentImage(-1);
            } else {
              setCurrentImage(0);
            }
          }

          break;

        default:
          break;
      }
    }
  }

  const getProfileData = async () => {
    try {
      let resp = await getUserProfileRequest();
      setUserProfileData(resp.message[0]);
      return resp;
    } catch (err) {
      console.log(err);
    }
  };

  const handleRecordInterest = async (productId, skuId) => {
    try {
      enqueueSnackbar('Recording Interest');
      const result = await upsertUserInterestRequest({productId, skuId});
      await getProfileData();
      enqueueSnackbar(result.message, {variant: `success`});
    } catch (err) {
      enqueueSnackbar('Unable to record interest', {variant: `error`});
      console.log(`upsertUserInterest err`, err);
    }
  };
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <div className={classes.root}>
      {/* {variant && images[variant.subject].warrantyDocument && (
        <SliderDialog
          open={warrantyDocumentDialog}
          handleClose={() => setWarrantyDocumentDialog(false)}
          width={'80%'}
        >
          <FileViewer
            fileType={'docx'}
            filePath={images[variant.subject].warrantyDocument}
            onError={() => console.log('error')}
          />
        </SliderDialog>
        <Dialog
          fullScreen
          open={warrantyDocumentDialog}
          onClose={() => setWarrantyDocumentDialog(false)}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setWarrantyDocumentDialog(false)}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <FileViewer
            fileType={'docx'}
            filePath={images[variant.subject].warrantyDocument}
            onError={() => console.log('error')}
          />
        </Dialog>
      )} */}
      {variant ? (
        <React.Fragment>
          {variant.sku && variant.sku.length > 0 && currentImage !== null ? (
            <Grid
              container
              style={{width: '100%'}}
              alignItems="flex-start"
              spacing={3}
            >
              {/* Image container */}
              <Grid item container md={12} lg={7} alignItems={'flex-center'}>
                <Grid
                  item
                  container
                  xs={12}
                  md={12}
                  style={{width: '100%'}}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    style={{}}
                    alignItems="end"
                    justifyContent={'center'}
                    md={1}
                    xs={1}
                  >
                    <InputAdornment position="start">
                      <IconButton
                        onClick={
                          e => changeImage(e, currentImage - 1)
                          // currentImage === -1
                          //   ? changeImage(
                          //       e,
                          //       images[variant.subject].image.length - 1
                          //     )
                          //   : images[variant.subject].data_3d !== null &&
                          //     currentImage === -1
                          //   ? changeImage(
                          //       e,
                          //       images[variant.subject].image.length - 1
                          //     )
                          //   : currentImage < 1
                          //   ? changeImage(e, currentImage)
                          //   : changeImage(e, currentImage - 1)
                        }
                        style={{padding: 0}}
                        color="secondary"
                        disabled={
                          variant.sku[currentSkuIndex].data_3d &&
                          currentImage === -1
                            ? true
                            : currentImage === 0
                            ? true
                            : false
                        }
                      >
                        <ArrowBackIosNewIcon fontSize="large" />
                      </IconButton>
                    </InputAdornment>
                  </Grid>
                  <Grid
                    item
                    container
                    md={10}
                    xs={10}
                    justifyContent={'center'}
                  >
                    {currentImage === -1 ? (
                      variant.sku[currentSkuIndex].data_3d && (
                        <Grid
                          sx={{
                            height: {xs: 200, md: 400, sm: '200px'},
                            width: '100%',
                          }}
                        >
                          <model-viewer
                            src={
                              variant.sku[currentSkuIndex].data_3d
                                ? variant.sku[currentSkuIndex].data_3d.model
                                : ''
                            }
                            // ios-src={PowerPergolaModelSourceUsdz}
                            poster={
                              variant.sku[currentSkuIndex].data_3d
                                ? variant.sku[currentSkuIndex].data_3d.poster
                                : ''
                            }
                            camera-controls
                            alt="A 3D model carousel"
                            shadow-intensity="1"
                            ar
                            ar-modes="webxr scene-viewer quick-look"
                            camera-orbit="90deg "
                          >
                            <button slot="ar-button" id="ar-button">
                              View in 3D
                            </button>

                            <div id="ar-prompt">
                              <img src={HandIcon} />
                            </div>

                            <button id="ar-failure">
                              Please use a spacious outdoor environment to view
                              the model properly
                            </button>
                          </model-viewer>
                        </Grid>
                      )
                    ) : variant.sku[currentSkuIndex].image.length > 0 ? (
                      <img
                        src={
                          variant.sku[currentSkuIndex].image.length > 0
                            ? variant.sku[currentSkuIndex].image[currentImage]
                                .image_url
                            : 'No Images upladed'
                        }
                        sx={{height: {xs: 200, md: 400, lg: 400}}}
                        style={{
                          width: '100%',
                          transform: '1000ms',
                          transition: 'rotate(180deg)',
                        }}
                      />
                    ) : (
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{height: 200, width: '100%'}}
                      >
                        <Grid>
                          <Grid
                            justifyContent={'center'}
                            sx={{display: 'flex', justifyContent: 'center'}}
                          >
                            <PanoramaOutlinedIcon
                              sx={{
                                fontSize: '5rem',
                              }}
                              color='primary'
                            />
                          </Grid>

                          <Grid justifyContent={'center'}>
                            {' '}
                            <Typography
                              variant="h4"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                              color='primary'
                            >
                              No Images Available
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    style={{}}
                    md={1}
                    xs={1}
                    alignItems="flex-end"
                    justifyContent={'center'}
                  >
                    <InputAdornment position="end">
                      <IconButton
                        onClick={
                          e => changeImage(e, currentImage + 1)
                          // images[variant.subject].ar !== null &&
                          // currentImage === images[variant.subject].image.length - 1
                          //   ? changeImage(e, -1)
                          //   : currentImage ===
                          //     images[variant.subject].image.length - 1
                          //   ? changeImage(e, 0)
                          //   : currentImage >
                          //     images[variant.subject].image.length - 2
                          //   ? changeImage(e, currentImage)
                          //   : changeImage(e, currentImage + 1)
                        }
                        style={{padding: 0}}
                        color="secondary"
                        disabled={
                          currentImage ===
                          variant.sku[currentSkuIndex].image.length - 1
                        }
                        size="large"
                      >
                        <ArrowForwardIosIcon fontSize="large" />
                      </IconButton>
                    </InputAdornment>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{width: '100%', marginTop: 10}}
                  justifyContent="center"
                >
                  <ImageList
                    sx={{
                      gridAutoFlow: 'column',
                      gridTemplateColumns: {
                        md: 'repeat(auto-fit, minmax(130px,1fr)) !important',
                        lg: 'repeat(auto-fit, minmax(130px,1fr)) !important',
                        xs: 'repeat(auto-fit, minmax(85px,1fr)) !important',
                      },
                      gridAutoColumns: {
                        md: 'minmax(130px, 1fr)',
                        lg: 'minmax(130px, 1fr)',
                        xs: 'minmax(85px, 1fr)',
                      },
                    }}
                  >
                    {variant.sku[currentSkuIndex].data_3d && (
                      <Card
                        onClick={() =>
                          openInNewTab(
                            `https://kuula.co/share/collection/795vR?logo=0&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1`
                          )
                        }
                        style={{
                          cursor: 'pointer',
                          // border: '#92c1f1 5px solid',
                          // margin: 5,
                          // padding: 5,
                          // display: 'flex',
                          // alignItems: 'center',

                          // transition: 'transform .2s',

                          // transform: `scale(1.05)`,
                        }}
                        sx={{
                          height: {md: 80, xs: 60},
                          width: {md: 120, xs: 80},
                        }}
                      >
                        <img
                          src={vrImg}
                          style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </Card>
                    )}
                    {variant.sku[currentSkuIndex].data_3d && (
                      <Card
                        onClick={() => changeImage(null, -1)}
                        style={{
                          cursor: 'pointer',
                          border:
                            -1 === currentImage ? '#92c1f1 5px solid' : 'none',
                          margin: 5,
                          padding: -1 === currentImage ? 0 : 5,
                          display: 'flex',
                          alignItems: 'center',

                          transition: 'transform .2s',

                          transform:
                            -1 === currentImage ? `scale(1.05)` : 'none',
                        }}
                        sx={{
                          height: {md: 80, xs: 60},
                          width: {md: 120, xs: 80},
                        }}
                      >
                        <img
                          src={rotateImg}
                          style={{
                            height: '60%',
                            width: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </Card>
                    )}

                    {variant.sku[currentSkuIndex].image &&
                      variant.sku[currentSkuIndex].image.map((item, index) => {
                        return (
                          <Card
                            onClick={() => changeImage(item, index)}
                            style={{
                              cursor: 'pointer',
                              border:
                                index === currentImage
                                  ? '#D2D2D7 5px solid'
                                  : 'none',
                              margin: 5,
                              transition: 'transform .2s',
                              padding: index === currentImage ? 0 : 5,
                              display: 'flex',
                              alignItems: 'center',
                              transform:
                                index === currentImage
                                  ? `scale(1.1,1.1)`
                                  : 'none',
                            }}
                            sx={{
                              height: {md: 80, xs: 60},
                              width: {md: 120, xs: 80},
                            }}
                          >
                            <img
                              src={item.image_url}
                              style={{
                                height: '100%',
                                width: '100%',
                              }}
                            />
                          </Card>
                        );
                      })}
                  </ImageList>
                </Grid>
              </Grid>

              {/* Details container */}
              <Grid item md={12} lg={5}>
                <Typography
                  variant="h1"
                  color="primary"
                >{`${variant.name}`}</Typography>

                <Typography variant="h3" mt={1} color="primary">
                  {variant.description}
                </Typography>

                <Typography
                  variant="h3"
                  mt={5}
                  sx={{fontWeight: 600}}
                  color="primary"
                >
                  {'Available sizes'}
                </Typography>

                <FormControl>
                  <RadioGroup
                    value={currentSkuIndex}
                    onChange={e => {
                      setCurrentSkuIndex(e.target.value);
                    }}
                  >
                    {variant &&
                      variant.sku.map((item, index) => (
                        <FormControlLabel
                          sx={{color: '#000'}}
                          value={index}
                          control={<Radio />}
                          label={item.sku_name + ' - ' + item.description}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>

                <Grid container sx={{width: 1, mt: 1}}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleRecordInterest(
                        variant.product_ID,
                        variant.sku[currentSkuIndex].sku_ID
                      )
                    }
                    disabled={
                      userProfileData &&
                      userProfileData.productInterest &&
                      userProfileData.productInterest.filter(
                        item =>
                          item.SKU_ID === variant.sku[currentSkuIndex].sku_ID
                      ).length > 0
                    }
                  >
                    I'm interested
                  </Button>
                </Grid>

                {/* {images[variant.subject].warrantyDocument && (
          <Button
            sx={{
              mt: 3,
              color: 'black',
              textTransform: 'initial',
              textDecoration: 'underline',
              fontSize: '1.2rem',
            }}
            onClick={() => setWarrantyDocumentDialog(true)}
          >
            <b> View Warranty Information</b>
          </Button>
        )} */}
              </Grid>
            </Grid>
          ) : (
            <Card sx={{width: 1, p: 5}}>
              <Grid
                container
                sx={{width: 1}}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h3">
                  No Variants available for selected product
                </Typography>
              </Grid>
            </Card>
          )}
        </React.Fragment>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={400}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </div>
  );
};
const mapStateToProps = state => {
  const {solarProducts, userProfileData} = state.app;
  return {solarProducts, userProfileData};
};
const mapDispatchToProps = dispatch => ({
  setUserProfileData: userProfileData => {
    dispatch({type: setUserProfileDataAction, data: {userProfileData}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
