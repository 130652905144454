import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {
  Card,
  Grid,
  Typography,
  CardMedia,
  Button,
  CircularProgress,
} from '@mui/material';
import {useHistory} from 'react-router-dom';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import Roof from '../assets/images/roof1.webp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Product from './Product';

import PergolaModelSource from '../assets/models/PowerPergola.glb';
import PergolaModelPoster from '../assets/models/PowerPergola.png';
import SolaireModelSource from '../assets/models/solaire.glb';
import SolaireModelPoster from '../assets/models/solaire.png';

import Roof1 from '../assets/images/powerroof_render.webp';
import Roof2 from '../assets/images/roof2.webp';
import Roof3 from '../assets/images/roof3.webp';

import PowerPergola1 from '../assets/images/PowerPergola/F1E1.webp';

import Pergola1 from '../assets/images/ComingSoon.webp';
import Pergola2 from '../assets/images/pergola2.webp';
import Pergola3 from '../assets/images/pergola3.webp';
import Pergola4 from '../assets/images/pergola4.webp';
import Pergola5 from '../assets/images/pergola5.webp';
import Pergola6 from '../assets/images/pergola6.webp';
import Pergola7 from '../assets/images/pergola7.webp';
import {connect} from 'react-redux';
import {Box} from '@mui/system';
import ScheduleSurveyAndPurchaseCard from '../components/ScheduleSurveyAndPurchaseCard';
import redirectToPage from '../util/UtilFunctions/redirectToPage';

import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';

const useStyle = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  paddedContainers: {
    padding: '15px 0px 15px 0px',
  },
  paddedContainers2: {
    width: '100%',
    padding: '30px 0px 30px 0px',
  },

  card: {
    position: 'relative',
    cursor: 'pointer',
    height: '200px',
  },
  media: {
    // background: 'linear-gradient(90deg, rgba(255, 102, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 100%)',
  },
  colorOverlay: {
    position: 'absolute',
    top: '0px',
    // background: 'linear-gradient(90deg, rgba(255, 102, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 100%)',
    height: '100%',
    width: '100%',
  },
  playButtonOverlay: {
    position: 'absolute',
    top: '40%',
    left: '45%',
  },
  overlay: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.58)',
    width: '100%',
    height: '25%',
    padding: '0px 10px 10px 10px',
    [theme.breakpoints.down('md')]: {
      height: '35%',
    },
  },
}));

const images = {
  PowerRoof: {
    image: [Roof1, Roof2, Roof3],
    ar: null,
  },
  PowerGazebo: {
    image: [PowerPergola1, Pergola2, Pergola3, Pergola4],
    ar: {
      model: PergolaModelSource,
      poster: PergolaModelPoster,
    },
  },
  GazeboSolaire: {
    image: [Pergola1, Pergola1, Pergola3, Pergola4],
    ar: {
      model: SolaireModelSource,
      poster: SolaireModelPoster,
    },
  },
};

const Products = ({solarProducts, setAppBarPage}) => {
  const classes = useStyle();
  const history = useHistory();

  let {path} = useRouteMatch();

  const bookSurveyHandler = () => {
    redirectToPage(history, 'bookSurvey', '/bookSurvey', setAppBarPage);
  };

  const selectProduct = product => {
    console.log(
      history,
      path.split('/')[1],
      path + '/' + product.name,
      setAppBarPage
    );
    redirectToPage(
      history,
      path.split('/')[1],
      path + '/' + product.name,
      setAppBarPage
    );
  };

  return (
    <>
      {solarProducts ? (
        <Grid container style={{width: '100%'}}>
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Typography
              variant="h1"
              style={{
                lineHeight: '1.5',
                marginBottom: '1rem',
                color: '#000000',
              }}
            >
              Our Offerings
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Typography
              variant="h3"
              style={{lineHeight: '1.75', color: '#000000'}}
            >
              Learn more about our solar solutions and let us know which ones
              you are interested in.
            </Typography>
          </Grid>

          <Grid item container xs={12} alignItems="stretch">
            {solarProducts.map((variant, index) => {
              return (
                <Grid
                  item
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{p: {md: 2}, mt: {xs: 1, md: 0}}}
                  key={index}
                >
                  <Card sx={{p: 2, height: 1}}>
                    {variant.cover_image ? (
                      <img
                        // component="img"
                        height="200"
                        width="100%"
                        src={variant.cover_image}
                        alt="Paella dish"
                        className={classes.media}
                      />
                    ) : (
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{height: 200, width: '100%'}}
                      >
                        <Grid>
                          <Grid
                            justifyContent={'center'}
                            sx={{display: 'flex', justifyContent: 'center'}}
                          >
                            <PanoramaOutlinedIcon
                              sx={{
                                fontSize: '5rem',
                              }}
                            />
                          </Grid>

                          <Grid justifyContent={'center'}>
                            {' '}
                            <Typography
                              variant="h4"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                              color='primary'
                            >
                              No Images Available
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container sx={{width: 1}} alignItems={'flex-end'}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h2"
                          sx={{mt: 2, textTransform: 'capitalize'}}
                        >
                          {variant.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          sx={{mt: 2, fontWeight: 300, height: 50}}
                        >
                          {variant.description}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        sx={{width: 1, height: '100%', mt: 2}}
                        justifyContent="center"
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => selectProduct(variant)}
                          disabled={variant.name === 'GazeboSolaire'}
                          sx={{my: 2}}
                        >
                          Learn More
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {process.env.REACT_APP_SKIP_SITE_SURVEY === 'NO' && (
            <Grid item container xs={12} lg={4} sx={{p: 2}}>
              <ScheduleSurveyAndPurchaseCard />
            </Grid>
          )}
        </Grid>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={400}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}{' '}
    </>
  );
};

const mapStateToProps = state => {
  const {solarProducts} = state.app;
  return {solarProducts};
};
const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
