import React, {useEffect, useState} from 'react';
import DocumentIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/SystemUpdateAlt';
import AddIcon from '@mui/icons-material/Add';
import {
  Grid,
  Typography,
  Divider,
  Link,
  Button,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Skeleton,
} from '@mui/material';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import {connect} from 'react-redux';
import {makeStyles} from '@mui/styles';
import {set} from 'date-fns/esm';
import {
  getAllUserDocuments as getAllUserDocumentsRequest,
  getFileSas as getFileSasRequest,
} from '../util/ApiCalls/service';

import ScheduleSurveyAndPurchaseCard from '../components/ScheduleSurveyAndPurchaseCard';

const useStyle = makeStyles(theme => ({
  paddedContainers: {
    padding: '2rem 2rem 1rem 2rem',
  },
  root: {
    background: theme.palette.background.main,
    padding: 10,
    overflow: 'auto',
  },
}));

const Documents = props => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const [token, setToken] = useState(null);
  const classes = useStyle();

  const [documentsData, setDocumentsData] = useState();

  const [currentDocument, setCurrentDocument] = useState(null);

  const [noDocsFlag, setNoDocsFlag] = useState(false);

  useEffect(() => {
    if (props.userAuthToken && !documentsData) {
      getAllUserDocumentsRequest()
        .then(resp => {
          const docTypes = resp.message.allDocuments;
          const userDocs = resp.message.userCurrentDocument;

          if (userDocs.length < 1) {
            setNoDocsFlag(true);
          }

          let comboData = [];

          docTypes.map(type => {
            let tempDocs = userDocs.filter(
              doc => type.document_type_ID === doc.document_type_ID
            );
            comboData = [
              ...comboData,
              {
                ...type,
                userDocs: tempDocs,
              },
            ];
          });


          setDocumentsData(comboData.length ? comboData : []);
        })
        .catch(err => console.log(err));
    }
  });

  const downloadFile = async fileName => {
    try {
      const fileSas = await getFileSasRequest(fileName);
      window.location.assign(fileSas);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={classes.root}>
      {currentDocument && (
        <Dialog
          open={currentDocument ? true : false}
          onClose={() => setCurrentDocument(null)}
          maxWidth={'xl'}
          style={{width: '100%'}}
        >
          <DialogTitle id="alert-dialog-title">
            <Grid container>
              <DocumentIcon fontSize="large" color="primary" />
              <Typography variant="h1">{currentDocument.subject}</Typography>
              {/* <DownloadIcon fontSize="large" color="primary" /> */}
            </Grid>
          </DialogTitle>
          <Divider
            width="100%"
            color="primary"
            style={{
              borderWidth: 2,
              backgroundColor: 'rgb(128,128,128)',
              borderColor: 'rgb(128,128,128)',
            }}
          />
          <DialogContent>
            <Grid
              container
              style={{width: '100%', maxWidth: '900px'}}
              justifyContent="space-around"
            >
              {currentDocument.userDocs.map(doc => {
                console.log({doc});
                return (
                  <Grid item container xs={12}>
                    <DocumentIcon fontSize="large" color="primary" />
                    <Button onClick={() => downloadFile(doc.file_name)}>
                      {doc.file_display_name
                        ? doc.file_display_name
                        : doc.file_name}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setCurrentDocument(null)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Grid container style={{width: '100%'}} spacing={0}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            style={{marginBottom: '1rem'}}
            color={'#000000'}
          >
            Your Documents
          </Typography>
        </Grid>

        {noDocsFlag ? (
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Typography
              variant="h3"
              style={{marginBottom: '1rem'}}
              color={'#000000'}
            >
              There are no documents to share right now.
            </Typography>
            {process.env.REACT_APP_SKIP_SITE_SURVEY === 'NO' && (
              <ScheduleSurveyAndPurchaseCard />
            )}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{marginBottom: '1rem'}}
              color="primary"
            >
              Here are all the documents you have uploaded, received and
              e-signed
            </Typography>
          </Grid>
        )}
      </Grid>

      {/*   uploading screen removed temporarily */}
      {false && (
        <Grid item container xs={12}>
          <Card style={{width: '100%'}}>
            <Grid
              container
              justifyContent="space-evenly"
              style={{paddingBottom: '2rem'}}
            >
              <Grid item container xs={12} className={classes.paddedContainers}>
                <Typography
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    marginBottom: '0.75rem',
                  }}
                  variant="h3"
                >
                  Documents you currently need to upload and e sign
                </Typography>
                <Typography
                  style={{width: '100%', textAlign: 'center'}}
                  variant="h4"
                >
                  You can upload your documents or images by importing from your
                  computer
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                spacing={0}
                className={classes.paddedContainers}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  justifyContent="center"
                  style={{margin: '1rem 0'}}
                >
                  <Grid
                    item
                    container
                    xs={10}
                    style={{
                      border: '3px dashed lightblue',
                      padding: '1rem 0',
                      borderRadius: '1rem',
                    }}
                  >
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                      <DocumentIcon fontSize="large" color="secondary" />
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                      <Typography variant="h3">
                        Loan Agreement Document
                      </Typography>
                      <Typography variant="h4">E-sign Required</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={10}
                    justifyContent="center"
                    style={{marginTop: '2rem'}}
                  >
                    <Button variant="contained" color="secondary" size="large">
                      Sign
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  justifyContent="center"
                  style={{margin: '1rem 0'}}
                >
                  <Grid
                    item
                    container
                    xs={10}
                    style={{
                      border: '3px dashed lightblue',
                      padding: '1rem 0',
                      borderRadius: '1rem',
                    }}
                  >
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                      <DocumentIcon fontSize="large" color="secondary" />
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                      <Typography variant="h3">Housing Documents</Typography>
                      <Typography variant="h4">Upload Document</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={10}
                    justifyContent="center"
                    style={{marginTop: '2rem'}}
                  >
                    <Button variant="contained" color="secondary" size="large">
                      Upload
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}

      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        style={{marginTop: '2rem'}}
      >
        <Grid item container xs={12} justifyContent="flex-start">
          {documentsData
            ? documentsData
                .slice(0)
                .reverse()
                .map((document, i) => {
                  if (document.userDocs.length)
                    return (
                      <Grid
                        item
                        sm={12}
                        md={6}
                        lg={4}
                        style={{padding: 10, minWidth: 300}}
                        key={i}
                      >
                        <Card
                          className={classes.paddedContainers}
                          sx={{maxHeight: 200}}
                        >
                          <Grid container>
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent="space-between"
                              style={{padding: '1rem 0'}}
                            >
                              <DocumentIcon
                                fontSize="large"
                                color="secondary"
                              />
                              {/* <IconButton>
                          <AddIcon color="primary" />
                        </IconButton> */}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="h3">
                                {document.subject}
                              </Typography>
                            </Grid>

                            {/* <Grid item xs={12}>
                          <Typography variant="h5">
                            {document.description}
                          </Typography>
                        </Grid> */}
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent="space-between"
                              style={{padding: '1rem 0'}}
                            >
                              <Button
                                color="secondary"
                                variant="contained"
                                disabled={document.userDocs.length === 0}
                                onClick={() => setCurrentDocument(document)}
                              >
                                Download
                              </Button>
                              {/* <DownloadIcon fontSize="large" color="secondary" /> */}
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  else return null;
                })
            : [0, 1, 2].map((item, index) => {
                return (
                  <Grid
                    item
                    sm={12}
                    md={6}
                    lg={4}
                    style={{padding: 10, minWidth: 300}}
                    key={index}
                  >
                    <Card className={classes.paddedContainers}>
                      <Grid container>
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent="space-between"
                          style={{padding: '1rem 0'}}
                        >
                          <DocumentIcon fontSize="large" color="secondary" />

                          <Skeleton variant="text" sx={{mt: 1, width: 1}} />
                          <Skeleton variant="text" sx={{mt: 1, width: 1}} />
                          <Skeleton
                            variant="rectangular"
                            width={'100%'}
                            height={50}
                            sx={{mt: 1}}
                          />
                          <Skeleton
                            variant="rectangular"
                            width={'10%'}
                            height={25}
                            sx={{mt: 1}}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                );
              })}
        </Grid>

        {/* <Divider width='100%' color='primary' style={{ borderWidth: 2, backgroundColor: 'rgb(128,128,128)', borderColor: 'rgb(128,128,128)' }} />

                <Grid container style={{ padding: 50 }}>
                    {documentData.documents.map(item => {
                        return (<Grid item xs={12} style={{ marginTop: 10 }}>
                            <Link href="#" variant="h2">
                                {item}
                            </Link>
                        </Grid>)
                    })}
                </Grid> */}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {userAuthToken};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
