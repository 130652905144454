import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {
  Divider,
  Grid,
  Typography,
  Card,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  Box,
  Tabs,
  Tab,
  DialogActions,
  DialogContent,
  Skeleton,
  Alert,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import {useHistory} from 'react-router-dom';
import PowerRoof from '../assets/images/powerroof_render.webp';
import PowerGazebo from '../assets/images/powergazebo_render.webp';
import GazeboSolaire from '../assets/images/ComingSoon.webp';
import {connect} from 'react-redux';
import {useSnackbar} from 'notistack';
import {
  getPriceQuote as getPriceQuoteRequest,
  createOrder as createOrderRequest,
  getDesignLink as getDesignLinkRequest,
  sendContract,
  rejectQuote as rejectQuoteRequest,
  getProposalDataWithHistory as getProposalDataWithHistoryRequest,
} from '../util/ApiCalls/service';
import ProgressDialog from '../components/ProgressDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {DateTime} from 'luxon';
import redirectToPage from '../util/UtilFunctions/redirectToPage';

import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import {numericNullChecker} from '../util/UtilFunctions/nullCheckers';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  getAllUserDocuments as getAllUserDocumentsRequest,
  getFileSas as getFileSasRequest,
} from '../util/ApiCalls/service.jsx';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import {styled} from '@mui/material/styles';

const useStyle = makeStyles(theme => ({
  root: {
    padding: 10,
    [theme.breakpoints.down('md')]: {
      padding: '5px 2px',
    },
  },
  cardRoot: {
    width: '100%',
    margin: '10px 25% 10px 25%',
    padding: 10,
  },
  imageCardRoot: {
    width: '100%',
    padding: 10,
  },
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const systemData = [
  {
    size: 7.8,
    systemDetails: {
      systemProduction: 284515,
      systemSaving: 64.5,
      consumptionOffset: 2.2,
      paybackPeriod: 6.3,
    },
    systemCostDetails: {
      systemCost: 27300,
      fedralTaxCredit: -8190,
    },
    taxRebatesAndIncentives: {
      nationalIncentive: 8190,
      localIncentive: 0,
    },
    savings: {
      currentMonthlyBillWithSolar: 96,
      currentMonthlyBillWithoutSolar: 343,
      estimatedMontlyBillWithSolar: 108,
      estimatedMontlyBillWithoutSolar: 386000,
    },
    environmentalImpact: {
      oilBarrels: 496,
      treesPlanted: 5474,
      acresOfForest: 17.99,
    },
  },
  {
    size: 8.8,
    systemDetails: {
      systemProduction: 284515,
      systemSaving: 64.5,
      consumptionOffset: 2.2,
      paybackPeriod: 6.3,
    },
    systemCostDetails: {
      systemCost: 27300,
      fedralTaxCredit: -8190,
    },
    taxRebatesAndIncentives: {
      nationalIncentive: 8190,
      localIncentive: 0,
    },
    savings: {
      currentMonthlyBillWithSolar: 96,
      currentMonthlyBillWithoutSolar: 343,
      estimatedMontlyBillWithSolar: 108,
      estimatedMontlyBillWithoutSolar: 386000,
    },
    environmentalImpact: {
      oilBarrels: 496,
      treesPlanted: 5474,
      acresOfForest: 17.99,
    },
  },
  {
    size: 9.8,
    systemDetails: {
      systemProduction: 284515,
      systemSaving: 64.5,
      consumptionOffset: 2.2,
      paybackPeriod: 6.3,
    },
    systemCostDetails: {
      systemCost: 27300,
      fedralTaxCredit: -8190,
    },
    taxRebatesAndIncentives: {
      nationalIncentive: 8190,
      localIncentive: 0,
    },
    savings: {
      currentMonthlyBillWithSolar: 96,
      currentMonthlyBillWithoutSolar: 343,
      estimatedMontlyBillWithSolar: 108,
      estimatedMontlyBillWithoutSolar: 386000,
    },
    environmentalImpact: {
      oilBarrels: 496,
      treesPlanted: 5474,
      acresOfForest: 17.99,
    },
  },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PriceQuoteAndContract = props => {
  const classes = useStyle();
  const history = useHistory();
  console.log({history});
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const [quoteData, setQuoteData] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [selectedQuote, setSelectedQuote] = useState();

  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const quoteType = '';
  const SystemDetails = () => {
    const currentSystem = systemData[tabValue];
    return (
      <Grid container style={{width: '100%'}}>
        {/* System details */}
        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{padding: 10, marginTop: 10}}
        >
          <Typography variant="h3">
            Overview of the system and price breakdown
          </Typography>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">System Production </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {currentSystem.systemDetails.systemProduction + 'kW'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">System Saving </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {currentSystem.systemDetails.systemSaving + 'kW'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">Consumption Offset </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {currentSystem.systemDetails.consumptionOffset + 'kW'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">Payback Period </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {currentSystem.systemDetails.paybackPeriod + ' years'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{width: '100%'}} />

        {/* System Cost */}
        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{padding: 10, marginTop: 10}}
        >
          <Typography variant="h3">Cost Details</Typography>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">System Cost </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {'$ ' + currentSystem.systemCostDetails.systemCost}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">Federal Tax Credit </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {'$ ' + currentSystem.systemCostDetails.fedralTaxCredit}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">Net System Cost </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {'$ ' +
                  (currentSystem.systemCostDetails.systemCost +
                    currentSystem.systemCostDetails.fedralTaxCredit)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{width: '100%'}} />

        {/* Tax rebatest and incentives */}
        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{padding: 10, marginTop: 10}}
        >
          <Typography variant="h3">Tax Rebates and Incentives</Typography>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">National Incentive </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {'$ ' + currentSystem.taxRebatesAndIncentives.nationalIncentive}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">Local Incentive </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {'$ ' + currentSystem.taxRebatesAndIncentives.localIncentive}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">
                Total Amount you save from incentives{' '}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {'$ ' +
                  (currentSystem.taxRebatesAndIncentives.nationalIncentive +
                    currentSystem.taxRebatesAndIncentives.localIncentive)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{width: '100%'}} />

        {/*  with and without solar grid */}
        <Grid
          item
          container
          xs={12}
          spacing={0}
          style={{padding: 10, textAlign: 'center', marginTop: 10}}
        >
          <Typography variant="h3">Your Savings With Solar</Typography>
          <Grid item container xs={12}>
            <Grid
              item
              container
              xs={6}
              style={{
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <Grid item xs={12} style={{padding: 10}}>
                <Typography variant="h3" color="primary">
                  {'With Solar'}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{padding: 10}}>
                <Typography variant="h5" color="primary">
                  {'$ ' + currentSystem.savings.currentMonthlyBillWithSolar}
                </Typography>
                <Typography variant="h5">{'Monthly Utility Bill'}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{borderBottom: '1px solid black'}}
            >
              <Grid item xs={12} style={{padding: 10}}>
                <Typography variant="h3">{'Without Solar'}</Typography>
              </Grid>
              <Grid item xs={12} style={{padding: 10}}>
                <Typography variant="h5">
                  {'$ ' + currentSystem.savings.currentMonthlyBillWithoutSolar}
                </Typography>
                <Typography variant="h5">{'Monthly Utility Bill'}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              style={{borderRight: '1px solid black', padding: 10}}
            >
              <Typography variant="h5" color="primary">
                {'$ ' + currentSystem.savings.estimatedMontlyBillWithSolar}
              </Typography>
              <Typography variant="h5">
                {'Est. monthly Utility Bill in 25 years'}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{padding: 10}}>
              <Typography variant="h5">
                {'$ ' + currentSystem.savings.estimatedMontlyBillWithoutSolar}
              </Typography>
              <Typography variant="h5">
                {'Est. monthly Utility Bill in 25 years'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{width: '100%'}} />

        {/* Environmental Impact */}
        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{padding: 10, marginTop: 10}}
        >
          <Typography variant="h3">Your Environmental Impact</Typography>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">National Incentive </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {currentSystem.environmentalImpact.oilBarrels +
                  ' Barrels of Oil'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">Local Incentive </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {currentSystem.environmentalImpact.treesPlanted +
                  ' Trees Planted'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">
                Total Amount you save from incentives{' '}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {currentSystem.environmentalImpact.acresOfForest +
                  ' Acres of Forest Planted'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Disclamer */}
        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{padding: 10, marginTop: 10}}
        >
          <Typography variant="h6">
            {
              '*You price and system cost could increase upto 10% of the cost based on roof damages or complexity. Your final  price will be provided after the site visit'
            }
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{padding: 10, marginTop: 10}}
        >
          <Button
            variant="outlined"
            onClick={() =>
              redirectToPage(
                history,
                'documents',
                '/documents',
                props.setAppBarPage
              )
            }
          >
            View {quoteType} Quote Documents
          </Button>
        </Grid>
      </Grid>
    );
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = event => {
    setSelectedQuote(event.target.value);
  };

  const formatContractData = (quotationData, customerData, orderId) => {
    const {
      salutation,
      email,
      gmaps_address_string,
      first_name,
      middle_name,
      surname,
      mobile_telephone_number,
    } = customerData;
    let customerName = '';
    if (salutation) {
      customerName = customerName + ' ' + salutation;
    }
    if (first_name) {
      customerName = customerName + ' ' + first_name;
    }
    if (middle_name) {
      customerName = customerName + ' ' + middle_name;
    }
    if (surname) {
      customerName = customerName + ' ' + surname;
    }

    const date = DateTime.now().toFormat('dd/LL/yyyy');

    const data = {
      signerEmail: email,
      signerName: customerName,
      documentId: 'DOCTYPE000002',

      customerOid: customerData.individual_ID,

      ownerOid: customerData.individual_ID,

      order_ID: orderId,
      status: 'sent',
      fields: {
        customer_type: 'Individual Customer',
        contract_date: `${date}`,
        customer_name: customerName,
        customer_phone_number: mobile_telephone_number,
        customer_billing_address: gmaps_address_string,
        customer_email: email,
        customer_pan: '',
        customer_shipping_address: gmaps_address_string,
        customer_GSTIN: '',
        solar_syatem_capacity: `${quotationData.system_size} kWp`,
        solar_ac_capacity: '0',
        expected_generation: `${quotationData.annual_energy_production} kWh`,
        system_price: `${
          props.currencyFormatter
            ? quotationData.amount.toFixed(2)
            : quotationData.amount.toFixed(2)
        }`,
      },
    };

    return data;
  };

  const getQuotes = () => {
    // getPriceQuoteRequest()
    //   .then(resp => {
    //     // setQuoteData(resp.message);
    //   })
    //   .catch(err => console.log(err));
    getProposalDataWithHistoryRequest(false)
      .then(resp => {
        setQuoteData(resp.message.quotes);
      })
      .catch(err => console.log(err));
  };

  const selectQuote = async quote => {
    enqueueSnackbar('Selecting Quote');
    setOpenProgressDialog(true);
    try {
      let selectQuoteData = await createOrderRequest({
        price_quote_ID: quote.price_quote_ID,
      });
      enqueueSnackbar(
        `Created Order with ID :${selectQuoteData.message.order_ID}`,
        {variant: 'success'}
      );

      let tempQuoteData = quoteData;
      tempQuoteData.map(i => {
        if (quote.price_quote_ID === i.price_quote_ID)
          i.order_ID = selectQuoteData.message.order_ID;
      });

      setQuoteData(tempQuoteData);

      const contractTemplateData = formatContractData(
        quote,
        props.userProfileData,
        selectQuoteData.message.order_ID
      );

      let contractResult = await sendContract(contractTemplateData);

      setOpenProgressDialog(false);
    } catch (err) {
      console.log(err);
      setOpenProgressDialog(false);
      enqueueSnackbar('Error while selecting quote', {variant: 'error'});
    }
  };

  const downloadDesignLink = async design_ID => {
    enqueueSnackbar('Fetching Design Report');
    setOpenProgressDialog(true);
    try {
      const designLinkData = await getDesignLinkRequest({design_ID});
      setOpenProgressDialog(false);
      enqueueSnackbar('Fetch Successful', {variant: 'success'});
      window.open(designLinkData.message.designReportLink, '_blank');
    } catch {
      enqueueSnackbar('File not found', {variant: 'error'});
      setOpenProgressDialog(false);
    }
  };

  const rejectQuote = async price_quote_ID => {
    enqueueSnackbar('Selecting Quote');
    setOpenProgressDialog(true);
    try {
      const rejectQuoteData = await rejectQuoteRequest(price_quote_ID);
      enqueueSnackbar('Quote Rejected', {variant: 'success'});
      setOpenProgressDialog(false);
      getQuotes();
    } catch (err) {
      enqueueSnackbar('Error while rejecting quote', {variant: 'error'});
      setOpenProgressDialog(false);
    }
  };

  useEffect(() => {
    if (props.userAuthToken && !quoteData) {
      getQuotes();
    }
  });

  const downloadFile = async fileName => {
    try {
      const fileSas = await getFileSasRequest(fileName);
      window.open(fileSas, '_blank');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'md'}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h1">{quoteType + ' Quote'}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container style={{width: '100%'}} justifyContent="space-around">
            <Grid item xs={12}>
              <Typography variant="h3">{'Select a System Size'}</Typography>

              <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                  value={tabValue}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  {systemData.map((item, index) => {
                    return (
                      <Tab
                        key={index}
                        label={item.size}
                        {...a11yProps(index)}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              <SystemDetails />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenDialog(false)}
          >
            {quoteType === 'Prelimnary'
              ? 'Get a Custom Quote'
              : 'Proceed to Financing'}
          </Button>
        </DialogActions>
      </Dialog>

      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />
      <Grid container style={{width: '100%'}}>
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Typography variant="h1" color={'#000000'}>
            PRICE QUOTE AND CONTRACT
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          mt={2}
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Typography variant="h3" color="#000000">
            You are only a short way from meeting your energy needs with Arka’s
            solar solutions.
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          mt={1}
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Typography variant="h3" color="#000000">
            Based on our expert’s survey of your property, we have prepared a
            detailed design and price quote for you.
          </Typography>
        </Grid>

        {quoteData && props.currencyFormatter ? (
          <Grid item container xs={12}>
            {quoteData.length > 0 ? (
              quoteData.map(quote => {
                if (
                  !quote.rejected ||
                  history.location.pathname === '/priceQuote'
                ) {
                  return (
                    <Grid
                      item
                      container
                      xs={12}
                      key={quote.price_quote_ID}
                      mt={3}
                      justifyContent="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item container xs={12}>
                        <Card sx={{width: 1, px: 2, py: 1}}>
                          <Grid container justifyContent={'space-between'}>
                            <Grid>
                              <Typography
                                variant="h2"
                                sx={{mt: 1, textTransform: 'uppercase'}}
                              >
                                {quote.price_quote_ID}
                              </Typography>
                            </Grid>
                            {/* {quote.file_display_name && (
                              <Grid>
                                {console.log(quote)}
                                <Typography variant="h2" sx={{mt: 1}}>
                                  {quote.file_display_name}
                                  <span>
                                    {' '}
                                    <Button
                                      color="secondary"
                                      onClick={() =>
                                        downloadFile(quote.file_name)
                                      }
                                    >
                                      <FileDownloadIcon />
                                    </Button>
                                  </span>
                                </Typography>
                              </Grid>
                            )} */}
                          </Grid>

                          <Grid container sx={{width: 1, mt: 2}}>
                            {quote.lineItems && props.solarProducts ? (
                              quote.lineItems.map((lineItem, lineItemIndex) => {
                                return (
                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    key={lineItemIndex}
                                  >
                                    <Accordion
                                      style={{
                                        width: '100%',
                                        background: '#c3c3c3',
                                      }}
                                    >
                                      <AccordionSummary
                                        expandIcon={
                                          <ExpandMoreIcon color="primary" />
                                        }
                                      >
                                        <Typography variant="h3">
                                          {lineItem.product_name +
                                            ' - ' +
                                            lineItem.sku_name}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails
                                        sx={{
                                          px: {xs: 0.5, sm: 2, md: 3},
                                          py: {sm: 1, md: 3},
                                        }}
                                      >
                                        <Grid item container xs={12}>
                                          <Grid
                                            item
                                            container
                                            sm={12}
                                            md={5}
                                            lg={5}
                                            justifyContent="center"
                                          >
                                            {lineItem.product_ID ? (
                                              <img
                                                src={
                                                  props.solarProducts.find(
                                                    item =>
                                                      item.product_ID ===
                                                      lineItem.product_ID
                                                  ) && props.solarProducts.find(
                                                    item =>
                                                      item.product_ID ===
                                                      lineItem.product_ID
                                                  ).cover_image ? props.solarProducts.find(
                                                    item =>
                                                      item.product_ID ===
                                                      lineItem.product_ID
                                                  ).cover_image :   PowerGazebo
                                                }
                                                sx={{
                                                  height: {md: 200, xs: 200},
                                                }}
                                                width={'95%'}
                                                alt={'Panel'}
                                              />
                                            ) : (
                                              <img
                                                src={PowerGazebo}
                                                sx={{
                                                  height: {md: 200, xs: 200},
                                                }}
                                                width={'95%'}
                                                alt={'Panel'}
                                              />
                                            )}
                                            {/* {
                                              <img
                                                src={PowerGazebo}
                                                sx={{
                                                  height: {md: 200, xs: 200},
                                                }}
                                                width={'95%'}
                                                alt={'Panel'}
                                              />
                                            }
                                            {lineItem.name ===
                                              'PowerGazebo' && (
                                              <img
                                                src={PowerGazebo}
                                                sx={{
                                                  height: {md: 200, xs: 200},
                                                }}
                                                width={'95%'}
                                                alt={'Panel'}
                                              />
                                            )}
                                            {lineItem.name === 'PowerRoof' && (
                                              <img
                                                src={PowerRoof}
                                                sx={{
                                                  height: {md: 200, xs: 200},
                                                }}
                                                width={'95%'}
                                                alt={'Panel'}
                                              />
                                            )} */}
                                          </Grid>
                                          <Grid item container sm={12} md={7}>
                                            <Grid
                                              container
                                              sx={{px: {sm: 0, md: 2}, py: 1}}
                                            >
                                              <Grid
                                                container
                                                alignItems="center"
                                                justifyContent={'space-between'}
                                              >
                                                <Typography variant="h3">{`System Size`}</Typography>
                                                <Typography variant="h3">{`${numericNullChecker(
                                                  lineItem.system_size
                                                ).toFixed(2)} kWp`}</Typography>
                                              </Grid>
                                              <Grid
                                                container
                                                alignItems="center"
                                                justifyContent={'space-between'}
                                              >
                                                <Typography variant="h3">{`Annual Energy Production`}</Typography>
                                                <Typography variant="h3">{`${props.numericFormatter(
                                                  numericNullChecker(
                                                    lineItem.annual_energy_production
                                                  ).toFixed(2)
                                                )} kWh`}</Typography>
                                              </Grid>
                                              <Grid
                                                container
                                                alignItems="center"
                                                justifyContent={'space-between'}
                                              >
                                                <Typography variant="h3">{`Energy Needs Met`}</Typography>
                                                <Typography variant="h3">{`${props.numericFormatter(
                                                  numericNullChecker(
                                                    lineItem.coverage * 100
                                                  ).toFixed(2)
                                                )}%`}</Typography>
                                              </Grid>
                                              <Grid
                                                container
                                                alignItems="center"
                                                justifyContent={'space-between'}
                                              >
                                                <Typography variant="h3">{`Estimated Annual Savings`}</Typography>
                                                <Typography variant="h3">{`${props.currencyFormatter(
                                                  numericNullChecker(
                                                    lineItem.estimated_annual_savings
                                                  ).toFixed(2)
                                                )}`}</Typography>
                                              </Grid>
                                              <Grid
                                                container
                                                alignItems="center"
                                                justifyContent={'space-between'}
                                              >
                                                <Typography variant="h3">{`Amount Before Tax`}</Typography>
                                                <Typography variant="h3">{`${props.currencyFormatter(
                                                  numericNullChecker(
                                                    lineItem.amount
                                                  ).toFixed(2)
                                                )}`}</Typography>
                                              </Grid>
                                              <Grid
                                                container
                                                alignItems="center"
                                                justifyContent={'space-between'}
                                              >
                                                <Typography variant="h3">{`Tax`}</Typography>
                                                <Typography variant="h3">{`${props.currencyFormatter(
                                                  numericNullChecker(
                                                    lineItem.amount *
                                                      lineItem.tax
                                                  ).toFixed(2)
                                                )}`}</Typography>
                                              </Grid>
                                              <Grid
                                                container
                                                alignItems="center"
                                                justifyContent={'space-between'}
                                              >
                                                <Typography variant="h3">{`Discount`}</Typography>
                                                <Typography variant="h3">{`${props.currencyFormatter(
                                                  numericNullChecker(
                                                    lineItem.amount *
                                                      lineItem.discount
                                                  ).toFixed(2)
                                                )}`}</Typography>
                                              </Grid>
                                              <Grid
                                                container
                                                alignItems="center"
                                                justifyContent={'space-between'}
                                              >
                                                <Typography variant="h3">{`Grand Total`}</Typography>
                                                <Typography variant="h3">{`${props.currencyFormatter(
                                                  numericNullChecker(
                                                    lineItem.amount +
                                                      lineItem.amount *
                                                        lineItem.tax -
                                                      lineItem.amount *
                                                        lineItem.discount
                                                  ).toFixed(2)
                                                )}`}</Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                );
                              })
                            ) : (
                              <Typography variant="h2">
                                No Line Items found
                              </Typography>
                            )}
                            <Grid item xs={12} sx={{mt: 2}}>
                              <Typography variant="h1"></Typography>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{minWidth: 650}}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Name</StyledTableCell>
                                      <StyledTableCell align="right">
                                        Description
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        Amount
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {quote.installments &&
                                      quote.installments.length > 0 &&
                                      quote.installments.map(row => (
                                        <StyledTableRow
                                          key={row.name}
                                          sx={{
                                            '&:last-child td, &:last-child th':
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {row.name}
                                          </StyledTableCell>
                                          <StyledTableCell align="right">
                                            {row.description}
                                          </StyledTableCell>
                                          <StyledTableCell align="right">
                                            {parseFloat(row.amount) > 0
                                              ? props.currencyFormatter(
                                                  numericNullChecker(
                                                    parseFloat(row.amount)
                                                  ).toFixed(2)
                                                )
                                              : props.currencyFormatter(
                                                  numericNullChecker(
                                                    parseFloat(
                                                      row.percentage_value
                                                    ) * quote.amount
                                                  ).toFixed(2)
                                                )}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                            <Grid
                              container
                              alignItems="center"
                              mt={2}
                              justifyContent={'space-around'}
                            >
                              <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={() =>
                                  downloadFile(quote.file_display_name)
                                }
                                disabled={!quote.file_display_name}
                              >
                                <b>View Full Quote</b>
                              </Button>
                              {!quote.order_ID && !quote.rejected && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  size="large"
                                  onClick={() =>
                                    rejectQuote(quote.price_quote_ID)
                                  }
                                >
                                  <b>Reject Quote</b>
                                </Button>
                              )}
                              {quote.rejected && (
                                <Alert severity="error">Rejected</Alert>
                              )}
                              {quote.order_ID && (
                                <Alert severity="success">
                                  {quote.order_ID}
                                </Alert>
                              )}
                              {/* {quote.order_ID && (
                                <Alert severity="warning" sx={{mt: 1}}>
                                  {
                                    'The purchase contract for the above ordered item(s) has been sent to your email. Please check and sign the contract to proceed.'
                                  }
                                </Alert>
                              )} */}
                            </Grid>
                          </Grid>
                          <Typography
                            mt={2}
                            variant="h3"
                          >{`The quote has an estimated cost of ${props.currencyFormatter(
                            quote.amount
                          )}. It will take us 10-12 days to deliver the required parts to your location and 4-5 days for our partner to install it.`}</Typography>
                        </Card>
                      </Grid>
                    </Grid>
                  );
                }
              })
            ) : (
              <Grid container item sx={12}>
                <Paper sx={{p: 2, width: 1, mt: 2}}>
                  <Typography variant="h2">No Quotes Found</Typography>
                </Paper>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={12}
            mt={3}
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Card sx={{width: 1}}>
              <Skeleton variant="rectangular" width={'100%'} height={250} />
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
const mapStateToProps = state => {
  const {
    userAuthToken,
    userProfileData,
    currencyFormatter,
    numericFormatter,
    solarProducts,
  } = state.app;

  return {
    userAuthToken,
    userProfileData,
    currencyFormatter,
    numericFormatter,
    solarProducts,
  };
};

const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceQuoteAndContract);
