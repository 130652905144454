import React, {useState} from 'react';
import 'date-fns';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {Grid, TextField, Typography, Button} from '@mui/material';
import {DateTime} from 'luxon';
import {makeStyles} from '@mui/styles';
import {connect} from 'react-redux';
import {useSnackbar} from 'notistack';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import ProgressDialog from './ProgressDialog';
import {
  setUserProfileData as setUserProfileDataAction,
  setAppBarPage as setAppBarPageAction,
} from '../constants/reduxActions';
import {useHistory} from 'react-router-dom';
import {bookSiteSurvey as bookSiteSurveyRequest} from '../util/ApiCalls/service';
import redirectToPage from '../util/UtilFunctions/redirectToPage';

const useStyle = makeStyles(theme => ({
  pickerInput: {
    cursor: 'pointer',
    fontSize: '20px',
    fontWeight: 500,
    marginLeft: 10,
    textAlign: 'center',
  },
}));
const SurveyDatePicker = props => {
  const classes = useStyle();
  const history = useHistory();
  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  const [value, setValue] = React.useState(new Date());

  const handleChange = newValue => {
    setValue(newValue);
  };
  const [selectedFromDate, setSelectedFromDate] = useState(
    DateTime.now().plus({days: 2}).toJSDate()
  );
  const [selectedToDate, setSelectedToDate] = useState(
    DateTime.now().plus({days: 9}).toJSDate()
  );
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [toDateSet, setToDateSet] = useState(false);
  const [fromDateSet, setFromDateSet] = useState(false);

  const handleFromDateChange = date => {
    if (isValidDate(date)) {
      setSelectedFromDate(date);
      setToDateSet(true);
      setFromDateSet(false);
    }
  };

  const handleToDateChange = date => {
    if (isValidDate(date)) {
      setSelectedToDate(date);
      setFromDateSet(true);
    }
  };

  const isValidDate = d => d instanceof Date && !isNaN(d);

  const handleCloseFromDialog = date => {
    setSelectedToDate(DateTime.fromJSDate(date).plus({days: 7}).toJSDate());
  };
  const handleCloseToDialog = date => {
    console.log(date);
  };

  const bookSiteSurvey = () => {
    setOpenProgressDialog(true);
    bookSiteSurveyRequest({
      siteSurveyFromDate:
        DateTime.fromJSDate(selectedFromDate).toFormat('yyyy-MM-dd'),
      siteSurveyToDate:
        DateTime.fromJSDate(selectedToDate).toFormat('yyyy-MM-dd'),
    })
      .then(resp => {
        enqueueSnackbar('Survey Booked Successfully', {variant: 'success'});
        redirectToPage(history, 'dashboard', '/dashboard', props.setAppBarPage);
        setOpenProgressDialog(false);
        props.setUserProfileData(resp.message.customerData);
      })
      .catch(err => {
        setOpenProgressDialog(false);
        console.log(err);
      });
  };

  return (
    <div>
      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />
      <Grid container style={{width: '100%', padding: '0px 10px 0px 10px'}}>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems="center"
          style={{width: '100%'}}
        >
          <Typography variant="h3" sx={{mb: {xs: 2, sm: 2, md: 0}}}>
            From :
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label="From Date"
              showToolbar={false}
              inputFormat="MM/dd/yyyy"
              value={selectedFromDate}
              onChange={handleFromDateChange}
              disablePast
              renderInput={params => <TextField {...params} />}
              minDate={DateTime.now().plus({days: 2}).toJSDate()}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          style={{width: '100%'}}
          alignItems="center"
          sx={{mt: 1}}
        >
          <Typography variant="h3" sx={{mb: {xs: 2, sm: 2, md: 0}}}>
            To :
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              disablePast
              inputFormat="MM/dd/yyyy"
              showToolbar={false}
              // style={{justifyContent: 'center'}}
              // variant="dialog"
              // margin="none"
              // id="date-picker-inline"
              // openTo="day"
              // views={['year', 'month', 'day']}
              value={selectedToDate}
              onChange={handleToDateChange}
              // onAccept={handleCloseToDialog}
              minDate={DateTime.fromJSDate(selectedFromDate)
                .plus({days: 7})
                .toJSDate()}
              maxDate={DateTime.fromJSDate(selectedFromDate)
                .plus({days: 14})
                .toJSDate()}
              // error={props.errorText !== ''}
              // helperText={props.errorText}
              // shouldDisableDate={disableWeekends}
              renderInput={params => (
                <TextField
                  // style={{fontSize: 44}}
                  {...params}
                  // variant="standard"
                />
              )}
              disabled={!toDateSet}
              // InputProps={{
              //   classes: {input: classes.pickerInput},
              // disableUnderline: true,
              // }}
              // rifmFormatter={formatDate}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item container xs={12}>
          <Button
            style={{marginTop: 20}}
            onClick={bookSiteSurvey}
            variant="contained"
            color="secondary"
            fullWidth
            disabled={!toDateSet || !fromDateSet}
          >
            <b>Schedule site survey</b>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {userAuthToken};
};
const mapDispatchToProps = dispatch => ({
  setUserProfileData: userProfileData => {
    dispatch({type: setUserProfileDataAction, data: {userProfileData}});
  },
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDatePicker);
