import React from 'react';

import PowerRoof from '../../assets/images/powerroof_render.webp';
import PowerSolaire from '../../assets/images/ComingSoon.webp';
import PowerPergola from '../../assets/images/powergazebo_render.webp';
import BookSurvey from '../../assets/images/surveyImage.webp';

import {Button, Grid, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {useHistory} from 'react-router-dom';

import './CarouselItem.css';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';

const useStyles = makeStyles(theme => ({
  palette: {
    color: theme.palette.secondary.main,
  },
}));

const CarouselItem = ({data}) => {
  const history = useHistory();
  const classes = useStyles();

  const image = {
    roof: PowerRoof,
    gazebo: PowerPergola,
    bookSurvey: BookSurvey,
  };

  const handleRedirects = () => {
    if (data.name == 'bookSurvey') {
      history.replace(data.name);
      return;
    }
    history.replace('products' + '/' + data.name);
  };

  return (
    <div className="CarouselItem flex w-1">
      <div className="CarouselItem-img-wrapper">
        {data.cover_image ? (
          <img
            src={data.cover_image}
            alt={<div sx={{fontSize: '2rem'}}>NO Images Uploaded</div>}
            className="CarouselItem-img h-1 w-1"
          />
        ) : (
          <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            sx={{height: 200, width: '100%'}}
          >
            <Grid>
              <PanoramaOutlinedIcon sx={{fontSize: '5rem', color: 'black'}} />
            </Grid>
            <Grid container justifyContent={'center'}>
              {' '}
              <Typography variant="h4" color="primary">
                No Images Available
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>

      <div className="CarouselItem-description flex flex-col h-1">
        <div
          className={'CarouselItem-shortdescription flex ' + classes.palette}
          style={{backgroundColor: 'white'}}
        >
          <Typography variant="h1" color="#000000">
            {data.short_description}
          </Typography>
        </div>
        <div
          className="CarouselItem-longdescription flex flex-col "
          style={{backgroundColor: 'white'}}
        >
          <Typography variant="h3" style={{lineHeight: 1.5}} color="#000000">
            {data.description}
          </Typography>
          {console.log(data)}
          <Button
            variant="contained"
            color="secondary"
            style={{
              textTransform: 'none',
              fontSize: '1.25rem',
              fontWeight: '700',
            }}
            onClick={() => handleRedirects()}
            size="large"
            className="CarouselItem-button "
          >
            {data.name === 'bookSurvey'
              ? 'Schedule a site survey'
              : 'Learn More'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
