import React, {useEffect, useState} from 'react';
import {
  Paper,
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Button,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
  Grid,
  IconButton,
  Badge,
  FormGroup,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  ListItemIcon,
} from '@mui/material';
import {useHistory} from 'react-router-dom';
import Search from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import {connect} from 'react-redux';
import {makeStyles, withTheme} from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import {styled} from '@mui/material/styles';

import NotificationsList from './NotificationsList';
//import ArkaLogo from '../assets/images/arkaLogo.webp';
import {
  toggleDarkMode as toggleDarkModeAction,
  setDashboardPage as setDashboardPageAction,
  setUserAuthToken as setUserAuthTokenAction,
  setAppBarPage as setAppBarPageAction,
} from '../constants/reduxActions';
import HelperPopover from './HelperPopover';

import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import EvStationIcon from '@mui/icons-material/EvStation';
import HistoryIcon from '@mui/icons-material/History';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LogoutIcon from '@mui/icons-material/Logout';
import redirectToPage from '../util/UtilFunctions/redirectToPage';


const CompanyLogo = process.env.REACT_APP_COMPANY_WHITE_LOGO;

const useStyle = makeStyles(theme => ({
  textColor: {
    color: theme.palette.text.main,
  },
  appBarRoot: {
    zIndex: 99,
    boxShadow: '0px 0px',
    background: theme.palette.myBackground,
  },
}));

const drawerWidth = 240;

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const CustomAppBar = props => {
  const history = useHistory();
  console.log(history);

  const classes = useStyle();

  const StyledDrawer = styled(Drawer)(({theme, open}) => {
    if (theme.palette.mode === 'light')
      return {
        '& .MuiDrawer-paper': {
          background: theme.palette.myBackground,
          color: theme.palette.primary.contrastText,

          '& .MuiListItem-root': {
            '&:hover': {
              background: '#263f58',
            },
          },
          '& .Mui-selected': {
            background: '#263f58',
          },
        },
      };
    else if (theme.palette.mode === 'light') {
      return {
        '& .MuiDrawer-paper': {
          background: 'rgba(255,255,255,1)',
        },
      };
    } else if (theme.palette.mode === 'dark') {
      return {
        '& .MuiDrawer-paper': {
          background: theme.palette.myBackground,
        },
      };
    }
  });

  return (
    <Box sx={{display: 'flex'}} style={{padding: 0}}>
      {history.location.pathname === '/terms' ||
      history.location.pathname === '/policies' ? (
        <Grid container sd={{width: 1}}>
          <CssBaseline />

          <Box>
            <AppBar
              position="fixed"
              sx={{width: `100%`, ml: `${0}px`}}
              className={classes.appBarRoot}
            >
              <Toolbar>
                <img src={CompanyLogo} alt="" style={{maxHeight: 50}} />
              </Toolbar>
            </AppBar>
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 0,
              height: '100vh',
              overflow: 'auto',
              bgcolor: 'background.default',
              width: 'initial',
              zIndex: 2,
            }}
          >
            <div id={'app-bar-root'}>
              <DrawerHeader />
              <div>{props.children}</div>
            </div>
          </Box>
        </Grid>
      ) : (
        <div style={{width:'100%'}}>{props.children}</div>
      )}
    </Box>
  );
};

const mapStateToProps = state => {
  const {darkMode, notifications, userProfileData, appBarPage} = state.app;
  return {darkMode, notifications, userProfileData, appBarPage};
};
const mapDispatchToProps = dispatch => ({
  toggleDarkMode: () => {
    dispatch({type: toggleDarkModeAction});
  },
  setDashboardPage: dashboardPage => {
    dispatch({type: setDashboardPageAction, data: {dashboardPage}});
  },
  setUserAuthToken: userAuthToken => {
    dispatch({type: setUserAuthTokenAction, data: {userAuthToken}});
  },
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomAppBar);
