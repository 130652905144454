import {Button} from '@mui/material';
import React, {useState, useEffect} from 'react';
import '../assets/styles/PaymentGateway.css';
import {useSnackbar} from 'notistack';
import {connect} from 'react-redux';
import {initializePayment as initializaPaymentRequest} from '../util/ApiCalls/service';

const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PK);

const ProductDisplay = props => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const submitForm = () => {
    enqueueSnackbar('Payment Commenced', {variant: 'success'});

    initializaPaymentRequest({
      payment_installment_ID: props.payment_installment_ID,
      amount: props.amount,
    })
      .then(resp => {
        stripe.redirectToCheckout({
          sessionId: resp.message.id,
        });
      })
      .catch(err => console.log(err));
  };
  return (
    <section>
      {/* <form onSubmit={submitForm}> */}
      <Button
        onClick={submitForm}
        fullWidth
        color="secondary"
        variant="contained"
        disabled={props.disabled}
      >
        {props.payment_completed ? 'Paid' : 'Pay now'}
      </Button>
      {/* </form> */}
    </section>
  );
};

const Message = ({message}) => (
  <section>
    <p>{message}</p>
  </section>
);

const PaymentGateway = props => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setMessage('Order placed! You will receive an email confirmation.');
    }

    if (query.get('canceled')) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay
      payment_installment_ID={props.payment_installment_ID}
      amount={props.amount}
      disabled={props.disabled}
      payment_completed={props.payment_completed}
    />
  );
};

const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {userAuthToken};
};
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGateway);