import { Grid, Typography } from '@mui/material';
import React from 'react';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';

const NoPreviewAvailable = () => {
  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      sx={{height: 200, width: '100%'}}
    >
      <Grid>
        <Grid
          justifyContent={'center'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <PanoramaOutlinedIcon
            sx={{
              fontSize: '5rem',
            }}
            color='primary'
          />
        </Grid>

        <Grid justifyContent={'center'}>
          {' '}
          <Typography
            variant="h4"
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            color='primary'
          >
            No Images Available
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NoPreviewAvailable;
