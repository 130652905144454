import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Card,
  Divider,
  Button,
  Box,
  Skeleton,
  Paper,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import OrderCard from '../components/OrderCard';
import {
  getPriceQuote as getPriceQuoteRequest,
  getSiteSurveyDetails as getSiteSurveyDetailsRequest,
  getOrderDetails as getOrderDetailsRequest,
  getProposalDataWithHistory as getProposalDataWithHistoryRequest,
} from '../util/ApiCalls/service';
import CircularProgress from '@mui/material/CircularProgress';
import ScheduleSurveyAndPurchaseCard from '../components/ScheduleSurveyAndPurchaseCard';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import {numericNullChecker} from '../util/UtilFunctions/nullCheckers';
import CustomNoRowsOverlay from '../components/NoRowsOverlay';

const useStyle = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  cardRoot: {
    width: '100%',
    padding: 10,
  },
}));

const InterestMapping = [
  {
    displayName: 'PowerRoof™',
    propertyName: 'solar_roof_interest',
  },
  {
    displayName: 'PowerGazebo™',
    propertyName: 'solar_pergola_interest',
  },
  {
    displayName: 'Both solutions',
    propertyName: 'both_interest',
  },
];

const OrderHistory = props => {
  const classes = useStyle();
  const history = useHistory();

  const [interest, setInterest] = useState(InterestMapping[2].displayName);

  const [surveyData, setSurveyData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const [quoteData, setQuoteData] = useState();

  const bookSurveyHandler = () => {
    redirectToPage(history, 'bookSurvey', '/bookSurvey', props.setAppBarPage);
  };

  useEffect(() => {
    if (props.userAuthToken && !surveyData && !orderData) {
      getSiteSurveyDetailsRequest()
        .then(resp => setSurveyData(resp.message.surveyDataNew))
        .catch(err => console.log(err));

      getProposalDataWithHistoryRequest(false)
        .then(resp => {
          let orderedQuotes = []
          resp.message.quotes.filter(quote => {
            if(quote.order_ID){
              orderedQuotes.push(quote)
            }
          })
          setOrderData(orderedQuotes)
        })
        .catch(err => console.log(err));
      // getOrderDetailsRequest()
      //   .then(resp => setOrderData(resp.message.orderData))
      //   .catch(err => console.log(err));

      // getPriceQuoteRequest()
      //   .then(resp => {
      //     setQuoteData(resp.message);
      //   })
      //   .catch(err => console.log(err));
    }
  }, []);

  return (
    <div className={classes.root}>
      <Grid container style={{width: '100%'}}>
        {/* Page Title */}
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Typography variant="h1" color={'#000000'}>
            Activity History
          </Typography>
        </Grid>
        {/* Sub Title */}
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Typography variant="h3" color={'#000000'}>
            View your current and previous orders.
          </Typography>
        </Grid>

        {props.userProfileData ? (
          <Grid item container sx={12}>
            <div style={{width: '100%'}}>
              {surveyData && orderData ? (
                <Grid item container xs={12}>
                  <Grid
                    item
                    container
                    xs={12}
                    mt={2}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                  >
                    <Typography variant="h1" sx={{mb: 2, color: '#1D1D1F'}}>
                      Survey Booking
                    </Typography>
                  </Grid>
                  {surveyData && surveyData.surveyData ? (
                    <Grid item container xs={12}>
                      <Grid item container xs={12}>
                        <OrderCard
                          displayName={'Survey'}
                          orderData={{
                            id: surveyData.surveyData.order_ID,
                            created_date: surveyData.surveyData.created_at,
                            name:
                              props.userProfileData.first_name +
                              ' ' +
                              props.userProfileData.surname,
                            address: props.userProfileData.gmaps_address_string,
                            status: surveyData.surveyData.status,
                            total: 'FREE',
                            from_date:
                              surveyData.surveyData.site_survey_from_date,
                            to_date: surveyData.surveyData.site_survey_to_date,
                          }}
                          surveyID={surveyData.surveyID}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item sx={12}>
                      <Paper sx={{p: 2, width: 1, mt: 2}}>
                        <Typography variant="h2">
                          No Survey Orders Found
                        </Typography>
                      </Paper>
                    </Grid>
                  )}
                  <Grid item container xs={12}>
                    {orderData && orderData.length > 0 ? (
                      props.userProfileData &&
                      props.solarProducts && (
                        <Grid
                          item
                          container
                          xs={12}
                          mt={2}
                          justifyContent="flex-start"
                          alignItems="flex-end"
                        >
                          <Typography variant="h1" color="#1D1D1F">
                            Order History
                          </Typography>

                          {props.currencyFormatter &&
                            props.solarProducts &&
                            orderData &&
                            orderData.map((order, index) => (
                              <OrderCard
                                key={index}
                                // displayName={props.solarProducts.filter(i => i.product_ID === orderData.product_ID)[0].subject}
                                displayName={order.order_ID}
                                orderData={{
                                  id: order.order_ID,
                                  created_date: order.order.created_at,
                                  name: `${props.userProfileData.first_name} ${props.userProfileData.surname}`,
                                  address:
                                    props.userProfileData.gmaps_address_string,
                                  status: order.order.status,
                                  total: props.currencyFormatter(
                                    numericNullChecker(order.order.amount)
                                  ),
                                }}
                                quoteData={
                                  order
                                }
                              />
                            ))}
                        </Grid>
                      )
                    ) : (
                      <Grid container item sx={12}>
                        <Paper sx={{p: 2, width: 1, mt: 2}}>
                          <Typography variant="h2">
                            No Product Orders Found
                          </Typography>
                        </Paper>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ) : !surveyData && orderData && orderData.length === 0 ? (
                <Grid item container xs={12} sx={{width: 1}}>
                  <Card sx={{width: 1, p: 2}}>
                    <Skeleton
                      variant="rectangular"
                      width={'100%'}
                      height={200}
                    />
                  </Card>
                </Grid>
              ) : (
                <Grid item container xs={12} lg={4} sx={{mt: 2}}>
                  {process.env.REACT_APP_SKIP_SITE_SURVEY === 'NO' ? (
                    <ScheduleSurveyAndPurchaseCard />
                  ) : (
                    <CustomNoRowsOverlay text="No data available" />
                  )}
                </Grid>
              )}
            </div>
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height={300}
          >
            <CircularProgress color="inherit" sx={{transform: 'scale(3)'}} />
          </Box>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const {userProfileData, userAuthToken, solarProducts, currencyFormatter} =
    state.app;
  return {userProfileData, userAuthToken, solarProducts, currencyFormatter};
};

const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
