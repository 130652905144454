import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {
  Typography,
  Grid,
  Card,
  Button,
  TextField,
  FormHelperText,
  Checkbox,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
} from '@mui/material';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import {styled} from '@mui/material/styles';
import {useHistory} from 'react-router-dom';

import ProgressBar from '../components/ProgressBar';
import UploadButton from '../components/UploadButton';
import SurveyDatePicker from '../components/SurveyDatePicker';
import {connect, useSelector} from 'react-redux';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import redirectToPage from '../util/UtilFunctions/redirectToPage';

import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import {getAllUserDocuments as getAllUserDocumentsRequest} from '../util/ApiCalls/service';
const useStyles = makeStyles(theme => ({
  rootContainer: {
    padding: 10,
    [theme.breakpoints.down('md')]: {
      padding: '5px 2px',
    },
  },
  rootHeaderText: {
    marginLeft: 50,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: 10,
      marginBottom: 10,
    },
  },
  rootGrid: {
    padding: 30,
    [theme.breakpoints.down('md')]: {
      padding: '15px 5px',
    },
    height: '95%',
  },
  mainCard: {
    width: '100%',
    padding: 20,
  },
  leftContainer: {
    width: '100%',
  },
  descriptionContainer: {
    height: '100%',
  },
  inputsContainer: {
    height: '100%',
    padding: 30,
  },
  inputFieldsContainer: {
    width: '100%',
    margin: '20px 0px 10px 0px',
    height: '100%',
  },
  optionButtons: {
    padding: 20,
    margin: 5,
  },
  uploadText: {
    width: '75%',
  },
  uploadButton: {
    width: '23%',
    padding: '15px ',
  },
}));

const questionsData = [
  {
    question: 'Do you own an Electrical Vehicle ?',
    options: ['Yes', 'No'],
  },
  {
    question: 'When did you purchase your Electrical Vehicle ?',
    options: [
      'Less than 6 months ago',
      '1-2 year ago',
      '2 to 5 years ago',
      'More than 5 years',
    ],
  },
  {
    question: 'Are you planning to get an Electrical Vehicle ?',
    options: ['Yes', 'No'],
  },
  {
    question: 'When are you planning to get an Electrical Vehicle ?',
    options: [
      'Within the next six months',
      'Within a year',
      'More than a year later',
    ],
  },
];

const imageFields = [
  'Electric Meter',
  'Electric Panel',
  'Circuit Breaker',
  'Electrical Panel Manufacturers Label',
];

const graphModes = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',
  lifetime: 'Lifetime',
  custom: 'Custom',
};

const Input = styled('input')({
  display: 'none',
});

const BookSurvey = props => {
  const classes = useStyles();
  const history = useHistory();

  const siteSurveyBooked = useSelector(state =>
    state.app.userProfileData
      ? state.app.userProfileData.site_survey_booked
      : false
  );
  console.log({siteSurveyBooked});
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(0);

  const [answers, setAnswers] = useState(['', '', '']);
  const [token, setToken] = useState(null);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [documentsData, setDocumentsData] = useState();

  const changeStep = type => {
    if (type === 'next') {
      if (page !== 4) {
        setProgress(progress + 50);
        setPage(page + 1);
      }
    } else {
      if (page !== 0) {
        setProgress(progress - 50);
        setPage(page - 1);
      }
    }
  };

  // get user document types for uploading images
  useEffect(() => {
    if (props.userAuthToken && !documentsData) {
      getAllUserDocumentsRequest()
        .then(resp => {
          setDocumentsData(resp.message);
        })
        .catch(err => console.log(err));
    }
  }, [props.userAuthToken]);

  const PageNavigation = () => {
    return (
      <Grid container style={{width: '100%'}} justifyContent="space-between">
        <Grid item>
          {/* {page !== 0 && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => changeStep('prev')}
            >
              Previous
            </Button>
          )} */}
        </Grid>
        <Grid item>
          {page <= 0 && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => changeStep('next')}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  const PagedInputFields = () => {
    if (page === 0) {
      return (
        <Grid container sx={{mb: 2}}>
          <Typography variant="h2">BEFORE THE SURVEY</Typography>
          <Grid item container xs={12} alignItems="center" sx={{my: 1}}>
            <Alert severity="info" sx={{alignItems: 'center'}}>
              <b>
                Note: This step is optional, but providing these images would
                streamline the Survey Process
              </b>
            </Alert>
          </Grid>
          <Typography variant="h3" style={{marginBottom: 20, marginTop: 5}}>
            You can speed up the survey process by providing us with photos of
            your house and electrical setup. Follow the guide below to upload
            the relevant images.
          </Typography>

          <Typography variant="h3" style={{marginBottom: 20, marginLeft: 5}}>
            In each photo, make sure the manufacturer’s information and other
            details on any product labels are clearly visible.
          </Typography>
          {documentsData &&
            documentsData.allDocuments &&
            imageFields.map((item, index) => {
              let documentTypeRecord = documentsData.allDocuments.filter(
                jItem => jItem.subject === item
              );
              if (documentTypeRecord.length === 1) {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    style={{marginBottom: 20}}
                    alignItems="center"
                    key={index}
                    justifyContent="space-between"
                  >
                    <UploadButton
                      type="image"
                      label={item}
                      index={index}
                      documentID={documentTypeRecord[0].document_type_ID}
                    />
                  </Grid>
                );
              }
            })}
        </Grid>
      );
    } else if (page === 1) {
      return (
        <Grid
          container
          style={{height: '50%'}}
          sx={{mb: {xs: 5, sm: 5, md: 0}}}
        >
          <Typography variant="h2">SCHEDULE A SITE SURVEY</Typography>
          <Divider width="100%" />
          <Typography variant="h3" style={{marginBottom: 20}}>
            Our installation partner will contact you and arrive at your
            property to carry out a detailed survey to help us identify the best
            solution to meet your needs.
          </Typography>

          <Grid container item xs={12} justifyContent="center">
            <Grid item sx={12} md={6}>
              <Card raised sx={{p: {xs: 2, sm: 2, md: 5}}}>
                <Grid container justifyContent={'center'}>
                  <Button
                    onClick={() =>
                      redirectToPage(
                        history,
                        'bookSurveyInfo',
                        '/bookSurveyInfo',
                        props.setAppBarPage
                      )
                    }
                  >
                    Learn why you should schedule the free survey.
                  </Button>
                  <Divider width="100%" />
                  <Typography
                    variant="h3"
                    style={{margin: '20px 0px 20px 0px'}}
                  >
                    Pick the dates during which our partners may survey your
                    property:
                  </Typography>
                  <SurveyDatePicker />
                  {/* <Grid item container alignItems='center' justifyContent='space-between' style={{marginRight:50}}>
                                        <Typography variant='h3'>From</Typography>
                                        <TextField value=' dd / mm / yyyy'></TextField>
                                        
                                    </Grid>
                                    <Grid item container alignItems='center' justifyContent='space-between' style={{marginRight:50}}>
                                        <Typography variant='h3' >To</Typography>
                                        <TextField value=' dd / mm / yyyy'></TextField>

                                    </Grid> */}
                  {/* <Button style={{marginTop:20}} variant='contained'>Book site survey</Button> */}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return <div>error</div>;
    }
  };

  return (
    <div className={classes.rootContainer}>
      {!siteSurveyBooked ? (
        <div>
          <div className={classes.rootHeaderText}>
            <Typography variant="h1" color="primary">
              SCHEDULE A SITE SURVEY
            </Typography>
            <Typography variant="h3" color="primary">
              Our one-time free site survey can help you understand which of our
              products best suits your needs.
            </Typography>
          </div>
          <Grid
            container
            className={classes.rootGrid}
            justifyContent="space-around"
          >
            <Grid item xs={12}>
              <Card className={classes.inputsContainer}>
                <ProgressBar progress={progress} />
                <Grid container className={classes.inputFieldsContainer}>
                  <Grid item container xs={12} style={{height: '90%'}}>
                    <PagedInputFields />
                  </Grid>
                  <Grid item xs={12} style={{height: '10%'}}>
                    <PageNavigation />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Grid
          container
          sx={{width: 1, p: 3}}
          justifyContent="center"
          alignItems="center"
        >
          <Card sx={{p: 5, mt: '5%'}}>
            <Grid container sx={{width: 1}} alignItems="center">
              <Grid
                item
                md={3}
                sm={12}
                container
                justifyContent={'center'}
                alignItem="center"
              >
                <CheckCircleOutlineIcon
                  color="success"
                  sx={{transform: 'scale(4.5)'}}
                />
              </Grid>
              <Grid item md={9} sm={12}>
                <Grid item xs={12}>
                  <Typography variant="h1">
                    Your Survey has already been booked!
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{mt: 2}}>
                  <Button
                    sx={{textTransform: 'none'}}
                    size="large"
                    color="secondary"
                    onClick={() =>
                      redirectToPage(
                        history,
                        'dashboard',
                        '/dashboard',
                        props.setAppBarPage
                      )
                    }
                  >
                    <b>
                      <u>
                        Please proceed to the Dashboard to continue along your
                        journey.
                      </u>
                    </b>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {userAuthToken};
};
const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(BookSurvey);
