import React, {useState} from 'react';
import {makeStyles, styled} from '@mui/styles';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Card,
  Grid,
  Typography,
  TextField,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  CardContent,
  Paper,
  InputAdornment,
  IconButton,
  Divider,
  Checkbox,
  Alert,
} from '@mui/material';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import {useMsal} from '@azure/msal-react';
import {connect, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import * as Yup from 'yup';
import {Formik} from 'formik';
import {GoogleApiWrapper} from 'google-maps-react';
import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

//icons
import HandIcon from '../assets/images/hand.webp';
import PersonIcon from '@mui/icons-material/Person';
import BoltIcon from '@mui/icons-material/Bolt';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RoofingIcon from '@mui/icons-material/Roofing';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import '../assets/styles/InteractiveSignUp.css';
//import ArkaLogo from '../assets/images/logo.webp';
//import spotlightLogo from '../assets/images/Spotlight Logo_Blue.svg';
import MapAutocomplete from '../components/MapAutocomplete';
import MapContainer from '../components/MapContainer';

import PergolaModelSource from '../assets/models/PowerPergola.glb';
import PergolaModelPoster from '../assets/models/PowerPergola.png';
import SolaireModelSource from '../assets/models/solaire.glb';
import SolaireModelPoster from '../assets/models/solaire.png';
import Roof1 from '../assets/images/roof2.webp';
import SignUpImage from '../assets/images/Sign_In_India_Wide.webp';
import ProgressDialog from '../components/ProgressDialog';
import {useSnackbar} from 'notistack';
import {setCurrentStep} from '../constants/reduxActions';
import {
  setUserAuthToken as setUserAuthTokenAction,
  setAppBarPage as setAppBarPageAction,
} from '../constants/reduxActions';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {
  login as loginRequest,
  getOtpSignUpEmailValidation as getOtpSignUpEmailValidationRequest,
  getOtpSignUpMobileValidation as getOtpSignUpMobileValidationRequest,
  verifyOtpSignUpEmailValidation as verifyOtpSignUpEmailValidationRequest,
  verifyOtpSignUpMobileValidation as verifyOtpSignUpMobileValidationRequest,
  signUp as signUpRequest,
  sendForgotPasswordVerificationCode as sendForgotPasswordVerificationCodeRequest,
  verifyOtpSetNewPassword as verifyOtpSetNewPasswordRequest,
  socialSignInUp as socialSignInUpRequest,
  getUserProfileBT as getUserProfileBTRequest,
  updateUserProfileBT as updateUserProfileBTRequest,
  createLead,
  getOtpSignInMobileValidation,
  verifyOtpSignInMobileValidation,
} from '../util/ApiCalls/service';

import redirectToPage from '../util/UtilFunctions/redirectToPage';

const CompanyLogo = process.env.REACT_APP_COMPANY_LOGO;
const CompanyWhiteLogo = process.env.REACT_APP_COMPANY_WHITE_LOGO;
const SpotlightLogo = process.env.REACT_APP_SPOTLIGHT_LOGO;

const useStyle = makeStyles(theme => ({
  root: {
    background: theme.palette.myBackground,

    padding: 0,
    minWidth: '350px',
    height: '100vh',
    minHeight: '100vh',
    [theme.breakpoints.down('md')]: {
      height: '130vh',
    },
  },
  paddedInputContainers: {
    padding: '0px 40px',
    width: '100%',
  },
  stepCard: {
    paddingTop: 20,
    width: '100%',
  },
  homeImage: {
    height: '100vh',
    width: '100%',
    transform: '1000ms',
    transition: 'rotate(180deg)',
    [theme.breakpoints.down('lg')]: {
      height: 200,
    },
  },
  mainGridContainer: {
    width: '100%',
    padding: '20px 100px 0px',
    [theme.breakpoints.down('lg')]: {
      padding: '2% 5% ',
    },
  },
  rootCardContainer: {
    width: '100%',
    textAlign: 'center',
    paddingBottom: 25,
  },
  inputsContainer: {
    width: '100%',
    padding: '30px 0px 20px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0px 20px 15px',
    },
  },
  facebookButton: {
    width: '40px',
    height: '42px',
    fontFamily: 'Helvetica,sans-serif',
    fontWeight: 700,
    // -webkit-font-smoothing: antialiased,
    color: '#fff',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: 'calc(.27548vw + 12.71074px)',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'background-color .3s,border-color .3s',
    backgroundColor: '#4c69ba',
    border: 'calc(0.06887vw + 0.67769px) solid #4c69ba',
    borderRadius: '4px',

    // padding:'0px 22% 0px 27%'
  },
  googleButton: {
    width: '40px',
    marginRight: 10,
  },
}));

const energyProducts = [
  {
    displayName: 'PowerRoof',
    image: Roof1,
  },
  {
    displayName: 'PowerGazebo',
    image: PergolaModelPoster,
    model: PergolaModelSource,
  },
  {
    displayName: 'GazeboSolaire',
    image: SolaireModelPoster,
    model: SolaireModelSource,
  },
];

const InterestMapping = [
  {
    displayName: 'Roof',
    propertyName: 'solar_roof_interest',
  },
  {
    displayName: 'Gazebo',
    propertyName: 'solar_pergola_interest',
  },
  {
    displayName: 'Both solutions',
    propertyName: 'both_interest',
  },
];

const steps = [
  {
    label: 'Contact Info',
  },
  {
    label: 'Address',
  },
  {
    label: 'Product View',
  },
  {
    label: 'Consumption & Interest',
  },
];

const QontoStepIconRoot = styled('div')(({theme, ownerState}) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#D2D2D7',
      // 'linear-gradient( 136deg, rgb(252, 218, 73) 0%, rgb(201, 170, 34) 50%, rgb(105, 87, 12) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#F4CD2A',
      // 'linear-gradient( 136deg, rgb(252, 218, 73) 0%, rgb(201, 170, 34) 50%, rgb(105, 87, 12) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.yellow[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#D2D2D7',
    // backgroundImage:
    //     'linear-gradient( 136deg, rgb(252, 218, 73) 0%, rgb(201, 170, 34) 50%, rgb(105, 87, 12) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#F4CD2A',
    // backgroundImage:
    //     'linear-gradient( 136deg, rgb(252, 218, 73) 0%, rgb(201, 170, 34) 50%, rgb(105, 87, 12) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const {active, completed, className} = props;

  const icons = {
    1: <PersonIcon color="primary" fontSize="large" />,
    2: <LocationOnIcon color="primary" fontSize="large" />,
    3: <RoofingIcon color="primary" fontSize="large" />,
    4: <BoltIcon color="primary" fontSize="large" />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{completed, active}}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const energyModes = ['billValue', 'consumption'];

const InteractiveSignUp = props => {
  const classes = useStyle();

  const geocoder = new props.google.maps.Geocoder();

  const history = useHistory();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [signUpAction, setSignUpAction] = useState(false);
  const [signInAction, setSignInAction] = useState(false);
  const [forgotPasswordAction, setForgotPasswordAction] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [contactInfo, setContactInfo] = useState();

  const [profileCreatedDialogOpen, setProfileCreatedDialogOpen] =
    useState(false);

  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const [signUpErrorDialog, setSignUpErrorDialog] = useState(false);
  const [signUpErrorMessage, setSignUpErrorMessage] = useState([]);
  const [signUpErrorHeader, setSignUpErrorHeader] = useState('');
  const [userOtp, setUserOtp] = useState('');
  const [loginWithOtp, setLoginWithOtp] = useState('');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState();
  const [invalidEmailPassword, setInvalidEmailPassword] = useState(false);

  const [termsNCondionsApproved, setTermsNCondionsApproved] = useState(false);

  const [validGmapsAddress, setValidGmapsAddress] = useState(false);

  //address state,
  const [addressLatLng, setAddressLatLng] = useState({
    lat: 12.980498,
    lng: 77.576634,
  });
  const [addressData, setAddressData] = useState({});
  const [addressString, setAddressString] = useState('');

  const [energyMode, setEnergyMode] = useState(energyModes[0]);
  const [energyValue, setEnergyValue] = useState();

  const [selectedInterest, setSelectedInterest] = useState(energyProducts[0]);

  const [otpRequested, setOtpRequested] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);

  const [otpMobileRequested, setOtpMobileRequested] = useState(false);
  const [otpMobileValidated, setOtpMobileValidated] = useState(false);

  const [roofArea, setRoofArea] = useState();
  const [pergolaArea, setPergolaArea] = useState();

  const [newSocialUserToken, setNewSocialUserToken] = useState(null);

  const [userInterest, setUserInterest] = useState(
    InterestMapping[2].displayName
  );

  const [oid, setOid] = useState();
  const [isEmail, setisEmail] = useState();
  const [isPhone, setisPhone] = useState();
  const [isPhoneBtn, setisPhoneBtn] = useState('Enter Mobile Number');
  const [isEmailBtn, setisEmailBtn] = useState('Enter Email ID (Optional)');
  const [signinWithOtp, setsigninWithOtp] = useState(0);
  const [fname, setfname] = useState();
  const [lname, setlname] = useState();
  const [pass, setpass] = useState();
  const [confirmpass, setconfirmpass] = useState();
  // const [googleLoginTry, setGoogleLoginTry] = useState(0);
  // const funcisPhone = () =>{
  //     return isPhone;
  // }
  // const funcisEmail = () =>{
  //   return isEmail;
  // }

  let spotlightBackendUrl;
  let loginWithEmailorPhone = 1;
  const [ForgotPassEmailorPhone, setForgotPassEmailorPhone] = useState(0);
  if (process.env.REACT_APP_MODE == 'PROD') {
    spotlightBackendUrl = 'https://' + window.location.hostname;
  } else {
    spotlightBackendUrl = 'http://localhost:5001';
  }

  const updateAddressFromMap = coords => {
    setAddressLatLng(coords);
    //reverse geocodde to address string

    geocoder
      .geocode({location: coords})
      .then(response => {
        setAddressData(response.results[0]);
        setAddressString(response.results[0].formatted_address);
      })
      .catch(e => console.log('Geocoder failed due to: ' + e));
  };

  const updateAddressFromAutocomplete = addressObject => {
    if (typeof addressObject !== 'string') {
      setAddressData(addressObject);
      setAddressString(addressObject.formatted_address);
      setAddressLatLng({
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      });
    }
  };

  const setUserPosition = position => {
    let coords = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    setAddressLatLng(coords);
    //reverse geocodde to address string

    geocoder
      .geocode({location: coords})
      .then(response => {
        setAddressData(response.results[0]);
        setAddressString(response.results[0].formatted_address);
        setValidGmapsAddress(true);
      })
      .catch(e => console.log('Geocoder failed due to: ' + e));
  };

  const handleGetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setUserPosition);
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const backOnSignupHandler = () => {
    setSignUpErrorDialog(false);
    //setSignUpAction(false);
  };

  const requestEmailOtp = email => {
    enqueueSnackbar('Requested for OTP');
    setOpenProgressDialog(true);

    getOtpSignUpEmailValidationRequest({
      email: email,
      appId: 'APP000001',
      orgId: 'ORG001',
    })
      .then(resp => {
        enqueueSnackbar('OTP has been sent to your mail', {variant: 'success'});
        setOtpRequested(true);
        setOpenProgressDialog(false);
      })
      .catch(err => {
        console.log(err);
        setOpenProgressDialog(false);

        if (err === 'Email Already Exist') {
          setOtpRequested(false);
          setSignUpErrorDialog(true);
          setSignUpErrorHeader(err);
          setSignUpErrorMessage([
            `The email ID you've used already has an account associated with it. Please sign in to your account. `,
            `If you face issues signing in try resetting your password.`,
          ]);

          enqueueSnackbar(err, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(err, {
            variant: 'error',
          });
        }
      });
  };

  const requestmobileOtp = mobile_telephone_number => {
    enqueueSnackbar('Requested for OTP');
    setOpenProgressDialog(true);

    getOtpSignUpMobileValidationRequest({
      mobile_telephone_number: mobile_telephone_number,
      appId: 'APP000001',
      orgId: 'ORG001',
    })
      .then(resp => {
        console.log(resp);
        enqueueSnackbar('OTP has been sent to your mobile', {
          variant: 'success',
        });
        setOtpMobileRequested(true);
        setOpenProgressDialog(false);
      })
      .catch(err => {
        console.log(err);
        if (err === 'Phone number already exist') {
          setOtpMobileRequested(false);
          setSignUpErrorDialog(true);
          setSignUpErrorHeader(err);
          setSignUpErrorMessage([
            `The Mobile Number you've used already has an account associated with it. Please sign in to your account. `,
            `If you face issues signing in try resetting your password.`,
          ]);
          setOpenProgressDialog(false);

          enqueueSnackbar(err, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(err, {
            variant: 'error',
          });
          console.log(err);
          setOpenProgressDialog(false);
        }
      });
  };
  const requestmobileotpsignin = mobileno => {
    setsigninWithOtp(2);
    console.log(mobileno);
    getOtpSignInMobileValidation({mobile_telephone_number: mobileno})
      .then(resp => {
        console.log(resp);
        enqueueSnackbar('OTP has been sent to your mobile', {
          variant: 'success',
        });
        //setOtpMobileRequested(true);
        setOpenProgressDialog(false);
      })
      .catch(err => {
        //console.log(err);
        // if (err === 'Phone Number Does Not Exist') {
        //   //setOtpMobileRequested(false);
        //   setOpenProgressDialog(false);
        //   // setSignUpErrorDialog(true);
        //   // setSignUpErrorHeader(err);

        //   // setSignUpErrorMessage([
        //   //   `The Mobile Number you are using don't have an account associated with us. Please sign up to create new account. `,
        //   //   `Else check the phone number if already registered.`,
        //   // ]);

        //   setsigninWithOtp(1);
        //   enqueueSnackbar(err, {
        //     variant: 'error',
        //   });
        // } else {
        enqueueSnackbar(err, {
          variant: 'error',
        });

        console.log(err);
        setOpenProgressDialog(false);
        setsigninWithOtp(1);
        //}
      });
  };

  const validateMobileLoginOtp = (mobileno, otp) => {
    setOpenProgressDialog(true);

    verifyOtpSignInMobileValidation({
      mobile_telephone_number: mobileno,
      otp: otp,
      appId: 'APP000001',
      orgId: 'ORG001',
    })
      .then(resp => {
        if (!resp.success) {
          setOpenProgressDialog(false);
          enqueueSnackbar(resp.message, {variant: 'error'});
        } else {
          setOpenProgressDialog(false);

          enqueueSnackbar('Mobile Login OTP verified', {variant: 'success'});
          localStorage.setItem('spotlight-user-token', resp.message.token);
          props.setUserAuthToken(resp.message.token);
          redirectToPage(
            history,
            'dashboard',
            '/dashboard',
            props.setAppBarPage
          );
          //setOtpValidated(true);
        }
      })
      .catch(err => {
        setOpenProgressDialog(false);
        // setSignUpErrorDialog(true);
        // if (err === 'OTP expired please get new OTP') {
        //   setSignUpErrorHeader('OTP Expired');

        //   setSignUpErrorMessage([
        //     `The Mobile OTP has expired please try again. `,
        //   ]);
        //   enqueueSnackbar('OTP Expired', {variant: 'error'});
        //   setsigninWithOtp(0);
        // } else {
        //   setSignUpErrorHeader('Incorrect OTP');

        //   setSignUpErrorMessage([
        //     `The Mobile OTP you entered is Incorrect. Please enter correct mobile OTP to signin. `,
        //   ]);
        //   enqueueSnackbar('Incorrect OTP', {variant: 'error'});
        // }
        enqueueSnackbar(err, {variant: 'error'});
        console.log(err);
      });
  };

  const validateEmailOtp = (email, otp) => {
    setOpenProgressDialog(true);

    verifyOtpSignUpEmailValidationRequest({
      email: email,
      otp: otp,
    })
      .then(resp => {
        setOpenProgressDialog(false);
        if (!resp.success) {
          console.log(resp);
          enqueueSnackbar(resp.message, {variant: 'error'});
        } else {
          enqueueSnackbar('Email OTP verified', {variant: 'success'});
          setOtpValidated(true);
        }
      })
      .catch(err => {
        setOpenProgressDialog(false);
        enqueueSnackbar(err, {variant: 'error'});
        console.log(err);
      });
  };

  const validateMobileOtp = (mobile_telephone_number, otp) => {
    setOpenProgressDialog(true);

    verifyOtpSignUpMobileValidationRequest({
      mobile_telephone_number: mobile_telephone_number,
      otp: otp,
    })
      .then(resp => {
        setOpenProgressDialog(false);
        if (!resp.success) {
          enqueueSnackbar(resp.message, {variant: 'error'});
        } else {
          enqueueSnackbar('Mobile OTP verified', {variant: 'success'});
          setOtpMobileValidated(true);
        }
      })
      .catch(err => {
        setOpenProgressDialog(false);
        enqueueSnackbar(err, {variant: 'error'});
        console.log(err);
      });
  };

  const signInHandler = () => {
    // enqueueSnackbar('Redirecting you to sign in', {variant: 'success'});
    // instance.loginRedirect(loginRequest);
    setSignInAction(true);
  };

  const backFromSignIn = () => {
    setSignUpAction(null);
    setOtpRequested(false);
    setOtpMobileRequested(false);
    setOtpMobileValidated(false);
    setOtpValidated(false);
  };

  const handleChangeInterest = e => {
    setSelectedInterest(e.target.value);
  };

  const interestChangeHandler = name => {
    setUserInterest(name);
  };

  const switchToAndFromForgotPassword = () => {
    setSignInAction(!signInAction);
    setForgotPasswordAction(!forgotPasswordAction);
  };

  const completeSignUpHandler = () => {
    let body = {
      gmaps_address_string: addressString,
      latitude: addressLatLng.lat.toString(),
      longitude: addressLatLng.lng.toString(),
      roof_size: roofArea ? roofArea : 0,
      terrace_yard_size: pergolaArea ? pergolaArea : 0,
      solar_roof_interest: userInterest === InterestMapping[0].displayName,
      solar_pergola_interest: userInterest === InterestMapping[1].displayName,
      both_interest: userInterest === InterestMapping[2].displayName,
      previous_month_electricity_bill:
        energyMode === energyModes[0] ? energyValue : 0,
      energy_consumption: energyMode === energyModes[1] ? energyValue : 0,
      profile_complete: true,
    };

    //if not new social user add in personal info
    if (!newSocialUserToken) {
      body = {
        ...body,
        firstName: contactInfo.firstname,
        lastName: contactInfo.lastname,
        mobile: contactInfo.phone.toString(),
        email: contactInfo.email,
        password: contactInfo.password,
      };
    }

    signUpHandler(body);
  };

  const createLeadonSignUp = leadBody => {
    const body = {leads: [leadBody]};
    createLead(body)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const signUpHandler = body => {
    console.log(body, newSocialUserToken);
    setOpenProgressDialog(true);

    if (!newSocialUserToken) {
      signUpRequest({
        users: [body],
      })
        .then(resp => {
          console.log('success', resp);
          localStorage.setItem('spotlight-user-token', resp.message.token);

          const leadBody = {
            first_name: body.firstName,
            last_name: body.lastName,
            email: body.email,
            gmaps_address_string: body.gmaps_address_string,
            address: body.gmaps_address_string,
            phone_number: body.mobile,
            org_ID: process.env.REACT_APP_ORG_ID,
            latitude: body.latitude,
            longitude: body.longitude,
            user_ID: resp.message.users[0].userId,
          };

          if (body.both_interest) {
            createLeadonSignUp(leadBody);

            createLeadonSignUp(leadBody);
          } else {
            createLeadonSignUp(leadBody);
          }

          setTimeout(() => {
            enqueueSnackbar(resp.metaData.toastMessage, {variant: 'success'});
            setOpenProgressDialog(false);
            redirectToPage(
              history,
              'dashboard',
              '/dashboard',
              props.setAppBarPage
            );
            props.setUserAuthToken(resp.message.token);
          }, 1000);
        })
        .catch(err => {
          console.log('failure', err);

          enqueueSnackbar('Sign Up Issue', {
            variant: 'error',
          });
          setOpenProgressDialog(false);

          setSignUpErrorDialog(true);

          if (err.search('UNIQUE_EMAIL') > 0) {
            setSignUpErrorHeader('Email Exists.  ');
            setSignUpErrorMessage(['Please sign in or use another email.']);
          } else {
            setSignUpErrorHeader('Something went wrong.  ');
            setSignUpErrorMessage([
              'Please fill out the form on our website and we will get back to you shortly',
            ]);
          }
        });
    } else {
      let tempUserProfileData;

      getUserProfileBTRequest(newSocialUserToken)
        .then(resp => {
          tempUserProfileData = resp.message[0];

          tempUserProfileData = {
            ...tempUserProfileData,
            ...body,
          };

          console.log(tempUserProfileData);
          updateUserProfileBTRequest(tempUserProfileData, newSocialUserToken)
            .then(resp => {
              localStorage.setItem('spotlight-user-token', newSocialUserToken);

              const leadBody = {
                first_name: tempUserProfileData.first_name,
                last_name: tempUserProfileData.last_name,
                email: tempUserProfileData.email,
                gmaps_address_string: tempUserProfileData.gmaps_address_string,
                address: tempUserProfileData.gmaps_address_string,
                phone_number: tempUserProfileData.mobile,
                org_ID: process.env.REACT_APP_ORG_ID,
                latitude: tempUserProfileData.latitude,
                longitude: tempUserProfileData.longitude,
                user_ID: tempUserProfileData.user_ID,
              };

              if (tempUserProfileData.both_interest) {
                createLeadonSignUp(leadBody);
                createLeadonSignUp(leadBody);
              } else {
                createLeadonSignUp(leadBody);
              }

              setTimeout(() => {
                enqueueSnackbar('Profile Updated Successfully', {
                  variant: 'success',
                });
                setOpenProgressDialog(false);
                redirectToPage(
                  history,
                  'dashboard',
                  '/dashboard',
                  props.setAppBarPage
                );
                window.location.reload(true);
              }, 6000);
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
    }
  };

  const setContactDataAndProceed = values => {
    setContactInfo(values);
    setActiveStep(activeStep + 1);
  };

  const requestForgotPasswordOtp = body => {
    setOpenProgressDialog(true);
    sendForgotPasswordVerificationCodeRequest(body)
      .then(resp => {
        setOpenProgressDialog(false);
        console.log(resp);
        if (resp.success) {
          enqueueSnackbar(resp.metaData.toastMessage, {
            variant: 'success',
          });
          console.log(body, body.email, body.mobile);
          if (body.email) setForgotPasswordEmail(body.email);
          else if (body.mobile) setForgotPasswordEmail(body.mobile);
        } else {
          enqueueSnackbar(resp.message, {
            variant: 'error',
          });
        }
      })
      .catch(err => {
        setOpenProgressDialog(false);
        console.log(err);
        if (err == 'Error: User not found')
          enqueueSnackbar('Error: User not found', {
            variant: 'error',
          });
        else
          enqueueSnackbar('Error Occured', {
            variant: 'error',
          });
      });
  };

  const socialAuthRequest = async (email, socialPlatform, socialProfile) => {
    setOpenProgressDialog(true);
    try {
      const responseData = await socialSignInUpRequest({
        socialProfile,
        socialPlatform,
        email,
      });

      setOpenProgressDialog(false);

      //change to positive when its ready
      if (responseData.message.newSocialUser) {
        setNewSocialUserToken(responseData.message.token);
        setSignUpAction(true);
        setActiveStep(1);
      } else {
        localStorage.setItem(
          'spotlight-user-token',
          responseData.message.token
        );
        props.setUserAuthToken(responseData.message.token);
      }
    } catch (err) {
      setOpenProgressDialog(false);
    }
  };

  const responseGoogleFailure = response => {
    // if (googleLoginTry === 0) {
    //   setGoogleLoginTry(1);
    //   return;
    // }
    enqueueSnackbar('Error while trying to sign in', {variant: 'error'});
  };

  const responseGoogleSuccess = async response => {
    socialAuthRequest(
      response.profileObj.email,
      response.tokenObj.idpId,
      response
    );
    enqueueSnackbar('Successfully signed in', {variant: 'success'});
  };

  const responseFacebook = async response => {
    if (response.status === 'unknown') {
      console.log(response);
      enqueueSnackbar('Error while trying to sign in', {variant: 'error'});
    } else {
      socialAuthRequest(response.email, response.graphDomain, response);
      enqueueSnackbar('Successfully signed in', {variant: 'success'});
    }
  };

  const componentClicked = response => {
    console.log('Click', response);
  };

  return (
    <div className={classes.root}>
      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />

      <Dialog
        open={profileCreatedDialogOpen}
        onClose={() => setProfileCreatedDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant="h1"></Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h3">
              {'Please provide us with some additional details'}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setProfileCreatedDialogOpen(false)}
            autoFocus
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={signUpErrorDialog}
        onClose={() => setSignUpErrorDialog(true)}
        sx={{p: 2}}
      >
        <DialogTitle>
          <Typography variant="h1" sx={{textTransform: 'capitalize'}}>
            {signUpErrorHeader}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {signUpErrorMessage.length > 0 &&
              signUpErrorMessage.map((msg, index) => (
                <Typography key={index} sx={{mt: 2}} variant="h3">
                  {msg}
                </Typography>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container sx={{width: 1, p: 2}} justifyContent="space-between">
            <Grid item>
              <Button
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  fontWeight: 700,
                  fontSize: '1rem',
                  display: 'none',
                }}
              >
                Forgot Password
              </Button>{' '}
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={backOnSignupHandler}
              >
                Close
              </Button>

              <Button
                variant="contained"
                color="secondary"
                target="_blank"
                onClick={() => {
                  setSignUpErrorDialog(false);
                  setSignUpAction(false);
                  setSignInAction(false);
                  setForgotPasswordAction(true);
                }}
                autoFocus
                sx={{ml: 2}}
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {signUpAction && (
          <Grid container margin={'auto'}>
            <Grid
              container
              style={{width: '100%', padding: '2em'}}
              justifyContent={'center'}
            >
              <img src={CompanyWhiteLogo} alt="" height="40px" />
            </Grid>
            <Grid container style={{width: '100%'}}>
              <Box
                display={{
                  xs: 'none',
                  sm: 'none',
                  md: 'block',
                  lg: 'block',
                  xl: 'block',
                }}
                style={{width: '100%'}}
              >
                <Stepper
                  style={{width: '100%'}}
                  activeStep={activeStep}
                  alternativeLabel
                  connector={<ColorlibConnector />}
                  sx={{color: 'white'}}
                >
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {step.label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              <Grid container className={classes.mainGridContainer}>
                <Grid item xs={12}>
                  {activeStep === 0 && (
                    <Card className={classes.stepCard}>
                      <Grid
                        container
                        className={classes.rootCardContainer}
                        justifyContent={'center'}
                      >
                        <Grid item xs={12}>
                          <Typography variant="h1">
                            Tell us about yourself
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          sx={{
                            p: {xs: '0px 2px', sm: '0px 5px', md: '0px 20px'},
                          }}
                        >
                          <Formik
                            initialValues={{
                              firstname: '',
                              lastname: '',
                              email: '',
                              phone: '',
                              password: '',
                              passwordConfirm: '',
                              visible1: false,
                              visible2: false,
                            }}
                            validationSchema={Yup.object().shape({
                              firstname: Yup.string()
                                .matches(
                                  /^[A-Za-z\'\-., ]*$/,
                                  'Please enter valid name'
                                )
                                .min(
                                  2,
                                  'First name has to be a minimum of 2 characters.'
                                )
                                .max(
                                  30,
                                  'First name has to be a maximum of 30 characters.'
                                )
                                .required('First name is required.'),
                              lastname: Yup.string()
                                .matches(
                                  /^[A-Za-z\' ]*$/,
                                  'Please enter valid name'
                                )
                                .min(
                                  1,
                                  'Last name has to be a minimum of 1 characters.'
                                )
                                .max(
                                  30,
                                  'Last name has to be a maximum of 30 characters.'
                                )
                                .required('Last Name is required.'),
                              email: Yup.string()
                                .matches(
                                  /^([a-z0-9\.])+\@(([a-z0-9])+\.)+([a-z0-9]{2,4})+$/,
                                  'Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed'
                                )
                                .email('Email must be formatted correctly.')
                                .max(255),
                              // .required('Email is required.'),

                              phone: Yup.string()
                                .min(
                                  10,
                                  'Please enter a valid 10 digit Mobile Number'
                                )
                                .max(
                                  10,
                                  'Please enter a valid 10 digit Mobile Number'
                                )
                                .required(
                                  'Please enter a valid 10 digit Mobile Number'
                                ),

                              password: Yup.string()
                                .required('Please enter your Password')
                                .matches(
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                  'Minimum 8 characters long. Must contain 1 upper case alphabet, 1 lower case and one special character'
                                ),
                              passwordConfirm: Yup.string()
                                .required('Confirm Password is required')
                                .oneOf(
                                  [Yup.ref('password')],
                                  'Confirm Password should match'
                                ),
                            })}
                            onSubmit={values => {
                              setOpenProgressDialog(true);
                              const body = {
                                firstName: values.firstname,
                                lastName: values.lastname,
                                mobile: values.phone.toString(),
                                email: values.email,
                                password: values.password,

                                gmaps_address_string: '',
                                latitude: '',
                                longitude: '',
                                roof_size: 0,
                                terrace_yard_size: 0,
                                solar_roof_interest: '',
                                solar_pergola_interest: false,
                                both_interest: false,
                                previous_month_electricity_bill: 0,
                                energy_consumption: 0,
                                profile_complete: false,
                              };
                              signUpHandler(body);
                            }}
                          >
                            {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              touched,
                              values,
                              setFieldValue,
                            }) => {
                              return (
                                <form
                                  noValidate
                                  onSubmit={handleSubmit}
                                  className={classes.formStyle}
                                  style={{width: '100%'}}
                                >
                                  <Grid container style={{width: '100%'}}>
                                    <Grid
                                      item
                                      sm={12}
                                      md={6}
                                      className={classes.paddedInputContainers}
                                    >
                                      <TextField
                                        error={Boolean(
                                          touched.firstname && errors.firstname
                                        )}
                                        fullWidth
                                        //autoFocus
                                        helperText={
                                          touched.firstname && errors.firstname
                                        }
                                        label="First Name"
                                        margin="normal"
                                        name="firstname"
                                        onBlur={handleBlur}
                                        onChange={e => {
                                          handleChange(e);
                                          setfname(e.target.value);
                                        }}
                                        type="text"
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      sm={12}
                                      md={6}
                                      className={classes.paddedInputContainers}
                                    >
                                      <TextField
                                        error={Boolean(
                                          touched.lastname && errors.lastname
                                        )}
                                        fullWidth
                                        //autoFocus
                                        helperText={
                                          touched.lastname && errors.lastname
                                        }
                                        label="Last Name"
                                        margin="normal"
                                        name="lastname"
                                        onBlur={handleBlur}
                                        onChange={e => {
                                          handleChange(e);
                                          setlname(e.target.value);
                                        }}
                                        type="text"
                                        variant="outlined"
                                      />
                                    </Grid>

                                    <Grid
                                      item
                                      sm={12}
                                      md={6}
                                      className={classes.paddedInputContainers}
                                    >
                                      <TextField
                                        sx={{mt: 2}}
                                        error={Boolean(
                                          touched.phone && errors.phone
                                        )}
                                        fullWidth
                                        //autoFocus
                                        helperText={
                                          touched.phone && errors.phone
                                        }
                                        label="Mobile Number (Mandatory)"
                                        margin="normal"
                                        name="phone"
                                        onBlur={handleBlur}
                                        onChange={event => {
                                          handleChange(event);
                                          setisPhone(event.target.value);
                                        }}
                                        onWheel={e => e.target.blur()}
                                        type="number"
                                        variant="outlined"
                                        disabled={
                                          otpMobileRequested ||
                                          otpMobileValidated
                                        }
                                        InputProps={{
                                          startAdornment: process.env
                                            .REACT_APP_REACH_US_INFO_CONFIG ===
                                            '1' && (
                                            <InputAdornment
                                              style={{marginRight: 10}}
                                            >
                                              {'+91'}
                                            </InputAdornment>
                                          ),
                                        }}
                                        onKeyDown={evt =>
                                          (evt.key === 'e' ||
                                            evt.key === 'E') &&
                                          evt.preventDefault()
                                        }
                                      />

                                      <TextField
                                        sx={{mt: 2}}
                                        error={Boolean(
                                          touched.email && errors.email
                                        )}
                                        fullWidth
                                        helperText={
                                          touched.email && errors.email
                                        }
                                        label="Email"
                                        margin="normal"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={event => {
                                          handleChange(event);
                                          setisEmail(event.target.value);
                                        }}
                                        type="email"
                                        variant="outlined"
                                        disabled={otpRequested}
                                      />
                                    </Grid>

                                    <Grid
                                      item
                                      sm={12}
                                      md={6}
                                      className={classes.paddedInputContainers}
                                    >
                                      <TextField
                                        sx={{mt: 2}}
                                        error={Boolean(
                                          touched.password && errors.password
                                        )}
                                        fullWidth
                                        //autoFocus
                                        helperText={
                                          touched.password && errors.password
                                        }
                                        label="Password"
                                        margin="normal"
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={e => {
                                          handleChange(e);
                                          setpass(e.target.value);
                                        }}
                                        type={
                                          values.visible1 ? 'text' : 'password'
                                        }
                                        variant="outlined"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {values.visible1 ? (
                                                <IconButton
                                                  onClick={() =>
                                                    setFieldValue(
                                                      'visible1',
                                                      false,
                                                      false
                                                    )
                                                  }
                                                >
                                                  <VisibilityOffIcon color="primary" />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  onClick={() =>
                                                    setFieldValue(
                                                      'visible1',
                                                      true,
                                                      false
                                                    )
                                                  }
                                                >
                                                  <VisibilityIcon color="primary" />
                                                </IconButton>
                                              )}
                                            </InputAdornment>
                                          ),
                                        }}
                                      ></TextField>
                                      <TextField
                                        sx={{mt: 2}}
                                        error={Boolean(
                                          touched.passwordConfirm &&
                                            errors.passwordConfirm
                                        )}
                                        fullWidth
                                        //autoFocus
                                        helperText={
                                          touched.passwordConfirm &&
                                          errors.passwordConfirm
                                        }
                                        label="Confirm Password"
                                        margin="normal"
                                        name="passwordConfirm"
                                        onBlur={handleBlur}
                                        onChange={e => {
                                          handleChange(e);
                                          setconfirmpass(e.target.value);
                                        }}
                                        type={
                                          values.visible2 ? 'text' : 'password'
                                        }
                                        variant="outlined"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {values.visible2 ? (
                                                <IconButton
                                                  onClick={() =>
                                                    setFieldValue(
                                                      'visible2',
                                                      false,
                                                      false
                                                    )
                                                  }
                                                >
                                                  <VisibilityOffIcon color="primary" />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  onClick={() =>
                                                    setFieldValue(
                                                      'visible2',
                                                      true,
                                                      false
                                                    )
                                                  }
                                                >
                                                  <VisibilityIcon color="primary" />
                                                </IconButton>
                                              )}
                                            </InputAdornment>
                                          ),
                                        }}
                                      ></TextField>
                                    </Grid>

                                    <Grid
                                      container
                                      justifyContent={'center'}
                                      sx={{mt: 1}}
                                    >
                                      <Grid
                                        item
                                        container
                                        xs={12}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        style={{padding: '15px 40px'}}
                                      >
                                        <Grid item xs={3}>
                                          <Divider
                                            flexItem
                                            sx={{
                                              borderBottomWidth: 2,
                                              borderColor: 'grey',
                                            }}
                                          />
                                        </Grid>
                                        <Typography variant="h3">
                                          or sign up with
                                        </Typography>
                                        <Grid item xs={3}>
                                          <Divider
                                            flexItem
                                            sx={{
                                              borderBottomWidth: 2,
                                              borderColor: 'grey',
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        xs={12}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        style={{padding: '15px 40px'}}
                                      >
                                        <GoogleLogin
                                          clientId={
                                            process.env
                                              .REACT_APP_GOOGLE_AUTH_APP_ID
                                          }
                                          buttonText=""
                                          onSuccess={responseGoogleSuccess}
                                          onFailure={responseGoogleFailure}
                                          cookiePolicy={'single_host_origin'}
                                          className={classes.googleButton}
                                        />
                                        <FacebookLogin
                                          appId={
                                            process.env
                                              .REACT_APP_FACEBOOK_AUTH_APP_ID
                                          }
                                          // autoLoad={true}
                                          fields="name,email,picture"
                                          scope="public_profile, email, user_birthday"
                                          // onClick={componentClicked}
                                          callback={responseFacebook}
                                          cssClass={classes.facebookButton}
                                          icon="fa-facebook"
                                          textButton=""
                                        />
                                      </Grid>

                                      {/* Step 0: User adds mobile number */}
                                      {!otpMobileRequested &&
                                        !otpMobileValidated && (
                                          <Grid
                                            item
                                            container
                                            xs={6}
                                            justifyContent={'center'}
                                            style={{
                                              padding: '15px 10px',
                                              // backgroundColor: 'powderblue',
                                            }}
                                          >
                                            {isPhone &&
                                              isPhone.length == 10 &&
                                              setisPhoneBtn(
                                                'Verify Mobile Number'
                                              )}
                                            {isPhone &&
                                              isPhone.length != 10 &&
                                              setisPhoneBtn(
                                                'Enter Mobile Number'
                                              )}
                                            <Button
                                              variant="contained"
                                              size="large"
                                              color="secondary"
                                              onClick={() =>
                                                requestmobileOtp(values.phone)
                                              }
                                              disabled={
                                                values.phone === '' ||
                                                errors.phone ||
                                                otpMobileValidated ||
                                                !(
                                                  fname &&
                                                  fname.length > 1 &&
                                                  typeof fname === 'string'
                                                ) ||
                                                !lname ||
                                                !pass ||
                                                !confirmpass ||
                                                confirmpass !== pass
                                              }
                                            >
                                              {isPhoneBtn}
                                            </Button>
                                            {/* <Button
                                            size="large"
                                            color="secondary"
                                            onClick={() =>
                                              requestEmailOtp(values.email)
                                            }
                                            disabled={
                                              values.email === '' ||
                                              errors.email ||
                                              !otpMobileValidated
                                            }
                                          >
                                            {isEmailBtn}
                                          </Button> */}
                                          </Grid>
                                        )}
                                      {/* Step 1: User tries to validate mobile number */}
                                      {/* Mobile phone otp Validation */}
                                      {otpMobileRequested &&
                                        !otpMobileValidated && (
                                          <Grid
                                            item
                                            container
                                            lg={8}
                                            md={10}
                                            sm={12}
                                            justifyContent={'center'}
                                            style={{padding: '15px 40px'}}
                                            spacing={2}
                                          >
                                            <Grid item xs={12}>
                                              <Typography variant="h3">
                                                Please enter the OTP sent to
                                                your Mobile Number
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              container
                                              justifyContent={'space-between'}
                                              alignItems={'center'}
                                              xs={12}
                                            >
                                              <Grid item xs={12} sm={4}>
                                                <Button
                                                  variant="contained"
                                                  size="large"
                                                  color="secondary"
                                                  onClick={() => {
                                                    requestmobileOtp(
                                                      values.phone
                                                    );
                                                    setUserOtp('');
                                                  }}
                                                >
                                                  Resend otp
                                                </Button>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                sx={{mt: {xs: 2}}}
                                              >
                                                <TextField
                                                  type="number"
                                                  value={userOtp}
                                                  onChange={e =>
                                                    setUserOtp(e.target.value)
                                                  }
                                                  onWheel={e => e.target.blur()}
                                                ></TextField>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                sx={{mt: {xs: 2}}}
                                              >
                                                <Button
                                                  variant="contained"
                                                  size="large"
                                                  color="secondary"
                                                  onClick={() => {
                                                    validateMobileOtp(
                                                      values.phone,
                                                      userOtp
                                                    );
                                                    setUserOtp('');
                                                  }}
                                                  disabled={
                                                    userOtp.length !== 4
                                                  }
                                                >
                                                  Verify OTP
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        )}
                                      {/* Mobile phone validation */}
                                      {otpMobileRequested &&
                                        otpMobileValidated && (
                                          <Grid
                                            item
                                            container
                                            xs={12}
                                            justifyContent={'center'}
                                            sx={{mt: 2}}
                                          >
                                            <Typography variant="h3">
                                              Mobile Number successfully
                                              validated!
                                            </Typography>
                                            {isEmail &&
                                              (setisPhoneBtn(
                                                'Mobile Number Verified'
                                              ),
                                              setOtpMobileRequested(false))}
                                          </Grid>
                                        )}
                                      {/* Step 2: Email (Optional) */}
                                      {otpMobileValidated &&
                                        !otpRequested &&
                                        !otpValidated && (
                                          <Grid
                                            item
                                            container
                                            xs={12}
                                            justifyContent={'center'}
                                            style={{
                                              padding: '15px 10px',
                                              // backgroundColor: 'powderblue',
                                            }}
                                          >
                                            {isEmail &&
                                              setisEmailBtn('Verify Email ID')}
                                            {!isEmail &&
                                              setisEmailBtn(
                                                'Enter Email ID (Optional)'
                                              )}
                                            {/* <Button
                                            variant="contained"
                                            size="large"
                                            color="secondary"
                                            onClick={() =>
                                              requestmobileOtp(values.phone)
                                            }
                                            disabled={
                                              values.phone === '' ||
                                              errors.phone ||
                                              otpMobileValidated ||
                                              !(
                                                fname &&
                                                fname.length > 1 &&
                                                typeof fname === 'string'
                                              ) ||
                                              !lname ||
                                              !pass ||
                                              !confirmpass ||
                                              confirmpass !== pass
                                            }
                                          >
                                            {isPhoneBtn}
                                          </Button> */}
                                            <Button
                                              variant="contained"
                                              size="large"
                                              color="secondary"
                                              onClick={() =>
                                                requestEmailOtp(values.email)
                                              }
                                              disabled={
                                                values.email === '' ||
                                                errors.email ||
                                                !otpMobileValidated
                                              }
                                            >
                                              {isEmailBtn}
                                            </Button>
                                          </Grid>
                                        )}
                                      {/* Step 3:Email filled */}
                                      {otpRequested && !otpValidated && (
                                        <Grid
                                          item
                                          container
                                          lg={8}
                                          md={10}
                                          sm={12}
                                          justifyContent={'center'}
                                          style={{padding: '15px 40px'}}
                                          spacing={2}
                                        >
                                          <Grid item xs={12}>
                                            <Typography variant="h3">
                                              Please enter the OTP sent to your
                                              Email
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            container
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            xs={12}
                                          >
                                            <Grid item xs={12} sm={4}>
                                              <Button
                                                variant="contained"
                                                size="large"
                                                color="secondary"
                                                onClick={() => {
                                                  requestEmailOtp(values.email);
                                                  setUserOtp('');
                                                }}
                                              >
                                                Resend otp
                                              </Button>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={4}
                                              sx={{mt: {xs: 2}}}
                                            >
                                              <TextField
                                                type="number"
                                                value={userOtp}
                                                onChange={e =>
                                                  setUserOtp(e.target.value)
                                                }
                                                onWheel={e => e.target.blur()}
                                              ></TextField>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={4}
                                              sx={{mt: {xs: 2}}}
                                            >
                                              <Button
                                                variant="contained"
                                                size="large"
                                                color="secondary"
                                                onClick={() => {
                                                  validateEmailOtp(
                                                    values.email,
                                                    userOtp
                                                  );
                                                  setUserOtp('');
                                                }}
                                                disabled={userOtp.length !== 4}
                                              >
                                                Verify OTP
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}

                                      {/* Email Validation */}
                                      {otpRequested && otpValidated && (
                                        <Grid
                                          item
                                          container
                                          xs={12}
                                          justifyContent={'center'}
                                          sx={{mt: 2}}
                                        >
                                          <Typography variant="h3">
                                            Email successfully validated!
                                          </Typography>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    sx={{mt: 2, p: {md: 0, sm: 2, xs: 2}}}
                                    justifyContent={'space-between'}
                                  >
                                    <Button
                                      variant="contained"
                                      size="large"
                                      color="secondary"
                                      onClick={() => backFromSignIn()}
                                    >
                                      Back
                                    </Button>
                                    {/* <Button
                                      type="submit"
                                      variant="outlined"
                                      size="large"
                                      color="secondary"
                                      disabled={
                                        values.password === '' ||
                                        values.passwordConfirm === '' ||
                                        values.firstname === '' ||
                                        values.lastname === '' ||
                                        values.email === '' ||
                                        values.phone === '' ||
                                        errors.passwordConfirm ||
                                        errors.passwordConfirm ||
                                        errors.firstname ||
                                        errors.lastname ||
                                        errors.email ||
                                        errors.phone ||
                                        !otpValidated
                                      }
                                    >
                                      Sign up and go to dashboard
                                    </Button> */}
                                    {/* {console.log(isEmail, isPhone)}
                                    {isPhone &&
                                      setisEmailBtn('THEN VERIFY EMAIL')}
                                    {!isPhone &&
                                      setisEmailBtn('Please add Phone Number')} */}
                                    {isEmail && isPhone && (
                                      <Button
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        onClick={() =>
                                          setContactDataAndProceed(values)
                                        }
                                        disabled={
                                          values.password === '' ||
                                          values.passwordConfirm === '' ||
                                          values.firstname === '' ||
                                          values.lastname === '' ||
                                          values.email === '' ||
                                          values.phone === '' ||
                                          errors.passwordConfirm ||
                                          errors.passwordConfirm ||
                                          errors.firstname ||
                                          errors.lastname ||
                                          errors.email ||
                                          errors.phone ||
                                          !(otpValidated && otpMobileValidated)
                                        }
                                      >
                                        Next
                                      </Button>
                                    )}
                                    {!isEmail && isPhone && (
                                      <Button
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        onClick={() =>
                                          setContactDataAndProceed(values)
                                        }
                                        disabled={
                                          values.password === '' ||
                                          values.passwordConfirm === '' ||
                                          values.firstname === '' ||
                                          values.lastname === '' ||
                                          (values.email === '' &&
                                            values.phone === '') ||
                                          errors.passwordConfirm ||
                                          errors.passwordConfirm ||
                                          errors.firstname ||
                                          errors.lastname ||
                                          errors.email ||
                                          errors.phone ||
                                          !otpMobileValidated
                                        }
                                      >
                                        Next
                                      </Button>
                                    )}
                                  </Grid>
                                </form>
                              );
                            }}
                          </Formik>
                        </Grid>
                      </Grid>
                    </Card>
                  )}

                  {activeStep === 1 && (
                    <Card className={classes.stepCard}>
                      <Grid
                        container
                        className={classes.rootCardContainer}
                        justifyContent={'center'}
                        style={{height: '400px', padding: '0px 25px'}}
                      >
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                          <Typography variant="h1">
                            Where are you located ?
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                          <Typography variant="h3" style={{color: 'red'}}>
                            Place marker directly above your roof
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{position: 'relative', height: '290px'}}
                        >
                          <MapContainer
                            addressLatLng={addressLatLng}
                            updateAddressFromMap={updateAddressFromMap}
                            setValidGmapsAddress={setValidGmapsAddress}
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          style={{width: '100%', marginTop: 20}}
                          className={classes.subPaddedContainers}
                          justifyContent="space-between"
                        >
                          <Grid item xs={9} sm={10} md={11} lg={11}>
                            <MapAutocomplete
                              addressString={addressString}
                              setAddressString={setAddressString}
                              addressData={addressData}
                              updateAddressFromAutocomplete={
                                updateAddressFromAutocomplete
                              }
                              validGmapsAddress={validGmapsAddress}
                              setValidGmapsAddress={setValidGmapsAddress}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            container
                            justifyContent={'flex-end'}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              sx={{height: 1}}
                              onClick={handleGetUserLocation}
                            >
                              <MyLocationIcon sx={{transform: 'scale(1.5)'}} />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent={'space-between'}
                        style={{padding: 25}}
                        sx={{mt: 3}}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setActiveStep(activeStep - 1)}
                          disabled={true}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setActiveStep(activeStep + 1)}
                          disabled={addressString === '' || !validGmapsAddress}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Card>
                  )}

                  {activeStep === 2 && (
                    <Card className={classes.stepCard} sx={{p: 1}}>
                      <Grid
                        container
                        className={classes.rootCardContainer}
                        justifyContent={'center'}
                      >
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                          <Typography variant="h1">
                            Which Arka solution are you looking for?
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{width: '100%', padding: '0px 10px 0px 10px'}}
                          justifyContent={'center'}
                        >
                          <Grid
                            item
                            container
                            md={12}
                            lg={8}
                            justifyContent={'space-between'}
                            spacing={2}
                          >
                            <Grid item xs={12} style={{marginBottom: 20}}>
                              <Typography variant="h3">
                                A sneak preview of our solutions:
                              </Typography>
                            </Grid>
                            {energyProducts.map((product, index) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={4}
                                  lg={4}
                                  spacing={2}
                                  key={index}
                                >
                                  <Button
                                    style={{textTransform: 'none'}}
                                    variant="contained"
                                    color={
                                      selectedInterest &&
                                      selectedInterest.displayName ===
                                        product.displayName
                                        ? 'secondary'
                                        : 'primary'
                                    }
                                    onClick={() => setSelectedInterest(product)}
                                    fullWidth
                                  >
                                    {product.displayName}
                                  </Button>
                                </Grid>
                              );
                            })}
                          </Grid>
                          <Grid
                            item
                            container
                            md={12}
                            lg={7}
                            style={{width: '100%', marginTop: 10}}
                          >
                            {selectedInterest && selectedInterest.model && (
                              <model-viewer
                                src={selectedInterest.model}
                                poster={selectedInterest.image}
                                camera-controls
                                alt="A 3D model carousel"
                                shadow-intensity="1"
                                ar
                                ar-modes="webxr scene-viewer quick-look"
                                style={{height: 270, width: '100%'}}
                                // camera-orbit="90deg "
                              >
                                <button slot="ar-button" id="ar-button">
                                  View in 3D
                                </button>

                                <div id="ar-prompt">
                                  <img src={HandIcon} />
                                </div>

                                <button id="ar-failure">
                                  Please use a spacious outdoor environment to
                                  view the model properly
                                </button>
                              </model-viewer>
                            )}
                            {selectedInterest && !selectedInterest.model && (
                              <img
                                src={selectedInterest.image}
                                style={{
                                  height: 270,
                                  width: '100%',
                                  transform: '1000ms',
                                  transition: 'rotate(180deg)',
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        style={{padding: '0px 25px 25px 25px'}}
                        container
                        xs={12}
                        justifyContent={'space-between'}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setActiveStep(activeStep - 1)}
                          disabled={activeStep === 0}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setActiveStep(activeStep + 1)}
                          disabled={!selectedInterest}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Card>
                  )}

                  {activeStep === 3 && (
                    <Card className={classes.stepCard} sx={{px: {md: 3}}}>
                      <Grid container justifyContent={'center'}>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                          <Typography variant="h1">
                            What are your energy needs?
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          md={12}
                          lg={6}
                          className={classes.inputsContainer}
                        >
                          <Grid item container md={12} lg={12} spacing={2}>
                            <Grid item xs={12} style={{marginBottom: 5}}>
                              <Typography variant="h3">
                                I would like to share my energy needs in terms
                                of
                              </Typography>
                            </Grid>
                            <Grid item md={6} lg={3}>
                              <Button
                                variant="contained"
                                color={
                                  energyMode === energyModes[0]
                                    ? 'secondary'
                                    : 'primary'
                                }
                                style={{textTransform: 'none'}}
                                onClick={() => setEnergyMode(energyModes[0])}
                                fullWidth
                              >
                                {'Amount (' +
                                  process.env.REACT_APP_CURRENCY_SYMBOL +
                                  ')'}
                              </Button>
                            </Grid>
                            <Grid item md={6} lg={3}>
                              <Button
                                variant="contained"
                                color={
                                  energyMode === energyModes[1]
                                    ? 'secondary'
                                    : 'primary'
                                }
                                style={{textTransform: 'none'}}
                                onClick={() => setEnergyMode(energyModes[1])}
                                fullWidth
                              >
                                {'Units (kWh)'}
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item container md={12} lg={12} mt={3}>
                            {energyMode === energyModes[0] && (
                              <Grid item container xs={8}>
                                <Typography
                                  variant="h3"
                                  style={{margin: '10px 0px'}}
                                >
                                  On an average, my monthly electricity bill is:
                                </Typography>
                                <TextField
                                  type="number"
                                  onKeyDown={evt =>
                                    (evt.key === 'e' || evt.key === 'E') &&
                                    evt.preventDefault()
                                  }
                                  variant="outlined"
                                  placeholder="00.00"
                                  value={energyValue}
                                  onChange={e =>
                                    setEnergyValue(parseFloat(e.target.value))
                                  }
                                  fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment style={{marginRight: 10}}>
                                        {process.env.REACT_APP_CURRENCY_SYMBOL}
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                              </Grid>
                            )}
                            {energyMode === energyModes[1] && (
                              <Grid item container xs={8}>
                                <Typography variant="h3" style={{margin: 5}}>
                                  On an average, my monthly consumption is:
                                </Typography>
                                <TextField
                                  type="number"
                                  onKeyDown={evt =>
                                    (evt.key === 'e' || evt.key === 'E') &&
                                    evt.preventDefault()
                                  }
                                  variant="outlined"
                                  placeholder="000.00 "
                                  value={energyValue}
                                  onChange={e =>
                                    setEnergyValue(parseFloat(e.target.value))
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{marginLeft: 10}}
                                      >
                                        {'kWh'}
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                ></TextField>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          md={12}
                          lg={6}
                          className={classes.inputsContainer}
                        >
                          <Grid item container md={12} lg={12} spacing={2}>
                            <Grid item xs={12} style={{marginBottom: 5}}>
                              <Typography variant="h3">
                                Which Arka solution are you looking for?
                              </Typography>
                            </Grid>
                            {InterestMapping.map(item => {
                              return (
                                <Grid item md={6} lg={3} key={item.displayName}>
                                  <Button
                                    variant="contained"
                                    color={
                                      userInterest === item.displayName
                                        ? 'secondary'
                                        : 'primary'
                                    }
                                    style={{textTransform: 'none'}}
                                    onClick={() =>
                                      interestChangeHandler(item.displayName)
                                    }
                                    fullWidth
                                  >
                                    {item.displayName}
                                  </Button>
                                </Grid>
                              );
                            })}
                          </Grid>
                          <Grid item container md={12} lg={12} mt={3}>
                            {(userInterest === InterestMapping[0].displayName ||
                              userInterest ===
                                InterestMapping[2].displayName) && (
                              <Grid
                                item
                                container
                                xs={6}
                                style={{padding: '20px 10px 0px 10px'}}
                              >
                                <Typography
                                  variant="h3"
                                  style={{margin: '10px 0px'}}
                                  sx={{
                                    maxWidth: {
                                      xs: 130,
                                      sm: 'initial',
                                      md: 'initial',
                                    },
                                  }}
                                >
                                  Total area of your roof
                                </Typography>
                                <TextField
                                  type="number"
                                  onKeyDown={evt =>
                                    (evt.key === 'e' || evt.key === 'E') &&
                                    evt.preventDefault()
                                  }
                                  variant="outlined"
                                  placeholder="000.00"
                                  value={roofArea}
                                  onChange={e =>
                                    setRoofArea(parseFloat(e.target.value))
                                  }
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{marginLeft: 10}}
                                      >
                                        {'sq. ft.'}
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                              </Grid>
                            )}
                            {(userInterest === InterestMapping[1].displayName ||
                              userInterest ===
                                InterestMapping[2].displayName) && (
                              <Grid
                                item
                                container
                                xs={6}
                                style={{padding: '20px 10px 0px 10px'}}
                              >
                                <Typography
                                  variant="h3"
                                  style={{margin: '10px 0px'}}
                                  sx={{
                                    maxWidth: {
                                      xs: 130,
                                      sm: 'initial',
                                      md: 'initial',
                                    },
                                  }}
                                >
                                  Area available for Gazebo
                                </Typography>
                                <TextField
                                  type="number"
                                  onKeyDown={evt =>
                                    (evt.key === 'e' || evt.key === 'E') &&
                                    evt.preventDefault()
                                  }
                                  variant="outlined"
                                  placeholder="000.00"
                                  value={pergolaArea}
                                  onChange={e =>
                                    setPergolaArea(parseFloat(e.target.value))
                                  }
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{marginLeft: 10}}
                                      >
                                        {'sq. ft.'}
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item container xs={12} justifyContent="center">
                        <Alert
                          severity="info"
                          sx={{
                            alignItems: 'center',
                            mt: 2,
                            px: {xs: 1, sm: 2, md: 3},
                          }}
                          icon={false}
                        >
                          <Checkbox
                            color="secondary"
                            checked={termsNCondionsApproved}
                            sx={{ml: {xs: 0, sm: 2}}}
                            onChange={e => {
                              setTermsNCondionsApproved(
                                !termsNCondionsApproved
                              );
                            }}
                          ></Checkbox>
                          <a
                            href={`${spotlightBackendUrl}/terms`}
                            target="_blank"
                          >
                            I agree to the Terms of Use, and understand the
                            Privacy Policy
                          </a>
                        </Alert>
                      </Grid>
                      {/* Nav Buttons Container */}
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent={'space-between'}
                        style={{padding: 25}}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setActiveStep(activeStep - 1)}
                          disabled={activeStep === 0}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => completeSignUpHandler()}
                          disabled={
                            !energyValue ||
                            (!pergolaArea && !roofArea) ||
                            !termsNCondionsApproved
                          }
                        >
                          Sign Up
                        </Button>
                      </Grid>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {signInAction && (
          <Grid container justifyContent="center" sx={{width: 1, height: 1}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card sx={{height: 1}}>
                <Grid
                  container
                  justifyContent={'center'}
                  style={{width: '100%', textAlign: 'center', height: 1}}
                >
                  <Grid
                    item
                    container
                    md={12}
                    lg={8}
                    height={450}
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'none',
                        lg: 'initial',
                      },
                    }}
                  >
                    <img src={SignUpImage} className={classes.homeImage} />
                  </Grid>
                  <Grid
                    item
                    container
                    md={12}
                    lg={4}
                    style={{padding: '10% 0px'}}
                    justifyContent={'center'}
                  >
                    <Grid
                      xs={12}
                      container
                      style={{width: '100%'}}
                      justifyContent={'center'}
                    >
                      <img src={CompanyLogo} alt="" height="60px" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h1">Welcome to </Typography>
                      <img src={SpotlightLogo} height="30px" alt="Img" />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Typography variant="h2">
                        Please enter your credentials
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mx={4} my={1}>
                      <Formik
                        initialValues={{
                          email: '',
                          password: '',
                          visible: false,
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string('Enter your Email/Phone Number')
                            // .email("Enter a valid email")
                            .required('Email/Phone Number is required')
                            .test(
                              'test-name',
                              'Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed for email or valid 10 digit phone number ',
                              function (value) {
                                const emailRegex =
                                  /^([a-z0-9\.])+\@(([a-z0-9])+\.)+([a-z0-9]{2,4})+$/;
                                console.log(value);
                                const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
                                let isValidEmail = emailRegex.test(value);
                                let isValidPhone = phoneRegex.test(value);
                                console.log(isValidEmail, isValidPhone);
                                if (isValidEmail) loginWithEmailorPhone = 1;
                                else if (isValidPhone)
                                  loginWithEmailorPhone = 2;
                                if (!isValidEmail && !isValidPhone) {
                                  return false;
                                }
                                return true;
                              }
                            ),
                          password: Yup.string().required(
                            'Please Enter your password'
                          ),
                        })}
                        onSubmit={values => {
                          enqueueSnackbar('Attempting to sign in');
                          setOpenProgressDialog(true);

                          {
                            loginWithEmailorPhone === 1 &&
                              loginRequest({
                                email: values.email,
                                //mobile: values.email,
                                password: values.password,
                              })
                                .then(resp => {
                                  console.log(resp);
                                  setOpenProgressDialog(false);

                                  if (!resp.success) {
                                    enqueueSnackbar(resp.message, {
                                      variant: 'error',
                                    });
                                  } else {
                                    enqueueSnackbar(
                                      resp.metaData.toastMessage,
                                      {
                                        variant: 'success',
                                      }
                                    );
                                    localStorage.setItem(
                                      'spotlight-user-token',
                                      resp.message.token
                                    );
                                    props.setUserAuthToken(resp.message.token);
                                    redirectToPage(
                                      history,
                                      'dashboard',
                                      '/dashboard',
                                      props.setAppBarPage
                                    );
                                  }
                                })
                                .catch(err => {
                                  setOpenProgressDialog(false);

                                  console.log(err);
                                  enqueueSnackbar(err, {variant: 'error'});
                                });
                          }
                          {
                            loginWithEmailorPhone === 2 &&
                              loginRequest({
                                //email: values.email,
                                mobile: values.email,
                                password: values.password,
                              })
                                .then(resp => {
                                  console.log(resp);
                                  setOpenProgressDialog(false);

                                  if (!resp.success) {
                                    enqueueSnackbar(resp.message, {
                                      variant: 'error',
                                    });
                                  } else {
                                    enqueueSnackbar(
                                      resp.metaData.toastMessage,
                                      {
                                        variant: 'success',
                                      }
                                    );
                                    localStorage.setItem(
                                      'spotlight-user-token',
                                      resp.message.token
                                    );
                                    props.setUserAuthToken(resp.message.token);
                                    redirectToPage(
                                      history,
                                      'dashboard',
                                      '/dashboard',
                                      props.setAppBarPage
                                    );
                                  }
                                })
                                .catch(err => {
                                  setOpenProgressDialog(false);

                                  console.log(err);
                                  enqueueSnackbar(err, {variant: 'error'});
                                });
                          }
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          touched,
                          values,
                          setFieldValue,
                        }) => {
                          return (
                            <form
                              noValidate
                              onSubmit={handleSubmit}
                              className={classes.formStyle}
                              style={{width: '100%', padding: 10}}
                            >
                              {signinWithOtp == 0 && (
                                <Grid
                                  item
                                  container
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xs={12}
                                  flexDirection="column"
                                  justifyContent="center"
                                >
                                  <TextField
                                    style={{marginTop: 0}}
                                    error={Boolean(
                                      touched.email && errors.email
                                    )}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email or Phone Number"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    variant="outlined"
                                  />
                                  <TextField
                                    style={{marginTop: 0}}
                                    error={Boolean(
                                      touched.password && errors.password
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.password && errors.password
                                    }
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type={values.visible ? 'text' : 'password'}
                                    variant="outlined"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {values.visible ? (
                                            <IconButton
                                              onClick={() =>
                                                setFieldValue(
                                                  'visible',
                                                  false,
                                                  false
                                                )
                                              }
                                            >
                                              <VisibilityOffIcon color="primary" />
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              onClick={() =>
                                                setFieldValue(
                                                  'visible',
                                                  true,
                                                  false
                                                )
                                              }
                                            >
                                              <VisibilityIcon color="primary" />
                                            </IconButton>
                                          )}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent={'center'}
                                    alignItems="center"
                                  >
                                    <Typography variant="h4">
                                      Forgot Your Password?
                                    </Typography>
                                    <Button
                                      size="large"
                                      color="primary"
                                      onClick={switchToAndFromForgotPassword}
                                      style={{
                                        textTransform: 'none',
                                        textDecoration: 'underline',
                                        fontWeight: 700,
                                        fontSize: 12,
                                      }}
                                    >
                                      Click Here
                                    </Button>
                                  </Grid>
                                  {/*  */}
                                  <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent={'space-evenly'}
                                    sx={{mt: 1}}
                                  >
                                    <Button
                                      variant="contained"
                                      size="large"
                                      color="primary"
                                      onClick={() => setSignInAction(false)}
                                    >
                                      Back
                                    </Button>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      size="large"
                                      color="primary"
                                      disabled={errors.email || errors.password}
                                    >
                                      Sign In
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                              <Grid
                                container
                                sx={{pt: 2}}
                                justifyContent="center"
                                alignItems={'center'}
                              >
                                {signinWithOtp === 0 && (
                                  <Button
                                    // type="submit"
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    //sx={{ display: 'none' }}
                                    onClick={() => {
                                      setsigninWithOtp(1);
                                    }}
                                  >
                                    Sign In With Otp
                                  </Button>
                                )}
                                {signinWithOtp === 1 && (
                                  <Grid>
                                    <Grid
                                      container
                                      sx={{
                                        justifyContent: 'space-around',
                                        // backgroundColor: 'coral',
                                      }}
                                    >
                                      <Grid
                                        container
                                        sx={{justifyContent: 'center'}}
                                        xs={6}
                                      >
                                        <TextField
                                          // type="number"
                                          // value={userOtp}
                                          label="Enter Phone No"
                                          size="small"
                                          onChange={e =>
                                            setLoginWithOtp(e.target.value)
                                          }
                                        ></TextField>
                                        {console.log(loginWithOtp)}{' '}
                                      </Grid>
                                      <Grid
                                        container
                                        sx={{justifyContent: 'center'}}
                                        xs={6}
                                      >
                                        <Button
                                          // type="submit"
                                          variant="contained"
                                          size="small"
                                          color="secondary"
                                          onClick={() => {
                                            requestmobileotpsignin(
                                              loginWithOtp
                                            );
                                          }}
                                        >
                                          Send Otp
                                        </Button>
                                      </Grid>
                                    </Grid>
                                    <Grid sx={{p: 2}}>
                                      <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        onClick={() => setsigninWithOtp(0)}
                                      >
                                        Back
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                                {signinWithOtp === 2 && (
                                  <Grid
                                    item
                                    container
                                    //lg={8}
                                    //md={10}
                                    xs={12}
                                    sx={{pb: 3}}
                                    justifyContent={'center'}
                                    style={{padding: '15px 40px'}}
                                    spacing={2}
                                  >
                                    <Grid item xs={12} alignItems="center">
                                      <Typography variant="h3">
                                        Please enter the OTP sent to your Mobile
                                        for Sign In
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      container
                                      justifyContent={'space-between'}
                                      alignItems={'center'}
                                      xs={12}
                                    >
                                      <Grid item xs={3} alignItems="center">
                                        <Button
                                          variant="contained"
                                          size="small"
                                          color="secondary"
                                          onClick={() => {
                                            requestmobileotpsignin(
                                              loginWithOtp
                                            );
                                            setUserOtp('');
                                          }}
                                        >
                                          Resend otp
                                        </Button>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        //sm={4}
                                        alignItems="center"
                                        sx={{mt: {xs: 2}}}
                                      >
                                        <TextField
                                          type="number"
                                          size="small"
                                          value={userOtp}
                                          onChange={e =>
                                            setUserOtp(e.target.value)
                                          }
                                          onWheel={e => e.target.blur()}
                                        ></TextField>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        //sm={4}
                                        alignItems="center"
                                        sx={{mt: {xs: 2}}}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          color="secondary"
                                          onClick={() => {
                                            validateMobileLoginOtp(
                                              loginWithOtp,
                                              userOtp
                                            );
                                            setUserOtp('');
                                          }}
                                          disabled={userOtp.length !== 4}
                                        >
                                          Verify OTP
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </form>
                          );
                        }}
                      </Formik>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}

        {forgotPasswordAction && (
          <Grid container justifyContent="center" sx={{width: 1, height: 1}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card sx={{height: 1}}>
                <Grid
                  container
                  justifyContent={'center'}
                  style={{width: '100%', textAlign: 'center', height: 1}}
                >
                  <Grid
                    item
                    container
                    md={12}
                    lg={8}
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'none',
                        lg: 'initial',
                      },
                    }}
                  >
                    <img src={SignUpImage} className={classes.homeImage} />
                  </Grid>
                  <Grid
                    item
                    container
                    md={12}
                    lg={4}
                    style={{padding: '10% 0px'}}
                    justifyContent={'center'}
                  >
                    <Grid
                      xs={12}
                      container
                      style={{width: '100%'}}
                      justifyContent={'center'}
                    >
                      <img src={CompanyLogo} alt="" height="60px" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h1">Welcome to </Typography>
                      <img src={SpotlightLogo} height="30px" alt="Img" />
                    </Grid>

                    {!forgotPasswordEmail ? (
                      <Grid container style={{width: '100%'}}>
                        <Grid item xs={12} mt={2}>
                          <Typography variant="h2">
                            Please enter your registered email
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mx={4} my={1}>
                          <Formik
                            initialValues={{
                              email: '',
                            }}
                            validationSchema={Yup.object().shape({
                              email: Yup.string(
                                'Enter your registered Email/Phone Number'
                              )
                                // .email("Enter a valid email")
                                .required('Email/Phone Number is required')
                                .test(
                                  'test-name',
                                  'Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed for email or valid 10 digit phone number ',
                                  function (value) {
                                    const emailRegex =
                                      /^([a-z0-9\.])+\@(([a-z0-9])+\.)+([a-z0-9]{2,4})+$/;
                                    console.log(value);
                                    const phoneRegex =
                                      /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
                                    let isValidEmail = emailRegex.test(value);
                                    let isValidPhone = phoneRegex.test(value);
                                    console.log(isValidEmail, isValidPhone);
                                    if (isValidEmail)
                                      setForgotPassEmailorPhone(1);
                                    else if (isValidPhone)
                                      setForgotPassEmailorPhone(2);
                                    if (!isValidEmail && !isValidPhone) {
                                      return false;
                                    }
                                    return true;
                                  }
                                ),
                            })}
                            onSubmit={values => {
                              enqueueSnackbar('Acquiring OTP', values.email);
                              setOpenProgressDialog(true);
                              if (ForgotPassEmailorPhone === 1) {
                                requestForgotPasswordOtp({email: values.email});
                              } else {
                                requestForgotPasswordOtp({
                                  mobile: values.email,
                                });
                              }
                            }}
                          >
                            {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              touched,
                              values,
                              setFieldValue,
                            }) => {
                              return (
                                <form
                                  noValidate
                                  onSubmit={handleSubmit}
                                  className={classes.formStyle}
                                  style={{width: '100%', padding: 10}}
                                >
                                  <Grid
                                    item
                                    container
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xs={12}
                                    flexDirection="column"
                                    justifyContent="center"
                                  >
                                    <TextField
                                      style={{marginTop: 0}}
                                      error={Boolean(
                                        touched.email && errors.email
                                      )}
                                      fullWidth
                                      helperText={touched.email && errors.email}
                                      label="Email or Phone Number"
                                      margin="normal"
                                      name="email"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="email"
                                      variant="outlined"
                                    />

                                    <Button
                                      style={{marginTop: 15}}
                                      type="submit"
                                      variant="contained"
                                      size="large"
                                      color="secondary"
                                      disabled={
                                        errors.email || values.email === ''
                                      }
                                    >
                                      Request OTP
                                    </Button>
                                  </Grid>
                                </form>
                              );
                            }}
                          </Formik>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container style={{width: '100%'}}>
                        <Grid item xs={12} mt={2}>
                          <Typography variant="h2">
                            Enter OTP and your new password
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mx={4} my={1}>
                          <Formik
                            initialValues={{
                              otp: '',
                              password: '',
                              passwordConfirm: '',
                              visible1: false,
                              visible2: false,
                            }}
                            validationSchema={Yup.object().shape({
                              otp: Yup.number().min(1000).max(9999),
                              password: Yup.string()
                                .required('Please Enter your password')
                                .matches(
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                  'Minimum 8 characters long. Must contain 1 upper case alphabet, 1 lower case and one special character'
                                ),
                              passwordConfirm: Yup.string()
                                .required('Confirm Password is required')
                                .oneOf(
                                  [Yup.ref('password')],
                                  'Confirm Password should match'
                                ),
                            })}
                            onSubmit={values => {
                              enqueueSnackbar('Verifying details');
                              setOpenProgressDialog(true);
                              console.log(ForgotPassEmailorPhone);
                              if (ForgotPassEmailorPhone === 1) {
                                verifyOtpSetNewPasswordRequest({
                                  email: forgotPasswordEmail,
                                  otp: parseInt(values.otp),
                                  newPassword: values.password,
                                })
                                  .then(resp => {
                                    if (resp.success) {
                                      enqueueSnackbar(
                                        resp.metaData.toastMessage,
                                        {variant: 'success'}
                                      );
                                      setOpenProgressDialog(false);
                                      setForgotPasswordAction(false);
                                      setSignInAction(false);
                                      setForgotPasswordEmail(null);
                                    } else {
                                      throw new Error(resp.message);
                                    }
                                  })
                                  .catch(err => {
                                    console.log(err);
                                    enqueueSnackbar(err, {
                                      variant: 'error',
                                    });
                                    setOpenProgressDialog(false);
                                  });
                              } else if (ForgotPassEmailorPhone === 2) {
                                verifyOtpSetNewPasswordRequest({
                                  mobile: forgotPasswordEmail,
                                  otp: parseInt(values.otp),
                                  newPassword: values.password,
                                })
                                  .then(resp => {
                                    if (resp.success) {
                                      enqueueSnackbar(
                                        resp.metaData.toastMessage,
                                        {variant: 'success'}
                                      );
                                      setOpenProgressDialog(false);
                                      setForgotPasswordAction(false);
                                      setSignInAction(false);
                                      setForgotPasswordEmail(null);
                                    } else {
                                      throw new Error(resp.message);
                                    }
                                  })
                                  .catch(err => {
                                    console.log(err);
                                    enqueueSnackbar(err, {
                                      variant: 'error',
                                    });
                                    setOpenProgressDialog(false);
                                  });
                              }
                            }}
                          >
                            {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              touched,
                              values,
                              setFieldValue,
                            }) => {
                              return (
                                <form
                                  noValidate
                                  onSubmit={handleSubmit}
                                  className={classes.formStyle}
                                  style={{width: '100%', padding: 10}}
                                >
                                  <Grid
                                    item
                                    container
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xs={12}
                                    flexDirection="column"
                                    justifyContent="center"
                                  >
                                    {
                                      //blank textfield added due to prefilling of email in password field
                                      false && <TextField></TextField>
                                    }
                                    <TextField
                                      sx={{mt: 1}}
                                      error={Boolean(
                                        touched.password && errors.password
                                      )}
                                      fullWidth
                                      helperText={
                                        touched.password && errors.password
                                      }
                                      label="Password"
                                      margin="normal"
                                      name="password"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type={
                                        values.visible1 ? 'text' : 'password'
                                      }
                                      variant="outlined"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {values.visible1 ? (
                                              <IconButton
                                                onClick={() =>
                                                  setFieldValue(
                                                    'visible1',
                                                    false,
                                                    false
                                                  )
                                                }
                                              >
                                                <VisibilityOffIcon color="primary" />
                                              </IconButton>
                                            ) : (
                                              <IconButton
                                                onClick={() =>
                                                  setFieldValue(
                                                    'visible1',
                                                    true,
                                                    false
                                                  )
                                                }
                                              >
                                                <VisibilityIcon color="primary" />
                                              </IconButton>
                                            )}
                                          </InputAdornment>
                                        ),
                                      }}
                                    ></TextField>
                                    <TextField
                                      sx={{mt: 1}}
                                      error={Boolean(
                                        touched.passwordConfirm &&
                                          errors.passwordConfirm
                                      )}
                                      fullWidth
                                      //autoFocus
                                      helperText={
                                        touched.passwordConfirm &&
                                        errors.passwordConfirm
                                      }
                                      label="Confirm Password"
                                      margin="normal"
                                      name="passwordConfirm"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type={
                                        values.visible2 ? 'text' : 'password'
                                      }
                                      variant="outlined"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {values.visible2 ? (
                                              <IconButton
                                                onClick={() =>
                                                  setFieldValue(
                                                    'visible2',
                                                    false,
                                                    false
                                                  )
                                                }
                                              >
                                                <VisibilityOffIcon color="primary" />
                                              </IconButton>
                                            ) : (
                                              <IconButton
                                                onClick={() =>
                                                  setFieldValue(
                                                    'visible2',
                                                    true,
                                                    false
                                                  )
                                                }
                                              >
                                                <VisibilityIcon color="primary" />
                                              </IconButton>
                                            )}
                                          </InputAdornment>
                                        ),
                                      }}
                                    ></TextField>

                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      justifyContent="space-evenly"
                                      alignItems="center"
                                    >
                                      <Grid item xs={4}>
                                        <Button
                                          variant="contained"
                                          size="medium"
                                          color="secondary"
                                          fullWidth
                                          onClick={() =>
                                            requestForgotPasswordOtp({
                                              email: forgotPasswordEmail,
                                            })
                                          }
                                        >
                                          Resend OTP
                                        </Button>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <TextField
                                          sx={{mt: 1}}
                                          error={Boolean(
                                            touched.otp && errors.otp
                                          )}
                                          fullWidth
                                          //autoFocus
                                          helperText={touched.otp && errors.otp}
                                          label="OTP"
                                          margin="normal"
                                          name="otp"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          type="number"
                                          onKeyDown={evt =>
                                            (evt.key === 'e' ||
                                              evt.key === 'E') &&
                                            evt.preventDefault()
                                          }
                                          variant="outlined"
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Button
                                          type="submit"
                                          variant="contained"
                                          size="large"
                                          color="secondary"
                                          fullWidth
                                          disabled={
                                            !values.otp ||
                                            errors.otp ||
                                            !values.password ||
                                            errors.password ||
                                            !values.passwordConfirm ||
                                            errors.passwordConfirm
                                          }
                                        >
                                          Submit
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </form>
                              );
                            }}
                          </Formik>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}

        {!signUpAction && !signInAction && !forgotPasswordAction && (
          <Grid container justifyContent="center" sx={{width: 1, height: 1}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card sx={{height: 1}}>
                <Grid
                  container
                  justifyContent={'center'}
                  style={{width: '100%', textAlign: 'center', height: 1}}
                >
                  <Grid
                    item
                    container
                    md={12}
                    lg={8}
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'none',
                        lg: 'initial',
                      },
                    }}
                  >
                    <img src={SignUpImage} className={classes.homeImage} />
                  </Grid>
                  <Grid
                    item
                    container
                    md={12}
                    lg={4}
                    style={{padding: '10% 0px'}}
                    justifyContent={'center'}
                  >
                    <Grid
                      xs={12}
                      container
                      style={{width: '100%'}}
                      justifyContent={'center'}
                    >
                      <img src={CompanyLogo} alt="" height="60px" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h1">Welcome to </Typography>
                      <img src={SpotlightLogo} height="30px" alt="Img" />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Typography variant="h2">
                        How would you like to proceed
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      mt={2}
                      style={{width: '100%'}}
                      sx={{mx: {xs: 2, sm: 2, md: 2}}}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        style={{fontWeight: 600}}
                        onClick={() => setSignUpAction(true)}
                        fullWidth
                      >
                        Sign Up
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      mt={2}
                      style={{width: '100%'}}
                      sx={{mx: {xs: 2, sm: 2, md: 2}}}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        style={{fontWeight: 600}}
                        onClick={() => signInHandler()}
                        fullWidth
                      >
                        Sign In
                      </Button>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      mt={2}
                      style={{width: '100%'}}
                      sx={{mx: {xs: 2, sm: 2, md: 2}}}
                      justifyContent="space-evenly"
                      alignItems={'center'}
                    >
                      <Grid item xs={3}>
                        <Divider
                          flexItem
                          sx={{borderBottomWidth: 2, borderColor: 'grey'}}
                        />
                      </Grid>
                      <Typography variant="h3">or log in with</Typography>
                      <Grid item xs={3}>
                        <Divider
                          flexItem
                          sx={{borderBottomWidth: 2, borderColor: 'grey'}}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      mt={2}
                      style={{width: '100%'}}
                      sx={{mx: {xs: 2, sm: 2, md: 2}}}
                      justifyContent="space-around"
                    >
                      <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_AUTH_APP_ID}
                        buttonText=""
                        onSuccess={responseGoogleSuccess}
                        onFailure={responseGoogleFailure}
                        cookiePolicy={'single_host_origin'}
                        className={classes.googleButton}
                      />
                      <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_AUTH_APP_ID}
                        // autoLoad={true}
                        fields="name,email,picture"
                        scope="public_profile, email, user_birthday"
                        // onClick={componentClicked}
                        callback={responseFacebook}
                        cssClass={classes.facebookButton}
                        icon="fa-facebook"
                        textButton=""
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
};

const WrappedContainer = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_KEY,
})(InteractiveSignUp);

const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {
    userAuthToken,
  };
};
const mapDispatchToProps = dispatch => ({
  setUserAuthToken: userAuthToken => {
    dispatch({type: setUserAuthTokenAction, data: {userAuthToken}});
  },
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WrappedContainer);
