import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {Formik} from 'formik';
import React, {useState} from 'react';
import {useEffect} from 'react';
import * as Yup from 'yup';
import {
  getOtpSignUpEmailValidation as getEmailOtp,
  getOtpSignUpMobileValidation as getMobileOtp,
  verifyOtpSignUpEmailValidation as validateEmailOtp,
  verifyOtpSignUpMobileValidation as validateMobileOtp,
} from '../../util/ApiCalls/service';
import {useSnackbar} from 'notistack';
import OtpInput from 'react-otp-input';
import ValidateOtp from './ValidateOtp';
import CloseIcon from '@mui/icons-material/Close';
const appId = process.env.REACT_APP_APP_ID;
const orgId = process.env.REACT_APP_ORG_ID;

//modes
//1 - mobile
//2 - email
const modeData = {
  mobile: {
    displayName: 'Mobile No.',
    modeName: 'mobile',
    apiPropertyName: 'mobile_telephone_number',
  },
  email: {displayName: 'Email', modeName: 'email', apiPropertyName: 'email'},
};

const RequestOtp = ({mode, handleCloseDialog, onSuccess}) => {
  //stages
  //1 - request
  //2 - validate
  const [stage, setStage] = useState(1);
  const [validatedInput, setValidatedInput] = useState();
  const [otpValidated, setOtpValidated] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const handleRequestOtp = async value => {
    enqueueSnackbar('Requesting OTP');
    try {
      let body = {
        appId: appId,
        orgId: orgId,
      };
      if (mode === modeData.mobile.modeName) {
        body = {
          ...body,
          mobile_telephone_number: `${value}`,
          countryCode: process.env.REACT_APP_COUNTRY_CODE,
        };
        await getMobileOtp(body);
      } else if (mode === modeData.email.modeName) {
        body = {...body, email: value};
        await getEmailOtp(body);
      }
      enqueueSnackbar('OTP sent', {variant: 'success'});
      setValidatedInput(value);
      setStage(2);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error ? error : 'Error sending OTP', {
        variant: 'error',
      });
    }
  };

  const handleValidateOtp = async otp => {
    enqueueSnackbar('Validating OTP');
    try {
      let body = {
        otp: otp,
        appId: appId,
      };
      if (mode === modeData.mobile.modeName) {
        body = {...body, mobile_telephone_number: validatedInput};
        await validateMobileOtp(body);
      } else if (mode === modeData.email.modeName) {
        body = {...body, email: validatedInput};
        await validateEmailOtp(body);
      }
      enqueueSnackbar('OTP validated successfully', {variant: 'success'});
      setOtpValidated(true);
      onSuccess(modeData[mode].apiPropertyName, validatedInput);
      handleCloseDialog();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error ? error : 'Incorrect OTP entered', {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog open={true}>
      {mode && (
        <React.Fragment>
          <DialogTitle>
            <Grid container justifyContent={'space-between'}>
              <Typography variant="h2" sx={{lineHeight: 2}}>
                Update your {modeData[mode].displayName}
              </Typography>

              <IconButton onClick={handleCloseDialog}>
                <CloseIcon sx={{color: 'black'}} />
              </IconButton>
            </Grid>{' '}
          </DialogTitle>
          <DialogContent>
            {mode ? (
              stage === 1 ? (
                <React.Fragment>
                  {mode === modeData.mobile.modeName ? (
                    <Formik
                      initialValues={{
                        phone: '',
                      }}
                      validationSchema={Yup.object().shape({
                        phone: Yup.string()
                          .min(
                            10,
                            'Please enter a valid 10 digit Mobile Number'
                          )
                          .max(
                            10,
                            'Please enter a valid 10 digit Mobile Number'
                          )
                          .required(
                            'Please enter a valid 10 digit Mobile Number'
                          ),
                      })}
                      onSubmit={values => {
                        handleRequestOtp(values.phone);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                        setFieldValue,
                      }) => {
                        return (
                          <form
                            noValidate
                            onSubmit={handleSubmit}
                            style={{width: '100%', padding: 10}}
                          >
                            <Grid container sx={{width: 1}}>
                              <Grid item xs={12}>
                                <Typography sx={{color: '#6E6E73'}}>
                                  Enter the mobile number to associated with
                                  your account.
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sx={{mt: 2}}>
                                <TextField
                                  type="number"
                                  size="small"
                                  name="phone"
                                  value={values.phone}
                                  helperText={touched.phone && errors.phone}
                                  error={Boolean(touched.phone && errors.phone)}
                                  label={'Mobile Number'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onKeyDown={evt =>
                                    (evt.key === 'e' || evt.key === 'E') &&
                                    evt.preventDefault()
                                  }
                                  fullWidth
                                  InputProps={{
                                    startAdornment: process.env
                                      .REACT_APP_REACH_US_INFO_CONFIG ===
                                      '1' && (
                                      <InputAdornment style={{marginRight: 10}}>
                                        {'+91'}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sx={{mt: 2}}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                >
                                  Submit
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>
                  ) : mode === modeData.email.modeName ? (
                    <Formik
                      initialValues={{
                        email: '',
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .matches(
                            /^([a-z0-9\.])+\@(([a-z0-9])+\.)+([a-z0-9]{2,4})+$/,
                            'Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed'
                          )
                          .email('Email must be formatted correctly.')
                          .max(255),
                      })}
                      onSubmit={values => {
                        handleRequestOtp(values.email);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                        setFieldValue,
                      }) => {
                        return (
                          <form
                            noValidate
                            onSubmit={handleSubmit}
                            style={{width: '100%', padding: 10}}
                          >
                            <Grid container sx={{width: 1}}>
                              <Grid item xs={12}>
                                <Typography sx={{color: '#6E6E73'}}>
                                  Enter the email to associated with your
                                  account.
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sx={{mt: 1}}>
                                <TextField
                                  style={{marginTop: 0}}
                                  error={Boolean(touched.email && errors.email)}
                                  fullWidth
                                  helperText={touched.email && errors.email}
                                  label="Email"
                                  margin="normal"
                                  name="email"
                                  size="small"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="email"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                >
                                  Submit
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>
                  ) : (
                    <div>Invalid mode</div>
                  )}
                </React.Fragment>
              ) : (
                <Grid container>
                  {validatedInput && (
                    <ValidateOtp
                      validatedInput={validatedInput}
                      getOtp={() => handleRequestOtp(validatedInput)}
                      validateOtp={handleValidateOtp}
                    />
                  )}
                </Grid>
              )
            ) : (
              <div>No Mode set</div>
            )}
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default RequestOtp;
