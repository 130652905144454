import React, {useEffect, useState} from 'react';
import {
  Paper,
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Button,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
  Grid,
  IconButton,
  Badge,
  FormGroup,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  ListItemIcon,
} from '@mui/material';
import {useHistory} from 'react-router-dom';
import Search from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import {connect} from 'react-redux';
import {makeStyles, withTheme} from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import {styled} from '@mui/material/styles';

import NotificationsList from './NotificationList2';
import {
  toggleDarkMode as toggleDarkModeAction,
  setDashboardPage as setDashboardPageAction,
  setUserAuthToken as setUserAuthTokenAction,
  setAppBarPage as setAppBarPageAction,
  resetRedux as resetReduxAction,
} from '../constants/reduxActions';
import HelperPopover from './HelperPopover';

import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import EvStationIcon from '@mui/icons-material/EvStation';
import HistoryIcon from '@mui/icons-material/History';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LogoutIcon from '@mui/icons-material/Logout';
import redirectToPage from '../util/UtilFunctions/redirectToPage';

const SpotlightWhiteLogo = process.env.REACT_APP_SPOTLIGHT_WHITE_LOGO;

const useStyle = makeStyles(theme => ({
  textColor: {
    color: theme.palette.text.main,
  },
  appBarRoot: {
    zIndex: 99,
    boxShadow: '0px 0px',
    background: theme.palette.myBackground,
  },
}));

const drawerWidth = 240;

const pagesNameMapping = {
  dashboard: 'Dashboard',
  products: 'Products',
  orderHistory: 'Activity History',
  priceQuote: 'Quotes',
  paymentHistory: 'Payment History',
  documents: 'Documents',
  profile: 'Profile',
  support: 'Support',
  account: 'Account',
};

const pages = [
  'dashboard',
  'products',
  'orderHistory',
  'priceQuote',
  'paymentHistory',
  'documents',
  'profile',
  'support',
];

const pageIcons = [
  DashboardIcon,
  CategoryIcon,
  EvStationIcon,
  HistoryIcon,
  AssignmentIcon,
  AccountCircleIcon,
  SupportAgentIcon,
  LogoutIcon,
];
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root': {
    color: 'black',

    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const CustomAppBar = props => {
  const history = useHistory();
  const {instance, accounts, inProgress} = useMsal();
  const account = useAccount(accounts[0] || {});

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [notificationNavOpen, setNotificationNavOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(props.appBarPage);

  const [name, setName] = useState(null);

  const [mobileOpen, setMobileOpen] = useState(false);

  const classes = useStyle();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const BookSurveyButton = () => {
    if (
      props.userProfileData &&
      !props.userProfileData.site_survey_booked &&
      process.env.REACT_APP_SKIP_SITE_SURVEY === 'NO'
    )
      return (
        <Grid container alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              redirectToPage(
                history,
                'bookSurvey',
                '/bookSurvey',
                props.setAppBarPage
              )
            }
          >
            SCHEDULE A SITE SURVEY
          </Button>
          <HelperPopover
            text="Why Schedule A Survey?"
            color="#6E6E73"
            action={() =>
              redirectToPage(
                history,
                'bookSurveyInfo',
                '/bookSurveyInfo',
                props.setAppBarPage
              )
            }
          />
        </Grid>
      );
    else {
      return null;
    }
  };

  const StyledDrawer = styled(Drawer)(({theme, open}) => {
    if (theme.palette.mode === 'light' && !mobileOpen)
      return {
        '& .MuiDrawer-paper': {
          background: theme.palette.myBackground,
          color: theme.palette.primary.contrastText,

          '& .MuiListItem-root': {
            '&:hover': {
              background: '#263f58',
            },
          },
          '& .Mui-selected': {
            background:
              'linear-gradient(90deg, #2E3954 -32.03%, rgba(46, 57, 84, 0) 89.06%)',
          },
        },
      };
    else if (theme.palette.mode === 'light' && mobileOpen) {
      return {
        '& .MuiDrawer-paper': {
          background: 'rgba(255,255,255,1)',
        },
      };
    } else if (theme.palette.mode === 'dark') {
      return {
        '& .MuiDrawer-paper': {
          background: theme.palette.myBackground,
        },
      };
    }
  });

  const handleChangeTheme = () => {
    props.toggleDarkMode();
  };

  const changePage = page => {
    handleDrawerToggle();
    redirectToPage(history, page, '/' + page, props.setAppBarPage);
    if (page === 'Dashboard') props.setDashboardPage('page');
  };

  const logoutHandler = () => {
    props.resetRedux();
    history.push('/login');
    localStorage.removeItem('spotlight-user-token');
    window.location.reload();
  };

  const switchNotificationDrawerState = () => {
    setNotificationNavOpen(!notificationNavOpen);
  };

  useEffect(() => {
    setCurrentPage(props.appBarPage);
  }, [props.appBarPage]);

  return (
    <Box sx={{display: 'flex'}} style={{padding: 0}}>
      <CssBaseline />
      <Dialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h1">Logout</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h3">
              Are you sure you want to logout?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={logoutHandler}>
            Yes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setLogoutDialogOpen(false)}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Mobile Drawer and App Bar */}
      <Box
        display={{
          xs: 'block',
          sm: 'block',
          md: 'block',
          lg: 'none',
          xl: 'none',
        }}
      >
        <AppBar
          position="fixed"
          sx={{width: `100%`, ml: `${0}px`}}
          className={classes.appBarRoot}
        >
          <Toolbar>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={1}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                container
                xs={11}
                justifyContent="space-between"
                alignItems="center"
              >
                {!props.userProfileData && <Grid></Grid>}
                {console.log(props.userProfileData)}
                {props.userProfileData && props.userProfileData.first_name && (
                  <Grid item justifyContent="center;" alignItems="center">
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '5vw',
                          sm: '4vw',
                          md: '2.5vw',
                        },
                      }}
                    >
                      Welcome,{' '}
                      {props.userProfileData.first_name
                        .charAt(0)
                        .toUpperCase() +
                        props.userProfileData.first_name.slice(1)}
                    </Typography>
                  </Grid>
                )}
                {/* <Grid item container lg={3} xs={7}>
                <BookSurveyButton />
              </Grid> */}

                <Grid item justifyContent="center" alignItems="center">
                  <IconButton onClick={() => switchNotificationDrawerState()}>
                    <Badge
                      badgeContent={
                        props.notifications.filter(item => !item.dismissed)
                          .length
                      }
                      color="secondary"
                    >
                      <NotificationsNoneIcon color="secondary" />
                    </Badge>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <StyledDrawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="temporary"
          anchor="left"
          style={{zIndex: 2}}
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
        >
          <Toolbar>
            <img src={SpotlightWhiteLogo} alt="" style={{maxHeight: 50}} />
          </Toolbar>
          <List>
            {props.userProfileData &&
              pages.map((page, index) => {
                if (
                  (page === 'support' && props.userProfileData.projectId) ||
                  page !== 'support'
                ) {
                  return (
                    <ListItem
                      button
                      key={index}
                      onClick={() => {
                        setCurrentPage(page);
                        changePage(page);
                      }}
                      selected={currentPage === page}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography variant="h3">
                            {pagesNameMapping[page]}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                }
              })}
            <ListItem button onClick={() => setLogoutDialogOpen(true)}>
              <ListItemText
                disableTypography
                primary={<Typography variant="h3">Logout</Typography>}
              />
            </ListItem>
          </List>
        </StyledDrawer>
      </Box>

      {/* Desktop Drawer and App Bar */}
      <Box
        display={{xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block'}}
      >
        <AppBar
          position="fixed"
          sx={{width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`}}
          className={classes.appBarRoot}
        >
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              {props.userProfileData && props.userProfileData.first_name && (
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: '1.6vw',
                        xl: '1.6vw',
                      },
                    }}
                  >
                    Welcome,{' '}
                    {props.userProfileData.first_name.charAt(0).toUpperCase() +
                      props.userProfileData.first_name.slice(1)}
                  </Typography>
                </Grid>
              )}
              <Grid item container xs={3}>
                <BookSurveyButton />
              </Grid>
              <Grid
                item
                container
                xs={4}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  style={{marginRight: 15}}
                  color="secondary"
                  className="step-2"
                  onClick={() =>
                    redirectToPage(
                      history,
                      'contactUs',
                      '/contactUs',
                      props.setAppBarPage
                    )
                  }
                >
                  <b>REACH US</b>
                </Button>

                <Typography variant="h2">{name ? name : ''}</Typography>
                <IconButton onClick={() => switchNotificationDrawerState()}>
                  <Badge
                    badgeContent={
                      props.notifications.filter(item => !item.dismissed).length
                    }
                    color="secondary"
                  >
                    <NotificationsNoneIcon color="secondary" />
                  </Badge>
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <StyledDrawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              borderRight: '1px solid #77777777',
            },
          }}
          variant="permanent"
          anchor="left"
          // style={{ zIndex: 2, background: 'red' }}
          // classes={{ paper: classes.drawerRoot }}
        >
          <Toolbar>
            <img src={SpotlightWhiteLogo} alt="" style={{maxHeight: 40}} />
          </Toolbar>
          <List className="step-1">
            {props.userProfileData &&
              pages.map((page, index) => {
                if (
                  (page === 'support' && props.userProfileData.projectId) ||
                  page !== 'support'
                ) {
                  return (
                    <ListItem
                      button
                      key={index}
                      onClick={() => {
                        setCurrentPage(page);
                        window.scrollTo({top: 0, behavior: 'smooth'});

                        redirectToPage(
                          history,
                          page,
                          '/' + page,
                          props.setAppBarPage
                        );
                      }}
                      selected={currentPage === page}
                    >
                      <ListItemText primary={pagesNameMapping[page]} />
                    </ListItem>
                  );
                }
              })}
            <ListItem button onClick={() => setLogoutDialogOpen(true)}>
              <ListItemText primary={'Logout'} />
            </ListItem>
          </List>
        </StyledDrawer>
      </Box>

      {/* Right notifications drawer */}
      <Box
      // sx={{
      //   display: {
      //     xs: 'none',
      //     sm: 'none',
      //     md: 'none',
      //     lg: 'block',
      //     xl: 'block',
      //   },
      // }}
      >
        <StyledDrawer
          anchor="right"
          open={notificationNavOpen}
          onClose={() => switchNotificationDrawerState()}
        >
          <Grid
            container
            sx={{
              width: {xs: '100vw', sm: '100vw', md: 450, lg: 450},
              padding: 3,
            }}
          >
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Typography variant="h2">{name ? name : ''}</Typography>
              <IconButton
                onClick={() => switchNotificationDrawerState()}
                sx={{background: 'white'}}
              >
                <Badge
                  badgeContent={
                    props.notifications.filter(item => !item.dismissed).length
                  }
                  color="secondary"
                >
                  <NotificationsNoneIcon color="secondary" />
                </Badge>
              </IconButton>
            </Grid>
            <Grid item container style={{marginTop: 20}}>
              <NotificationsList
                viewAll={false}
                switchNotificationDrawerState={switchNotificationDrawerState}
              />
            </Grid>
          </Grid>
        </StyledDrawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          height: '100vh',
          overflow: 'auto',
          bgcolor: 'background.default',
          width: 'initial',
          zIndex: 2,
        }}
      >
        <div id={'app-bar-root'}>
          <DrawerHeader />
          <div>{props.children}</div>
        </div>
      </Box>
    </Box>
  );
};

const mapStateToProps = state => {
  const {darkMode, notifications, userProfileData, appBarPage} = state.app;
  return {darkMode, notifications, userProfileData, appBarPage};
};
const mapDispatchToProps = dispatch => ({
  toggleDarkMode: () => {
    dispatch({type: toggleDarkModeAction});
  },
  setDashboardPage: dashboardPage => {
    dispatch({type: setDashboardPageAction, data: {dashboardPage}});
  },
  setUserAuthToken: userAuthToken => {
    dispatch({type: setUserAuthTokenAction, data: {userAuthToken}});
  },
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
  resetRedux: () => {
    dispatch({type: resetReduxAction});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomAppBar);
