import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileViewer from 'react-file-viewer';
import Terms from '../assets/documents/Terms.docx';
import '../assets/styles/documentViewer.css';

const TermsNcondition = () => {
  const onError = e => {
    console.log(e, 'error in file-viewer');
  };

  return (
    <Grid container sx={{width: 1, color: 'black', mt: 1,px:3}}>
      <Accordion style={{width: '100%', background: '#c3c3c3'}} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
          <Typography variant="h2">{'TERMS & CONDITIONS'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FileViewer fileType={'docx'} filePath={Terms} onError={onError} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default TermsNcondition;
