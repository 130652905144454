import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {
  Grid,
  Typography,
  Card,
  Divider,
  FormControlLabel,
  InputLabel,
  Select,
  FormControl,
  Button,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  MenuItem,
  Badge,
  Box,
} from '@mui/material';
import {connect} from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import BlockIcon from '@mui/icons-material/Block';
import {FileUploader} from 'react-drag-drop-files';
import {
  getCustomerSupportRequestDetails as getCustomerSupportRequestDetailsRequest,
  createCutomerSupportRequest as createCutomerSupportRequestRequest,
} from '../../util/ApiCalls/service';
import axios from 'axios';

import RoofImage from '../../assets/images/powerroof_render.webp';
import PergolaImage from '../../assets/images/powergazebo_render.webp';
import ManOnRoofImage from '../../assets/images/loginBackground.webp';
import {yellow} from '@mui/material/colors';
import {useSnackbar} from 'notistack';

import ProgressDialog from '../../components/ProgressDialog';
import ViewSupportRequestDialog from './ViewSupportRequestDialog';
import TicketsList from './TicketsList';
import TicketItem from './TicketItem';

import './dragNdrop.css';
import {DeleteForever} from '@mui/icons-material';
import {DateTime} from 'luxon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import OverwriteDialog from './OverwriteDialog';

import {
  getPriceQuote as getPriceQuoteRequest,
  getOrderDetails as getOrderDetailsRequest,
} from '../../util/ApiCalls/service';
const useStyle = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  cardRoot: {
    width: '100%',
    padding: 10,
  },
  greyText: {
    color: 'grey',
  },
  mainPaddedContainers: {
    padding: '50px 100px 50px 100px',
  },
  paddedTextContainers: {
    padding: 20,
  },
  subPaddedContainers: {
    padding: 10,
  },
  marginTop: {
    marginTop: '5px !important',
  },
  dialogPaper: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
    paddingBottom: 8,
  },
  imageContainer: {
    width: '80px',
    height: '80px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
}));

const issueTypes = [
  'Process Query',
  'Payment Issue',
  'Product Support',
  'Services Query',
  'General Query',
];

const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG'];

const serverUrl = process.env.REACT_APP_BACKEND_URL;
const Support = props => {
  const classes = useStyle();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [openDialog, setOpenDialog] = useState(false);

  const [supportRequestData, setSupportRequestData] = useState(null);
  const [typeOfIssue, setTypeOfIssue] = useState(null);
  const [orderID, setOrderID] = useState(null);
  const [productID, setProductID] = useState(null);

  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');

  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  const [openViewSupportRequestDialog, setOpenViewSupportRequestDialog] =
    useState(false);

  const [selectedTicket, setSelectedTicket] = useState(null);
  const [files, setFiles] = useState([]);
  const [filesSrc, setFilesSrc] = useState([]);

  const [matchingFileIndex, setMatchingFileIndex] = useState(null);
  const [tempNewFile, setTempNewFile] = useState(null);

  const [orderData, setOrderData] = useState(null);
  const [quoteData, setQuoteData] = useState(null);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const getSupportRequestData = () => {
    enqueueSnackbar('Requesting support data');
    setOpenProgressDialog(true);
    getCustomerSupportRequestDetailsRequest()
      .then(resp => {
        enqueueSnackbar('Data received', {variant: 'success'});
        let tempData = resp.message;
        console.log({tempData});

        tempData.sort((a, b) =>
          DateTime.fromISO(a.created_date) < DateTime.fromISO(b.created_date)
            ? 1
            : DateTime.fromISO(a.created_date) >
              DateTime.fromISO(b.created_date)
            ? -1
            : 0
        );
        console.log({tempData});
        setSupportRequestData(tempData);

        setOpenProgressDialog(false);
      })
      .catch(err => {
        enqueueSnackbar('Error getting support data', {variant: 'error'});
        setOpenProgressDialog(false);
      });
  };

  useEffect(() => {
    getSupportRequestData();
    getOrderDetailsRequest()
      .then(resp => setOrderData(resp.message.orderData))
      .catch(err => console.log(err));

    getPriceQuoteRequest()
      .then(resp => {
        setQuoteData(resp.message);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {}, [files, filesSrc]);

  const handleAddFile = newFile => {
    console.log(newFile[0], files);
    let fileExists = null;
    if (files && files.length > 0) {
      files.map((file, index) => {
        console.log(file, newFile[0]);
        if (file.name === newFile[0].name) {
          setMatchingFileIndex(index);
          setTempNewFile(newFile[0]);
          fileExists = file;
        }
      });
    }

    if (fileExists || fileExists === 0) {
      console.log('overwrite?');
    } else {
      if (files.length === 3)
        enqueueSnackbar('Maximum of 3 files can be uploaded', {
          variant: 'info',
        });
      else {
        setFiles([...files, ...newFile]);
        setFilesSrc([...filesSrc, window.URL.createObjectURL(newFile[0])]);
      }
    }
  };

  const cancelOverWrite = () => {
    setMatchingFileIndex(null);
    setTempNewFile(null);
  };

  const initiateOverWrite = () => {
    let temp = files;
    let tempSrc = filesSrc;

    console.log(temp, tempSrc, tempNewFile);

    temp[matchingFileIndex] = tempNewFile;
    tempSrc[matchingFileIndex] = window.URL.createObjectURL(tempNewFile);

    console.log(temp, tempSrc);

    setFiles(temp);
    setFilesSrc(tempSrc);

    setMatchingFileIndex(null);
    setTempNewFile(null);
    forceUpdate();
  };

  const clearSupportCard = () => {
    setTypeOfIssue(null);
    setSubject('');
    setDescription('');
    setOrderID(null);
    setProductID(null);
    setFiles([]);
    setFilesSrc([]);
  };

  const createCutomerSupportRequest = () => {
    setOpenProgressDialog(true);
    enqueueSnackbar('Creating support request');
    let spotlightBackendUrl;
    if (process.env.REACT_APP_MODE == 'PROD') {
      spotlightBackendUrl = 'https://' + window.location.hostname;
    } else {
      spotlightBackendUrl = 'http://localhost:5001';
    }
    const formData = new FormData();
    if (files.length > 0) {
      files.map(item => formData.append('file', item));
    }
    formData.append('type_of_issue', typeOfIssue);
    formData.append('subject', subject);
    formData.append('description', description);

    if (typeOfIssue !== issueTypes[4]) {
      formData.append('order_ID', orderID);
    }
    if (typeOfIssue === issueTypes[2] || typeOfIssue === issueTypes[3]) {
      formData.append('product_ID', productID);
    }

    axios
      .post(
        `${spotlightBackendUrl}/api/support/createCustomerSupportRequest`,
        formData,
        {
          headers: {
            Authorization: props.userAuthToken,
          },
        }
      )
      .then(resp => {
        setOpenProgressDialog(false);
        enqueueSnackbar('Ticket successfully created');
        getSupportRequestData();
        setOpenDialog(false);
        clearSupportCard();
      })
      .catch(err => {
        setOpenProgressDialog(false);
        enqueueSnackbar('Error creating ticket');
      });
  };

  const handleChangeIssueType = e => {
    setTypeOfIssue(e.target.value);
  };

  const handleChangeSubject = e => {
    setSubject(e.target.value);
  };
  const handleChangeDescription = e => {
    setDescription(e.target.value);
  };
  const handleChangeOrderId = e => {
    setOrderID(e.target.value);
  };
  const handleChangeProductId = e => {
    setProductID(e.target.value);
  };

  const SelectedOrderItems = () => {
    let temp = quoteData.filter(item => item.order_ID === orderID);

    return (
      <Grid container sx={{width: 1}}>
        {temp.length === 1 && (
          <Grid item container xs={12}>
            {temp[0].lineItems.map((item, index) => {
              return (
                <MenuItem key={index} value={item.product_ID}>
                  {item.product_ID}
                </MenuItem>
              );
            })}
          </Grid>
        )}
      </Grid>
    );

    // {quoteData.filter(i => i.order_ID === orderID).lineItems.map((item, index) => {
    //   return (
    //     <MenuItem key={index} value={item}>
    //       {item}
    //     </MenuItem>
    //   );
    // })}
  };
  const selectTicketAndOpenDialog = ticket => {
    console.log('Clicked with', ticket);
    setSelectedTicket(ticket);
    setOpenViewSupportRequestDialog(true);
  };

  const handleRemoveImage = index => {
    console.log(index);
    console.log(files);
    let filteredFiles = files;
    filteredFiles.splice(index, 1);
    setFiles(filteredFiles);
    let filteredFilesSrc = filesSrc;
    filteredFilesSrc.splice(index, 1);
    setFilesSrc(filteredFilesSrc);
    forceUpdate();
  };

  const removeFile = index => {
    let temp = files;
    temp.splice(index, 1);
    console.log('heres', temp);
    setFiles(temp);
  };

  return (
    <div className={classes.root}>
      {/* Create Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          clearSupportCard();
        }}
        maxWidth={'xl'}
        style={{width: '100vw'}}
        PaperProps={{classes: {root: classes.dialogPaper}}}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h2">{'New Support Request'}</Typography>
        </DialogTitle>
        {quoteData && orderData && (
          <DialogContent>
            <Grid
              container
              style={{width: '100%'}}
              justifyContent="space-around"
            >
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Typography className={classes.marginTop} variant="h4">
                    {'Select the type of issue'}
                  </Typography>
                  <Typography
                    className={classes.marginTop}
                    variant="h4"
                    sx={{color: 'red', ml: 1}}
                  >
                    {'*'}
                  </Typography>
                </Grid>
                <Select
                  size="small"
                  className={classes.marginTop}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeOfIssue}
                  onChange={handleChangeIssueType}
                  fullWidth
                >
                  {orderData.length > 0
                    ? issueTypes.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })
                    : [issueTypes[4]].map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                </Select>

                {typeOfIssue && typeOfIssue !== issueTypes[4] && (
                  <Grid container sx={{width: 1}}>
                    <Grid container>
                      <Typography className={classes.marginTop} variant="h4">
                        {'Select Order'}
                      </Typography>
                      <Typography
                        className={classes.marginTop}
                        variant="h4"
                        sx={{color: 'red', ml: 1}}
                      >
                        {'*'}
                      </Typography>
                    </Grid>
                    <Select
                      className={classes.marginTop}
                      value={orderID}
                      onChange={handleChangeOrderId}
                      fullWidth
                      size="small"
                    >
                      {orderData.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.order_ID}>
                            {item.order_ID}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                )}

                {(typeOfIssue === issueTypes[2] ||
                  typeOfIssue === issueTypes[3]) &&
                  orderID && (
                    <Grid container sx={{width: 1}}>
                      <Grid container>
                        <Typography className={classes.marginTop} variant="h4">
                          {'Select Product'}
                        </Typography>
                        <Typography
                          className={classes.marginTop}
                          variant="h4"
                          sx={{color: 'red', ml: 1}}
                        >
                          {'*'}
                        </Typography>
                      </Grid>
                      <Select
                        size="small"
                        className={classes.marginTop}
                        value={productID}
                        onChange={handleChangeProductId}
                        fullWidth
                      >
                        {quoteData.filter(i => i.order_ID === orderID)
                          .length === 1 &&
                          quoteData
                            .filter(i => i.order_ID === orderID)[0]
                            .lineItems.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.product_ID}>
                                  {item.name + ' - ' + item.product_ID}
                                </MenuItem>
                              );
                            })}
                        {/* <SelectedOrderItems /> */}
                      </Select>
                    </Grid>
                  )}
                <Grid container>
                  <Typography className={classes.marginTop} variant="h4">
                    {'Subject'}
                  </Typography>
                  <Typography
                    className={classes.marginTop}
                    variant="h4"
                    sx={{color: 'red', ml: 1}}
                  >
                    {'*'}
                  </Typography>
                </Grid>
                <TextField
                  size="small"
                  className={classes.marginTop}
                  value={subject}
                  onChange={handleChangeSubject}
                  fullWidth
                />

                <Grid container>
                  <Typography className={classes.marginTop} variant="h4">
                    {'Description'}
                  </Typography>
                  <Typography
                    className={classes.marginTop}
                    variant="h4"
                    sx={{color: 'red', ml: 1}}
                  >
                    {'*'}
                  </Typography>
                </Grid>
                <TextField
                  className={classes.marginTop}
                  value={description}
                  onChange={handleChangeDescription}
                  maxRows={8}
                  multiline={true}
                  fullWidth
                  size="small"
                />
                <Grid container sx={{mt: 1}}>
                  {files &&
                    files.length > 0 &&
                    filesSrc &&
                    filesSrc.length > 0 && (
                      <Grid container sx={{width: 1}}>
                        {filesSrc.map((image, index) => (
                          <Grid item sx={{mr: 5, mt: 2}}>
                            <Badge
                              badgeContent={
                                <IconButton
                                  size="small"
                                  style={{padding: 0}}
                                  onClick={() => handleRemoveImage(index)}
                                >
                                  <CloseRoundedIcon sx={{fontSize: '1rem'}} />
                                </IconButton>
                              }
                              color="primary"
                              sx={{
                                '& .MuiBadge-badge': {
                                  padding: 0,
                                },
                              }}
                            >
                              <Box
                                component={'div'}
                                className={classes.imageContainer}
                              >
                                {' '}
                                <img
                                  src={image}
                                  alt="uploaded"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                              </Box>
                            </Badge>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  <Grid item xs={12}>
                    <Typography
                      className={classes.marginTop}
                      variant="h4"
                      sx={{mb: 1}}
                    >
                      {'Upload image - (optional - maximum 3)'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FileUploader
                      multiple={true}
                      handleChange={handleAddFile}
                      name="file"
                      types={fileTypes}
                      children={
                        <Grid
                          container
                          sx={{
                            height: 100,
                            width: 1,
                            border: `2px dashed`,
                            borderColor: 'secondary.main',
                          }}
                          justifyContent="center"
                        >
                          <Typography variant="h4" sx={{mt: 2}}>
                            Click here to upload or drag and drop
                          </Typography>
                        </Grid>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        )}

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenDialog(false);
              clearSupportCard();
            }}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            onClick={() => createCutomerSupportRequest()}
            disabled={description === '' || subject === '' || !typeOfIssue}
            color="secondary"
          >
            {'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
      {supportRequestData && (
        <ViewSupportRequestDialog
          openDialog={openViewSupportRequestDialog}
          setOpenDialog={setOpenViewSupportRequestDialog}
          supportRequestData={supportRequestData}
          selectedTicketFromParent={selectedTicket}
          setSelectedTicketFromParent={setSelectedTicket}
        />
      )}

      <OverwriteDialog
        matchingFileIndex={matchingFileIndex}
        fileName={tempNewFile ? tempNewFile.name : ''}
        cancelOverWrite={cancelOverWrite}
        initiateOverWrite={initiateOverWrite}
      />

      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />
      <Grid container style={{width: '100%'}}>
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Typography variant="h1" color="primary">
            Support
          </Typography>
        </Grid>

        <Grid item container xs={12}>
          {/* Main Card */}
          <Grid item container xs={12}>
            <Card sx={{mt: 1}}>
              <Grid
                container
                style={{width: '100%'}}
                justifyContent={'space-between'}
              >
                {/* Raise request card */}
                <Grid item container lg={5} md={12} sm={12} xs={12}>
                  <Grid
                    item
                    container
                    sm={12}
                    xs={12}
                    className={classes.mainPaddedContainers}
                    sx={{
                      p: {xs: 1, sm: 1, md: 3},
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      className={classes.paddedTextContainers}
                      style={{width: '100%'}}
                      sx={{textAlign: {xs: 'center'}}}
                    >
                      <Typography variant="h2">
                        How can we help you today?
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.paddedTextContainers}>
                      <Typography
                        variant="h3"
                        className={classes.greyText}
                        sx={{
                          letterSpacing: 0,
                          lineHeight: 1,
                          textAlign: {xs: 'center'},
                        }}
                      >
                        Tell us about your issue and we will get back to at the
                        earliest.
                      </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid
                        item
                        xs={12}
                        className={classes.subPaddedContainers}
                      >
                        <Button
                          onClick={() => setOpenDialog(true)}
                          variant="contained"
                          color="secondary"
                          fullWidth
                        >
                          <b>New Support Request</b>
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.subPaddedContainers}
                        sx={{textAlign: {xs: 'center'}}}
                      >
                        <Typography variant="h4">
                          Have any queries ? Contact us{' '}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.subPaddedContainers}
                        sx={{textAlign: {xs: 'center'}}}
                      >
                        <Typography variant="h4">{process.env.REACT_APP_LOCAL_CONTACT_NUMBER}</Typography>
                        <Typography variant="h4">
                          Contact Timings : 9 AM - 6 PM 
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{borderRightWidth: 3}}
                />
                {/* Ongoing requests status */}
                <Divider
                  width="100%"
                  flexItem
                  sx={{
                    borderColor: 'secondary.main',
                    borderBottomWidth: 3,
                    display: {md: 'initial', lg: 'none'},
                  }}
                />
                <Grid item xs={true}>
                  <Grid
                    item
                    container
                    className={classes.paddedTextContainers}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <Typography variant="h2">My Tickets</Typography>
                    </Grid>
                    <Grid item justifyContent={'flex-end'}>
                      <Button
                        onClick={() => setOpenViewSupportRequestDialog(true)}
                      >
                        View All
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider width="100%" flexItem sx={{borderBottomWidth: 3}} />

                  <Grid
                    item
                    container
                    xs={12}
                    sx={{overflow: 'auto', maxHeight: 375}}
                  >
                    {supportRequestData && (
                      <Grid container sx={{width: 1, height: '100%'}}>
                        <TicketsList
                          supportTickets={supportRequestData}
                          summaryView
                          setSelectedTicket={selectTicketAndOpenDialog}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* FAQ Card */}
          <Grid item container xs={12} mt={3}>
            <Card style={{width: '100%'}}>
              <Grid container style={{width: '100%'}}>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{px: 2, py: 2}}
                >
                  <Typography variant="h2">
                    Frequently Asked Questions
                  </Typography>
                  {/* <TextField
                    placeholder="Search for anything"
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        </>
                      ),
                    }}
                  ></TextField> */}
                </Grid>

                <Grid item container xs={12} sx={{px: 2, pb: 2}}>
                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {
                          '1. What products do you offer? How do they differ from regular rooftop PV systems?'
                        }
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {
                          'Our solar product line offers customers a premium residential PV system. Our products use aesthetic and durable '
                        }
                        <b>
                          <i>building-integrated-PV (BIPV)</i>
                        </b>
                        {
                          ' solar tiles instead of regular PV modules. The PowerRoof and PowerGazebo are superior PV solutions offering durable, weather-proof systems that seamlessly integrate with the villa architecture and provide usable recreational space, while producing electricity. The PowerRoof replaces a part or the whole of the roof facet while PowerGazebo is a terrace or ground mount structure that can be retrofitted on most homes / villas.  '
                        }
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {
                          '2. What is the difference between PowerRoof and PowerGazebo ? '
                        }
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {
                          'The following images should help understand the differences between the two products. PowerRoof is a custom solution integrated into aesthetic villa designs that adds PV system with varying capacity and is installed during the construction of the villa or while replacing the roof. PowerRoof can be a partial or complete roof solution that is durable and all weather-proof. Partial roof facets could be replaced with PowerRoof to make them energy generating, reduce cost and increase use of sustainable roofing materials instead of RCC.  '
                        }
                      </Typography>
                      <Grid
                        container
                        // sx={{width:1, py: 2}}
                        md={12}
                        xs={12}
                        justifyContent={'space-evenly'}
                      >
                        <Grid
                          container
                          // sx={{width:1, py: 2}}
                          md={6}
                          xs={12}
                          justifyContent={'space-evenly'}
                          style={{padding: '5px'}}
                        >
                          <img
                            src={RoofImage}
                            sx={{height: {lg: 300, md: 200, sm: 200, xs: 200}}}
                            width={'95%'}
                          ></img>
                        </Grid>
                      </Grid>
                      <Typography variant="h5">
                        {
                          'PowerGazebo is a terrace or ground mount structure that provides an aesthetic recreational space integrated with a PV system. PowerGazebo systems are available in standard configurations of 180 sq. ft. / 2.4 kW PV, 250 sq. ft. / 3.46 kW PV, and 400 sq. ft. / 5.1 kW PV. A PowerGazebo can be installed by our approved dealers or installers on most terraces or backyards.'
                        }
                      </Typography>
                      <Grid
                        container
                        // sx={{width:1, py: 2}}
                        md={12}
                        xs={12}
                        justifyContent={'space-evenly'}
                      >
                        <Grid
                          container
                          // sx={{width:1, py: 2}}
                          md={6}
                          xs={12}
                          justifyContent={'space-evenly'}
                          style={{padding: '5px'}}
                        >
                          <img
                            src={PergolaImage}
                            sx={{height: {md: 300, xs: 200}}}
                            width={'95%'}
                          ></img>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {
                          '3. How are BIPV solar tiles different from conventional solar panels? '
                        }
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {'Arka BIPV solar tiles '}
                        <b>
                          <i>replace the actual roof structures</i>
                        </b>
                        {
                          ' unlike conventional solar panels intended to be installed on racking structures on top of a roof. Arka products include solar tiles, custom-designed mounting structure, and an improved electrical interconnection to offer a '
                        }
                        <b>
                          <i>solar roof</i>
                        </b>
                        {
                          'solution. While conventional PV modules are made with glass-on-back sheet with metal frames for structural integrity, Arka products are built with tempered glass-on-glass without a metal frame offering superior durability, inherent structural integrity, and ruggedness. Arka solutions offer '
                        }
                        <b>
                          <i>all weather-proof roof</i>
                        </b>
                        {' replacements which is '}
                        <b>
                          <i>not the case with conventional PV systems.</i>
                        </b>
                      </Typography>
                      <Grid
                        container
                        sx={{width: 1, py: 2}}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          container
                          // sx={{width:1, py: 2}}
                          md={6}
                          xs={12}
                          justifyContent={'space-evenly'}
                          style={{padding: '5px'}}
                        >
                          {
                            <img
                              src={ManOnRoofImage}
                              sx={{height: {md: 300, xs: 200}}}
                              width={'95%'}
                            ></img>
                          }
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {
                          '4. What about performance? Do they perform same or better than conventional solar panels?  '
                        }
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {
                          'Arka products use an innovative solar tile technology to maximize power density and performance. While most conventional solar panels use 150/162 mm full solar cells, Arka products are built with one-third cut high-performance 182 mm solar cells offering more power from a smaller area. Shingling (cutting) of solar cells increases power density and offers a superior aesthetic finish along with increased reliability and energy production. Each Arka tile produces about 77 W of power in an area of 250 sq. ft. PowerGazebo™ has forty-five such tiles for an aggregate of 3.46 kW rated DC capacity. '
                        }
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {'5. What about durability and reliability?  '}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {
                          'Arka products undergo rigorous reliability to ensure reliability and durability. Solar tiles are tested to meet BIS standards compliance and long-term reliability. Specifically, they are IS/IEC 61215, IS/IEC61730 (PART 1), IS/IEC 61730 (PART 2) certified. In addition, they are tested in environmental chambers to ensure 25-year life, and can withstand 140 km/hr cyclone winds, extreme hail, heavy rain, and snow loads. The PowerRoof and PowerGazebo products are exhaustively tested to last through their projected 25+ year lifetime. Arka tiles have been assessed and approved by BIS. The power inverter and gateway are approved for installation and commissioning by local utilities and meet the ALMM / MNRE standards. '
                        }
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {
                          '6. Are your systems approved by utility and local power authorities? '
                        }
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {
                          'All Arka products, including associated mounting structure and electronics are in the process of being approved at the local level. We use rigorously tested and qualified inverters in the PV systems. We currently offer on-grid PV systems that have the added benefit of “pay back” from local utilities for energy generated and fed back into the grid. In the near future, we will also offer hybrid inverter-based solutions with Lithium-Ion battery storage. '
                        }
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {'7. How do I keep track of my energy production?  '}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {
                          'Energy production from the Arka products is monitored continuously with our gateway that includes a '
                        }
                        <b>
                          <i>Jio cellular Modem and WiFi for backup/</i>
                        </b>
                        {
                          ' The energy production information gathered every 15 mins and stored on our cloud servers. This data is available to the system owners to view through the '
                        }
                        <b>
                          <i>Spotlight mobile and web apps.</i>
                        </b>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {
                          '8. Is the PowerGazebo waterproof? Can the PowerRoof replace a complete roof or roof facet?  '
                        }
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {
                          'Yes, the PowerGazebo and the PowerRoof are all-weather proof and completely waterproof. The PowerRoof product includes a waterproof underlayment that withstands all inclement weather conditions like rain, snow, sleet, and cyclones. The PowerGazebo includes the same and additionally has a false ceiling and improved aesthetics.  '
                        }
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {
                          '9. Can a PowerGazebo be installed on my older home RCC roof?  '
                        }
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {
                          'Yes. Our installation crew will do a site survey to assess the roof condition, determine the right location and orientation for the PowerGazebo. Once the site survey is completed, a design plan is shared with customers for their review and additional checks on electrical circuits/main panel are performed before an actual installation is scheduled.  '
                        }
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {
                          '10. Dust and soiling are a problem for PV systems in India - how are PowerGazebo and PowerRoof cleaned?'
                        }
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {
                          'We can recommend simple and innovative solutions to keep the Arka systems performing at their peak. You can either signup for the periodic maintenance with our installation partners or buy one of their cleaning accessories.  '
                        }
                      </Typography>
                      <Grid
                        container
                        sx={{width: 1, py: 2}}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          container
                          // sx={{width:1, py: 2}}
                          md={6}
                          xs={12}
                          justifyContent={'space-evenly'}
                          style={{padding: '5px'}}
                        >
                          <img
                            src={RoofImage}
                            sx={{height: {md: 300, xs: 200}}}
                            width={'95%'}
                          ></img>
                        </Grid>

                        <Grid
                          container
                          // sx={{width:1, py: 2}}
                          md={6}
                          xs={12}
                          justifyContent={'space-evenly'}
                          style={{padding: '5px'}}
                        >
                          <img
                            src={PergolaImage}
                            sx={{height: {md: 300, xs: 200}}}
                            width={'95%'}
                          ></img>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion style={{width: '100%', background: '#c3c3c3'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h3">
                        {
                          '11. How long will it take to install a PowerGazebo? How long does it take to install a PowerRoof?  '
                        }
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white'}}>
                      <Typography variant="h5">
                        {
                          'A 2-hour pre-install site survey is scheduled to assess the site and check on the electrical interconnections. PowerGazebo systems of 180 sq. ft. and 250 sq. ft. take about 2 days to install, while the larger systems, 400 sq. ft and above, would take around 3-5 days. Custom PowerRoof solutions designed for villas or individual residential homes would take 5-7 days to install and commission. '
                        }
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {userAuthToken};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Support);
