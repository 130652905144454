import React, {useEffect, useState, Suspense, lazy} from 'react';
import {makeStyles} from '@mui/styles';
import {
  Typography,
  Grid,
  Card,
  Button,
  Skeleton,
  Box,
  Divider,
  Chip,
  CardContent,
  IconButton,
  Alert,
  AlertTitle,
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {setAppBarPage as setAppBarPageAction} from '../../constants/reduxActions';
import RazorpayPaymentGateway from '../../components/RazorpayPaymentGateway';
import {
  getProposalDataWithHistory as getProposalDataWithHistoryRequest,
  getPaymentInstallments as getPaymentInstallmentsRequest,
  getReceipt,
} from '../../util/ApiCalls/service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {DateTime} from 'luxon';
import redirectToPage from '../../util/UtilFunctions/redirectToPage';

import DownloadIcon from '@mui/icons-material/Download';
import ProgressDialog from '../../components/ProgressDialog';
import {useSnackbar} from 'notistack';
import PaymentInstallments from '../PaymentInstallments';
import {
  numericNullChecker,
  stringNullChecker,
} from '../../util/UtilFunctions/nullCheckers';
import CustomNoRowsOverlay from '../../components/NoRowsOverlay';

function createData(name, calories, fat, carbs, protein) {
  return {name, calories, fat, carbs, protein};
}

function PaymentHistory(props) {
  const history = useHistory();
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const [paymentInstallmentsData, setPaymentInstallmentsData] = useState(null);

  const [currentPaymentIndex, setCurrentPaymentIndex] = useState();

  const [currentPaidPayment, setCurrentPaidPayment] = useState(null);

  const [paymentHistory, setPaymentHistory] = useState(null);

  const [open, setOpenDialog] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const getPaymentInstallmentsHandler = () => {
    // getPaymentInstallmentsRequest()
    //   .then(resp => {
    //     let {installments, paymentHistory, cashPaymentHistory} = resp.message;

    //     setPaymentInstallmentsData(installments);
    //     if(cashPaymentHistory && cashPaymentHistory.length>0){
    //       cashPaymentHistory = cashPaymentHistory.map(payment => ({
    //         ...payment,
    //         amount_due: payment.amount_due || '0',
    //         status: 'captured',
    //       }));
  
    //       paymentHistory = [...paymentHistory, ...cashPaymentHistory];

    //     }
    //     let sortedPaymentHistory = []
    //     if (paymentHistory && paymentHistory.length > 0) {
    //        sortedPaymentHistory = paymentHistory.sort(
    //         (a, b) => new Date(b.created_at) - new Date(a.created_at)
    //       );

    //     } 
        
    //     setPaymentHistory(sortedPaymentHistory);


    //     if (
    //       resp.message.paymentHistory &&
    //       resp.message.paymentHistory.length > 0
    //     ) {
    //       resp.message.paymentHistory
    //         .filter(payment => payment.status === 'captured')
    //         .map(
    //           payment =>
    //             props.currentStep.payment_installment_id ===
    //               payment.payment_installment_ID &&
    //             setCurrentPaidPayment(payment)
    //         );
    //     }

    //     installments.map((item, index) => {
    //       if (
    //         props.currentStep.payment_installment_id ===
    //         item.payment_installment_ID
    //       ) {
    //         setCurrentPaymentIndex(index);
    //       }
    //     });
    //   })
    //   .catch(err => console.log(err));
      getProposalDataWithHistoryRequest(true)
      .then(resp => {
         let { quotes, paymentHistory, cashPaymentHistory} = resp.message;
         let installmentArray = [];
         quotes.map(item =>{
          installmentArray.push(item.installments)
         })
        setPaymentInstallmentsData(installmentArray);
        if(cashPaymentHistory && cashPaymentHistory.length>0){
          cashPaymentHistory = cashPaymentHistory.map(payment => ({
            ...payment,
            amount_due: payment.amount_due || '0',
            status: 'captured',
          }));
  
          paymentHistory = [...paymentHistory, ...cashPaymentHistory];

        }
        let sortedPaymentHistory = []
        if (paymentHistory && paymentHistory.length > 0) {
           sortedPaymentHistory = paymentHistory.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

        } 
        
        setPaymentHistory(sortedPaymentHistory);


        if (
          resp.message.paymentHistory &&
          resp.message.paymentHistory.length > 0
        ) {
          resp.message.paymentHistory
            .filter(payment => payment.status === 'captured')
            .map(
              payment =>
                props.currentStep.payment_installment_id ===
                  payment.payment_installment_ID &&
                setCurrentPaidPayment(payment)
            );
        }

        installmentArray.map((item, index) => {
          if (
            props.currentStep.payment_installment_id ===
            item.payment_installment_ID
          ) {
            setCurrentPaymentIndex(index);
          }
        });
      })
      .catch(err => console.log(err));
  };

  const checkPayment = (paymentHistory, installmentId) => {
    if (!paymentHistory) return false;
    const payment = paymentHistory.find(
      payment =>
        payment.status === 'captured' &&
        payment.payment_installment_ID === installmentId
    );

    if (payment) return true;
    else return false;
  };
  useEffect(() => {
    console.log(props);
    if (props.userAuthToken && !paymentInstallmentsData && props.currentStep) {
      getPaymentInstallmentsHandler();
    } else {
      console.log('fails', props.msalToken, paymentInstallmentsData);
    }
  }, []);

  const mapPaymentState = status => {
    if (status === 'created') {
      return {label: 'Pending', color: 'success'};
    }
    if (status === 'captured') {
      return {label: 'Successful', color: 'success'};
    }

    if (status === 'failed') {
      return {label: 'Failed', color: 'error'};
    }

    return {label: status, color: ''};
  };

  const isDue = paymentInstallmentsData
    ? paymentInstallmentsData.every(installment => installment.amount !== null)
    : false;

  const handleDownload = async installmentId => {
    const individualId = paymentHistory[0].individual_ID;
    setOpenDialog(true);

    try {
      const res = await getReceipt(installmentId, individualId);
      const reciptname = res.message.receipt_name;

      fetch(
        `https://arka-functions-app.azurewebsites.net/api/sasGenerator?name=${reciptname}`
      )
        .then(resp => resp.text())
        .then(resp => {
          window.location.assign(resp);
          setOpenDialog(false);

          enqueueSnackbar('Receipt Downloaded Successfully', {
            variant: 'success',
            autoHideDuration: 2000,
          });
        })
        .catch(err => {
          setOpenDialog(false);
          enqueueSnackbar('Error occured', {
            variant: 'success',
            autoHideDuration: 2000,
          });

          console.log(err);
        });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Error occured', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }

    setOpenDialog(false);
  };

  return (
    <Box sx={{width: 1}}>
      <ProgressDialog open={open} setOpenDialog={setOpenDialog} />
      <Grid
        container
        style={{width: '100%'}}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <PaymentInstallments historical />
        </Grid>

        <Grid item container xs={12} style={{padding: 16}} mt={2}>
          <Grid item xs={12}>
            <Typography variant="h3" color="primary">
              {' '}
              Your Transaction History
            </Typography>
          </Grid>

          <Grid item mt={2} md={12} xs={12}>
            {paymentHistory ? (
              paymentHistory.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table sx={{minWidth: {md: 650, xs: 0}}}>
                    <TableHead
                      sx={{
                        '& .MuiTableCell-head': {
                          fontSize: '1rem',
                          fontWeight: '600',
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell sx={{flex: 1}}>Date</TableCell>

                        <TableCell sx={{flex: 2}} align="center">
                          Installment
                        </TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Amount Due</TableCell>

                        <TableCell align="right">Status</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody
                      sx={{
                        '& .MuiTableCell-root': {
                          fontSize: '1rem',
                          fontWeight: '500',
                        },
                      }}
                    >
                      {paymentHistory.reverse().map(payment => (
                        <TableRow
                          key={payment.ID}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                          <TableCell component="th" scope="row">
                            {DateTime.fromISO(payment.created_at).toFormat(
                              'dd MMM yyyy'
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {stringNullChecker(
                              paymentInstallmentsData.find(
                                installment =>
                                  installment.payment_installment_ID ===
                                  payment.payment_installment_ID
                              )?.name
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {' '}
                            {props.currencyFormatter &&
                              props.currencyFormatter(
                                numericNullChecker(payment.amount)
                              )}
                          </TableCell>
                          <TableCell align="right">
                            {' '}
                            {props.currencyFormatter &&
                              props.currencyFormatter(
                                numericNullChecker(payment.amount_due)
                              )}
                          </TableCell>
                          <TableCell align="right">
                            {
                              <Chip
                                label={mapPaymentState(payment.status).label}
                                color={mapPaymentState(payment.status).color}
                              />
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {paymentHistory.length === 0 && (
                    <Grid item xs={12}>
                      {' '}
                      <Paper
                        sx={{
                          height: 200,
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 0,
                        }}
                      >
                        {' '}
                        <Typography variant="h3">
                          No Transactions
                        </Typography>{' '}
                      </Paper>
                    </Grid>
                  )}
                </TableContainer>
              ) : (
                <CustomNoRowsOverlay
                  text={'No payments have been made so far'}
                />
              )
            ) : (
              <Card style={{width: '100%', padding: 20}}>
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'150px'}
                  animation="wave"
                />
              </Card>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems={'center'}
          sx={{mt: 2}}
        >
          <Button
            color="secondary"
            sx={{textDecoration: 'underline', textTransform: 'none'}}
            onClick={() =>
              redirectToPage(history, 'terms', '/terms', props.setAppBarPage)
            }
          >
            {'Review Terms & Conditions'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

const mapStateToProps = state => {
  const {
    userAuthToken,
    userProfileData,
    currentStep,
    notifications,
    steps,
    currencyFormatter,
  } = state.app;

  return {
    userAuthToken,
    userProfileData,
    currentStep,
    notifications,
    steps,
    currencyFormatter,
  };
};

const mapDispatchToProps = dispatch => ({
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
