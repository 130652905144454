import {Grid, Typography} from '@mui/material';
import React from 'react';

import PowerGazeboImage from '../assets/images/powergazebo_render.webp';

const AboutUs = () => {
  return (
    <div>
      <Grid
        container
        sx={{
          width: 1,
          flexDirection: {xs: 'column-reverse', sm: 'initial', md: 'initial'},
        }}
      >
        {/* Text container */}
        <Grid item sm={12} md={6} sx={{p: 2}}>
          <Typography variant="h1" color="primary">
            About Us
          </Typography>
          <Typography variant="h3" color="primary" sx={{mt: 1}}>
            {
              'Arka was founded in 2020 with the express intent of creating sustainable, long-lasting, and spectacular renewable energy solutions for everyone.'
            }
          </Typography>
          <Typography variant="h3" color="primary" sx={{mt: 1}}>
            {
              'With a focus on solar energy offerings for residences and residential projects, Arka ensures that the future of distributed energy is in the hands of its users.'
            }
          </Typography>{' '}
        </Grid>
        {/* image container */}
        <Grid item sm={12} md={6}>
          <img
            src={PowerGazeboImage}
            alt="nothing"
            style={{height: '100%', width: '100%'}}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutUs;
