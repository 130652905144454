import React from 'react';
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import {makeStyles} from '@mui/styles';
import {Grid} from '@mui/material';
const useStyle = makeStyles(theme => ({
  mapStyles: {
    width: '100%',
    height: '100%',
  },
  mapContainer: {
    position: 'absolute',
    height: '600px',
    width: '100%',
    background: 'white',
  },
}));

const MapContainer = props => {
  const mapStyles = {
    width: '100%',
    height: '100%',
  };
  const classes = useStyle();

  const handleMapClick = (mapProps, map, clickEvent) => {
    console.log('clicked');
    if (!props.disabled) {
      props.updateAddressFromMap({
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng(),
      });
      if (props && props.setValidGmapsAddress) props.setValidGmapsAddress(true);
    }
  };
  return (
    <Map
      mapType="satellite"
      google={props.google}
      zoom={17}
      style={mapStyles}
      initialCenter={props.addressLatLng}
      center={props.addressLatLng}
      onClick={handleMapClick}
      onZoomChanged={(mapProps, map) => map.setTilt(0)}
    >
      <Marker position={props.addressLatLng} />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_KEY,
})(MapContainer);
