import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Typography,
} from '@mui/material';
import PrivacyPolicy from '../assets/documents/PrivacyPolicy.docx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileViewer from 'react-file-viewer';
const Policies = () => {
  const onError = e => {
    console.log(e, 'error in file-viewer');
  };
  return (
    <Grid container sx={{width: 1, color: 'black', mt: 2,px:3}}>
      <Accordion style={{width: '100%', background: '#c3c3c3'}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
          <Typography variant="h2">{'PRIVACY POLICY'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FileViewer
            fileType={'docx'}
            filePath={PrivacyPolicy}
            onError={onError}
          />
        </AccordionDetails>
      </Accordion>
      {/* Cancellation & Refund Policy */}
      <Accordion style={{width: '100%', background: '#c3c3c3'}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
          <Typography variant="h2">
            {'CANCELLATION / REFUND POLICY '}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h3" sx={{my: 2}}>
            {
              'The details provided by you shall be utilized only for the purpose of receiving the payments to be made by you to the institution. All data shall be kept secure and shall not be divulged to anyone or utilized for any other purpose.'
            }
          </Typography>
          <Typography variant="h3" sx={{my: 2}}>
            {
              'There is no cancellation option for the end users after payment is made. '
            }
          </Typography>
          <Typography variant="h3" sx={{my: 2}}>
            {
              'In case of duplicate payment, parent/student, kindly approach admin or finance or accounts department for refund with proof of the transaction reference or your bank statement. '
            }
          </Typography>
          <Typography variant="h3" sx={{my: 2}}>
            {
              ' Refund will be processed within 10-15 working days, respective payment gateway will send back to the issuing bank [user’s card banker] in batches for processing, which should approximately take 8-15 working days, depending on issuing banks policies. '
            }
          </Typography>
          <Typography variant="h3" sx={{my: 2}}>
            <b>{'Important:'}</b>
            {
              ' by submitting a payment through the online-payments site you are agreeing to these terms and conditions including any updated changes in terms and conditions from time to time through our website. '
            }
          </Typography>
          <Typography variant="h3" sx={{my: 2}}>
            <b>{'Disclaimer:'}</b>
            {
              ' The articles, information and documents provided on this website are purely for information purpose and no legal commitment whatsoever are attached to the same in case of any inadvertent error that might have occurred due to unavoidable circumstances despite all the efforts put by the website management team. '
            }
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Cancellation & Refund Policy */}
      <Accordion style={{width: '100%', background: '#c3c3c3'}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
          <Typography variant="h2">{'SHIPPING POLICY'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h3" sx={{my: 2}}>
            {
              'The materials for your Arka system will be shipped to your selected location within 2 days of paying the second instalment on it. The delivery of the shipment can take 5\u201310 days, depending on your location.'
            }
          </Typography>
          <Typography variant="h3" sx={{my: 2}}>
            <b>
              <u>Do not open the materials shipped to you</u>
            </b>
            {', our installation partner will handle this process.'}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Data deletion policy */}
      <Accordion style={{width: '100%', background: '#c3c3c3'}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
          <Typography variant="h2">{'DATA DELETION'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h3" sx={{my: 2}}>
            {
              'Reach out to us at support@arkaenergy.com and request for your data deletion.'
            }
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default Policies;
