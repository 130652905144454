import React from 'react';
import {Box, Grid, Typography, Card, CardContent, Button} from '@mui/material';

const sampleData = {
  product: 'PowerRoof',
  date: new Date('11 october 2021'),
};

function DeliveryTracking(props) {
  return (
    <Grid container flexDirection={'column'}>
      <Grid item>
        {' '}
        <Typography variant="h1" color='primary' mb={4}>
          Delivery Tracking
        </Typography>
        <Typography mb={2} color='primary'>
          Your {sampleData.product} is on its way to you!
        </Typography>
        <Typography color='primary'>
          Expect the delivery by {sampleData.date.toDateString()}
        </Typography>
      </Grid>

      <Grid item my={4}>
        <Card sx={{maxWidth: 300, margin: 'auto'}}>
          <CardContent>
            <Grid container justifyContent={'center'}>
              <Grid item>
                <Typography gutterBottom color='primary'>
                  Our partner is handling this delivery.
                </Typography>
                <Typography gutterBottom color='primary'>
                  You can track the delivery here.
                </Typography>
              </Grid>
              <Grid item my={2}>
                {' '}
                <Button
                  sx={{
                    borderWidth: 3,
                    borderStyle: 'solid',
                    borderColor: 'secondary.main',
                    fontWeight: 700,
                    textTransform: 'none',
                  }}
                >
                  {' '}
                  Track Delivery
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item>
        <Typography
          variant="h2"
          sx={{borderBottom: '2px solid black', paddingBottom: 1.5, mb: 3}}
          color='primary'
        >
          What's next?
        </Typography>

        <Typography mb={1} color='primary'>
          Once the Solution is delivered, your installation will begin.
        </Typography>

        <Typography color='primary'>
          Based on current estimates, your {sampleData.product} should be
          installed by{' '}
          {new Date(
            sampleData.date.getTime() + 24 * 60 * 60 * 1000 * 11
          ).toDateString()}
          .
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DeliveryTracking;
