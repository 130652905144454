import * as reduxActions from '../../../constants/reduxActions'
const currencySymbolMapping = { 'INR': '₹', 'USD': '$' }
const initialState = {
  userAuthToken: localStorage.getItem('spotlight-user-token'),
  darkMode: true,
  msalToken: null,
  notifications: [],
  steps: [],
  currentStep: {},
  completeProfileNotification: false,
  userProfileData: null,
  oid: null,
  signUpCompleted: false,
  solarProducts: null,
  dashboardPage: 'Loading',
  appBarPage: 'dashboard',
  performanceGraphMode: null,
  npsTemplate: null,
  selectedSystemId: null,
  systemAggregateData: null,
  systemDetail: null,
  currencyFormatter: null,
  numericFormatter: null,
  currencySymbol: currencySymbolMapping[process.env.REACT_APP_CURRENCY]
}

//function to update the state in the redux store
export function app(state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  switch (action.type) {
    case reduxActions.resetRedux:
      return initialState
    case reduxActions.toggleDarkMode:
      return {
        ...state,
        darkMode: state.darkMode ? false : true
      }
    case reduxActions.storeNotifications:
      return {
        ...state,
        notifications: action.data.notifications
      }
    case reduxActions.storeMsalToken:
      return {
        ...state,
        msalToken: action.data.msalToken
      }
    case reduxActions.updateCompleteProfileNotification:
      return {
        ...state,
        completeProfileNotification: action.data.flagValue
      }
    case reduxActions.setUserProfileData:
      return {
        ...state,
        userProfileData: action.data.userProfileData
      }
    case reduxActions.storeSteps:
      return {
        ...state,
        steps: action.data.steps
      }
    case reduxActions.setCurrentStep:
      return {
        ...state,
        currentStep: action.data.currentStep
      }
    case reduxActions.setCustomerOid:
      return {
        ...state,
        oid: action.data.oid
      }
    case reduxActions.setSignUpCompletedFlag:
      return {
        ...state,
        signUpCompleted: true
      }
    case reduxActions.setSolarProducts:
      return {
        ...state,
        solarProducts: action.data.solarProducts
      }
    case reduxActions.setDashboardPage:
      return {
        ...state,
        dashboardPage: action.data.dashboardPage
      }
    case reduxActions.setUserAuthToken:
      return {
        ...state,
        userAuthToken: action.data.userAuthToken
      }
    case reduxActions.setAppBarPage:
      return {
        ...state,
        appBarPage: action.data.appBarPage
      }
    case reduxActions.setPerformanceGraphMode:
      return {
        ...state,
        performanceGraphMode: action.data.performanceGraphMode
      }
    case reduxActions.setSelectedSystemId:
      return {
        ...state,
        selectedSystemId: action.data.selectedSystemId
      }
    case reduxActions.setSystemAggregateData:
      return {
        ...state,
        systemAggregateData: action.data.systemAggregateData
      }
    case reduxActions.setSystemDetail:
      return {
        ...state,
        systemDetail: action.data.systemDetail
      }
    case reduxActions.setNpsTemplate:
      return {
        ...state,
        npsTemplate: action.data.npsTemplate
      }
    case reduxActions.setCurrencyFormatter:
      return {
        ...state,
        currencyFormatter: action.data.currencyFormatter
      }
    case reduxActions.setNumericFormatter:
      return {
        ...state,
        numericFormatter: action.data.numericFormatter
      }
    default:
      return state
  }
}