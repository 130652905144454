import {
  Typography,
  Grid,
  Divider,
  Button,
  IconButton,
  Card,
} from '@mui/material';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {DateTime} from 'luxon';
import CloseIcon from '@mui/icons-material/Close';
import {
  dismissNotification as dismissNotificationRequest,
  getNotifications as getNotificationsRequest,
  getNPS as getNpsRequest,
} from '../util/ApiCalls/service';
import {
  storeNotifications as storeNotificationsAction,
  setAppBarPage as setAppBarPageAction,
  setNpsTemplate as setNpsTemplateAction,
} from '../constants/reduxActions';
import ProgressDialog from './ProgressDialog';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import WhiteButton from './WhiteButton';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import {maxHeaderSize} from 'http';
const completeProfileNotificationItem = {
  subject: 'Incomplete Profile',
  description: 'Please complete your profile details',
};
const NotificationItem = props => {
  const history = useHistory();
  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  let createdAt = DateTime.fromISO(props.item.created_at);

  const dismissNotification = async item => {
    setOpenProgressDialog(true);
    try {
      const responseData = await dismissNotificationRequest({
        notificationId: item.id,
      });
      if (responseData.success) {
        const getNotifications = await getNotificationsRequest();
        props.storeNotifications(getNotifications.message);
        setOpenProgressDialog(false);
      }
    } catch (err) {
      console.log(
        'Error while trying to dismiss notification with id ' + item.id,
        err
      );
      setOpenProgressDialog(false);
    }
  };

  const handleOpenNpsDialog = () => {
    setOpenProgressDialog(true);
    let npsId = props.item.description.split('.')[1];
    if (npsId) {
      getNpsRequest(npsId)
        .then(resp => {
          if (!resp.message.data.is_submitted) {
            props.setNpsTemplate(resp.message);
            props.switchNotificationDrawerState();
            setOpenProgressDialog(false);
          }
        })

        .catch(err => {
          console.log(err);
        });
    }
  };

  if (props.viewAll) {
    return (
      <React.Fragment>
        <ProgressDialog
          open={openProgressDialog}
          setOpenDialog={setOpenProgressDialog}
        />
        <Grid
          item
          container
          xs={12}
          sx={{
            padding: '10px',
            border: 'solid 1px ',
            borderColor: props.viewAll ? 'black' : 'white',
            margin: 1,
            borderRadius: 2,
          }}
        >
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item xs={11}>
              <Typography
                variant="h3"
                style={{fontWeight: 600}}
                color={props.item.dismissed ? '#afafaf' : 'primary'}
              >
                {props.item.subject}
              </Typography>
              <Typography
                variant="h4"
                sx={{fontWeight: 100, lineHeight: 1.4, my: 1}}
                color={props.item.dismissed ? '#afafaf' : 'primary'}
              >
                {props.item.description}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {!props.item.dismissed && (
                <IconButton onClick={() => dismissNotification(props.item)}>
                  <CloseIcon color="primary" />
                </IconButton>
              )}
            </Grid>
          </Grid>
          {props.item.subject === completeProfileNotificationItem.subject &&
            props.setAppBarPage && (
              <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  sx={{mt: 1}}
                  onClick={() => {
                    if (props.switchNotificationDrawerState) {
                      props.switchNotificationDrawerState();
                    }
                    redirectToPage(
                      history,
                      'profile',
                      '/profile',
                      props.setAppBarPage
                    );
                  }}
                >
                  Complete Profile
                </Button>
              </Grid>
            )}
          {props.item.subject !== completeProfileNotificationItem.subject && (
            <Grid
              item
              container
              justifyContent="space-between"
              xs={12}
              sx={{mt: 1}}
            >
              <Typography
                variant="h4"
                color={props.item.dismissed ? '#afafaf' : 'primary'}
                style={{fontWeight: 100, color: 'grey'}}
              >
                {createdAt.toFormat('dd MMMM yyyy, hh:mm a')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  } else {
    if (props.item.notification_type === 'NPS') {
      return (
        <React.Fragment>
          <ProgressDialog
            open={openProgressDialog}
            setOpenDialog={setOpenProgressDialog}
          />
          <Card
            sx={{
              padding: '10px',
              border: 'solid 1px ',
              //   background: '#00D959',
              margin: 1,
              width: '100%',
              maxHeight: '20vh ',
            }}
          >
            <Grid item container xs={12}>
              <Grid item container justifyContent="space-between" xs={12}>
                <Grid item xs={11}>
                  <Typography variant="h3" style={{fontWeight: 600}}>
                    {props.item.subject}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{fontWeight: 100, lineHeight: 1.4, my: 1}}
                  >
                    {props.item.description.split('.')[0] + '.'}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <BookmarkIcon />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
              >
                {props.item.subject ===
                  completeProfileNotificationItem.subject && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    sx={{mt: 1}}
                    onClick={() => {
                      if (props.switchNotificationDrawerState) {
                        props.switchNotificationDrawerState();
                      }
                      redirectToPage(
                        history,
                        'profile',
                        '/profile',
                        props.setAppBarPage
                      );
                    }}
                  >
                    Complete Profile
                  </Button>
                )}
              </Grid>
              {props.item.subject !==
                completeProfileNotificationItem.subject && (
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  xs={12}
                  sx={{mt: 1}}
                >
                  <Typography
                    variant="h4"
                    style={{fontWeight: 100, color: 'grey'}}
                  >
                    {createdAt.toFormat('dd MMMM yyyy, hh:mm a')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenNpsDialog}
                  >
                    Rate Us
                  </Button>
                </Grid>
              )}
            </Grid>
          </Card>
        </React.Fragment>
      );
    } else if (!props.item.dismissed) {
      return (
        <React.Fragment>
          <ProgressDialog
            open={openProgressDialog}
            setOpenDialog={setOpenProgressDialog}
          />
          <Card
            sx={{
              padding: '10px',
              border: 'solid 1px ',
              margin: 1,
              width: '100%',
              maxHeight: 'fit-content',
            }}
          >
            <Grid item container xs={12}>
              <Grid item container justifyContent="space-between" xs={12}>
                <Grid item xs={11}>
                  <Typography variant="h3" style={{fontWeight: 600}}>
                    {props.item.subject}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{fontWeight: 100, lineHeight: 1.4, my: 1}}
                  >
                    {props.item.description}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => dismissNotification(props.item)}>
                    <CloseIcon color="primary" />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
              >
                {props.item.subject ===
                  completeProfileNotificationItem.subject && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    sx={{mt: 1}}
                    onClick={() => {
                      if (props.switchNotificationDrawerState) {
                        props.switchNotificationDrawerState();
                      }
                      redirectToPage(
                        history,
                        'profile',
                        '/profile',
                        props.setAppBarPage
                      );
                    }}
                  >
                    Complete Profile
                  </Button>
                )}
              </Grid>
              {props.item.subject !==
                completeProfileNotificationItem.subject && (
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  xs={12}
                  sx={{mt: 1}}
                >
                  <Typography
                    variant="h4"
                    style={{fontWeight: 100, color: 'grey'}}
                  >
                    {createdAt.toFormat('dd MMMM yyyy, hh:mm a')}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Card>
        </React.Fragment>
      );
    } else return null;
  }
};

const NotificationsList = props => {
  return (
    <Grid container sx={{overflow: 'scroll', height: 1}}>
      {props.notifications &&
        props.notifications.map((item, index) => {
          return (
            <NotificationItem
              key={index}
              item={item}
              storeNotifications={props.storeNotifications}
              viewAll={props.viewAll}
              setAppBarPage={props.setAppBarPage}
              setNpsTemplate={props.setNpsTemplate}
              switchNotificationDrawerState={
                props.switchNotificationDrawerState
              }
            />
          );
        })}
      {props.completeProfileNotification && (
        <NotificationItem
          item={completeProfileNotificationItem}
          setAppBarPage={props.setAppBarPage}
          switchNotificationDrawerState={props.switchNotificationDrawerState}
          setNpsTemplate={props.setNpsTemplate}
        />
      )}
      {!props.viewAll &&
        props.notifications.filter(item => item.dismissed === false).length ===
          0 && (
          <Typography variant="h3">You have no new notifications</Typography>
        )}
    </Grid>
  );
};

const mapStateToProps = state => {
  const {notifications, completeProfileNotification, appBarPage} = state.app;
  return {notifications, completeProfileNotification, appBarPage};
};
const mapDispatchToProps = dispatch => ({
  storeNotifications: notifications => {
    dispatch({type: storeNotificationsAction, data: {notifications}});
  },
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
  setNpsTemplate: npsTemplate => {
    dispatch({
      type: setNpsTemplateAction,
      data: {npsTemplate},
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
