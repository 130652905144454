import {logout} from '../LogoutUser/logout';

let spotlightBackendUrl;
if (process.env.REACT_APP_MODE == 'PROD') {
  spotlightBackendUrl = window.location.origin;
} else {
  spotlightBackendUrl = 'http://localhost:5001';
}

const functionAppUrl = process.env.REACT_APP_FUNCTIONS_APP_URL;
const authBackendUrl = process.env.REACT_APP_AUTHENTICATION_BACK_END;
const pmportalBackendUrl = process.env.REACT_APP_PMPORTAL_URL;

const integrationBackendUrl = process.env.REACT_APP_INTEGRATION_SERVICE_URL;
const appId = process.env.REACT_APP_APP_ID;
const orgId = process.env.REACT_APP_ORG_ID;

const authHeaderFromToken = () => {
  let token = localStorage.getItem('spotlight-user-token');

  if (token) {
    return {
      'Content-Type': 'application/json; charset=utf-8',
      authorization: token,
    };
  } else {
    return {};
  }
};

const authHeaderFromBody = token => {
  if (token) {
    return {
      'Content-Type': 'application/json; charset=utf-8',
      authorization: token,
    };
  } else {
    return {};
  }
};

const authHeader = token => {
  if (!token) {
    return authHeaderFromToken();
  } else {
    return authHeaderFromBody(token);
  }
};

const commonRequestOptions = {
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'omit',
};

function handleResponse(response) {
  return response.json().then(json => {
    if (
      response.status !== 200 &&
      response.status !== 201 &&
      response.status !== 202
    ) {
      if (response.status === 409) {
        const error =
          (json && json.error) ||
          (json && json.message) ||
          'Something went wrong';
        return Promise.reject(error);
      }
      if (response.status === 403) {
        if (process.env.REACT_APP_MODE === 'PROD') logout();
      }
      const error = (json && json.message) || 'Something went wrong';
      return Promise.reject(error);
    } else {
      return json;
    }
  });
}

const addOrgAppIdToBody = body => {
  return {
    ...body,
    appId,
    orgId,
  };
};
// NPS
// const getnps = npsId => {
//   const requestOptions = {
//     ...setHeaders(RequestTypes.GET),
//   };

//   return fetch(`${management_url}/api/nps/${npsId}`, requestOptions).then(
//     handleResponse
//   );
// };

//Authentication Backend Calls

export const login = body => {
  const updatedBody = addOrgAppIdToBody(body);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(updatedBody),
  };

  return fetch(`${authBackendUrl}/api/auth/signin`, requestOptions).then(
    response => response.json()
  );
};

export const signUp = body => {
  const updatedBody = addOrgAppIdToBody(body);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(updatedBody),
  };

  return fetch(`${authBackendUrl}/api/auth/signup`, requestOptions).then(
    handleResponse
  );
};

export const socialSignInUp = body => {
  const updatedBody = addOrgAppIdToBody(body);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(updatedBody),
  };

  return fetch(`${authBackendUrl}/api/auth/signin/social`, requestOptions).then(
    handleResponse
  );
};

export const sendForgotPasswordVerificationCode = body => {
  const updatedBody = addOrgAppIdToBody(body);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(updatedBody),
  };

  return fetch(`${authBackendUrl}/api/auth/sendCode`, requestOptions).then(
    handleResponse
  );
};

export const verifyOtpSetNewPassword = body => {
  const updatedBody = addOrgAppIdToBody(body);
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(updatedBody),
  };

  return fetch(
    `${authBackendUrl}/api/auth/updatePassword`,
    requestOptions
  ).then(handleResponse);
};

//Integration Backend Calls

export const getOtpSignUpEmailValidation = body => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(`${authBackendUrl}/api/auth/sendOTP`, requestOptions).then(
    handleResponse
  );
};
export const getOtpSignUpMobileValidation = body => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(`${authBackendUrl}/api/auth/sendSMS`, requestOptions).then(
    handleResponse
  );
};

export const getOtpSignInMobileValidation = body => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${authBackendUrl}/api/auth/sendOTPForMobileLogin`,
    requestOptions
  ).then(handleResponse);
};
export const verifyOtpSignInMobileValidation = body => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${authBackendUrl}/api/auth/signInWithPhoneNumberOTP`,
    requestOptions
  ).then(handleResponse);
};

export const verifyOtpSignUpEmailValidation = body => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${authBackendUrl}/api/auth/verifyOTP`,
    requestOptions
  ).then(handleResponse);
};

export const verifyOtpSignUpMobileValidation = body => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(`${authBackendUrl}/api/auth/verifyOTP`, requestOptions).then(
    handleResponse
  );
};

//Spotlight Backend Calls


//calls where token is sent from body - BT (Body Token)
export const getUserProfileBT = token => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(token),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/profile`,
    requestOptions
  ).then(handleResponse);
};

export const updateUserProfileBT = (body, token) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(token),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/profile`,
    requestOptions
  ).then(handleResponse);
};

//calls where token is read from localstorage
export const getSystemAggregateData = system_ID => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/data/aggregate${
      system_ID ? `/${system_ID}` : ''
    }`,
    requestOptions
  ).then(handleResponse);
};

export const getAllSteps = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(`${spotlightBackendUrl}/api/steps`, requestOptions).then(
    handleResponse
  );
};

export const getNPS = npsId => {
  // const updatedBody = {
  //   userId: '129000uuuuffddffdd9345',
  //   orgId: process.env.REACT_APP_ORG_ID,
  // };

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
    //body: JSON.stringify(updatedBody),
  };

  return fetch(`${spotlightBackendUrl}/api/nps/${npsId}`, requestOptions).then(
    handleResponse
  );
};

export const submitNPS = data => {
  const updatedBody = data;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(updatedBody),
  };

  return fetch(`${spotlightBackendUrl}/api/nps/submit`, requestOptions).then(
    handleResponse
  );
};

export const getAllProducts = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/product/solarProduct/v2`,
    requestOptions
  ).then(handleResponse);
};

export const getAllProductsUnauthenticated = (orgId) => {
  const requestOptions = {
    method: 'GET',
    headers:{
      ...commonRequestOptions,
      mode: 'no-cors'
    }
  };

  return fetch(
    `${spotlightBackendUrl}/signUp/products/${orgId}`,
    requestOptions
  ).then(handleResponse);
};

export const getUserProfile = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/profile`,
    requestOptions
  ).then(handleResponse);
};

export const getCurrentStep = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(`${spotlightBackendUrl}/api/steps/current`, requestOptions).then(
    handleResponse
  );
};

export const getNotifications = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/notification`,
    requestOptions
  ).then(handleResponse);
};

export const dismissNotification = body => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/dismissedNotification`,
    requestOptions
  ).then(handleResponse);
};

export const generateReceipt = body => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${spotlightBackendUrl}/api/payment/generateReceipt`,
    requestOptions
  ).then(handleResponse);
};

export const generateInvoice = body => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${spotlightBackendUrl}/api/orders/generateInvoice`,
    requestOptions
  ).then(handleResponse);
};

export const initializePayment = body => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(`${spotlightBackendUrl}/api/payment/init`, requestOptions).then(
    handleResponse
  );
};

export const bookSiteSurvey = body => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/bookSiteSurvey`,
    requestOptions
  ).then(handleResponse);
};

export const uploadDocument = formData => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'form-data',
    },
    body: formData,
  };

  return fetch(
    `${spotlightBackendUrl}/api/documents/fileUpload?documentID=DOC000001`,
    requestOptions
  ).then(handleResponse);
};

export const updateUserProfile = body => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/profile`,
    requestOptions
  ).then(handleResponse);
};

export const getAllUserDocuments = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(`${spotlightBackendUrl}/api/documents`, requestOptions).then(
    handleResponse
  );
};

export const getInstallationSteps = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/installationSteps`,
    requestOptions
  ).then(handleResponse);
};

export const getCurrentInstallationStep = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/installationSteps/current`,
    requestOptions
  ).then(handleResponse);
};

export const getSiteSurveyDetails = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/siteSurvey/siteSurveyDetails`,
    requestOptions
  ).then(handleResponse);
};

export const getOrderDetails = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/orders/orderDetails`,
    requestOptions
  ).then(handleResponse);
};

export const getPaymentInstallments = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/payment/paymentInstallments`,
    requestOptions
  ).then(handleResponse);
};

export const getReceipt = (installmentId, individualId) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/payment/${installmentId}/${individualId}`,
    requestOptions
  ).then(handleResponse);
};

export const getPriceQuote = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/quotes/priceQuote`,
    requestOptions
  ).then(handleResponse);
};

export const getProposalDataWithHistory = (history) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/quotes/proposal?history=${history}`,
    requestOptions
  ).then(handleResponse);
};

export const getCustomerSupportRequestDetails = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/support/customerSupportRequestDetails`,
    requestOptions
  ).then(handleResponse);
};

export const createCutomerSupportRequest = body => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${spotlightBackendUrl}/api/support/createCustomerSupportRequest`,
    requestOptions
  ).then(handleResponse);
};

export const createOrder = body => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${spotlightBackendUrl}/api/orders/createOrder`,
    requestOptions
  ).then(handleResponse);
};

export const getDesignLink = body => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/quotes/designReportLink`,
    requestOptions
  ).then(handleResponse);
};

export const getDeviceStatus = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/devices/status`,
    requestOptions
  ).then(handleResponse);
};

export const rejectQuote = priceQuoteId => {
  const requestOptions = {
    method: 'PATCH',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify({priceQuoteId}),
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/quotes/rejectQuote`,
    requestOptions
  ).then(handleResponse);
};

export const sendContract = body => {
  const requestOptions = {
    method: 'POST',

    body: JSON.stringify(body),

    headers: {...authHeader(), 'Content-Type': 'application/json'},
    ...commonRequestOptions,
  };

  return fetch(
    `${pmportalBackendUrl}/api/docuSign/template`,
    requestOptions
  ).then(handleResponse);
};

export const createLead = body => {
  const requestOptions = {
    method: 'POST',

    body: JSON.stringify(body),

    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    ...commonRequestOptions,
  };

  return fetch(`${spotlightBackendUrl}/api/leads`, requestOptions).then(
    handleResponse
  );
};

//SAS Document Services

export const getFileSas = fileName => {
  return fetch(`${functionAppUrl}/api/sasGenerator?name=${fileName}`).then(
    resp => resp.text()
  );
  // .then(resp => window.location.assign(resp))
  // .catch(err => console.log(err));
};

//GateWay System Details
export const getSysytemDetailsData = token => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(token),
    ...commonRequestOptions,
  };

  return fetch(
    `${spotlightBackendUrl}/api/system/systemDetail`,
    requestOptions
  ).then(handleResponse);


  
};


export const upsertUserInterest = body => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    ...commonRequestOptions,
    body: JSON.stringify(body),
  };

  return fetch(
    `${spotlightBackendUrl}/api/customer/interest`,
    requestOptions
  ).then(handleResponse);
};

export const resetPasswordInProfilePage = body => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    ...commonRequestOptions,
    
    body: JSON.stringify(body),
  };

  return fetch(`${spotlightBackendUrl}/api/customer/updatePassword`, requestOptions).then(
    handleResponse
  );
};
