import React from 'react';
import {
  Grid,
  Typography,
  Card,
  Divider,
  Box,
  Button,
  Alert,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {DateTime} from 'luxon';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useHistory} from 'react-router-dom';
import redirectToPage from '../util/UtilFunctions/redirectToPage';
import {connect, useDispatch} from 'react-redux';
import {setAppBarPage as setAppBarPageAction} from '../constants/reduxActions';
import {numericNullChecker} from '../util/UtilFunctions/nullCheckers';

const useStyle = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  cardRoot: {
    width: '100%',
    padding: 10,
    margin: '10px 0px',
  },
  greenText: {
    color: '#37aa23',
  },
  greyText: {
    color: 'grey',
  },
  secondaryColorDivider: {
    borderColor: theme.palette.secondary.main + ' !important',
  },
}));

const columnMapping = [
  // {columnName: 'quote_line_item_ID', displayName: 'ID'},
  {columnName: 'name', displayName: 'Name'},
  {columnName: 'system_size', displayName: 'System Size (kWp)'},
  {
    columnName: 'annual_energy_production',
    displayName: 'Annual Energy Production (kWh)',
  },
  {
    columnName: 'estimated_annual_savings',
    displayName: 'Estimated Annual Savings',
  },
  {columnName: 'coverage', displayName: 'Energy Offset %'},
  {columnName: 'amount', displayName: 'Amount'},
  // {columnName: 'description', displayName: 'Description'},
  // {columnName: 'tax', displayName: 'Tax %'},
  // {columnName: 'discount', displayName: 'Discount %'},
];

// {

//   "ID": 261,
//   "quote_line_item_ID": "QLI000261",
//   "amount": 647000,
//   "system_size": 3.46,
//   "annual_energy_production": 5300,
//   "coverage": 0.13,
//   "estimated_annual_savings": 42000,
//   "product_variant_ID": "2PRCT00000143",
//   "product_ID": "2PRDT00000142",
//   "SKU_ID": "2SKU00000177",
//   "individual_ID": "18abb648-5ca8-44ed-81a8-4967ae4c7b8a",
//   "created_at": "2022-11-30T11:39:00.607Z",
//   "design_ID": null,
//   "tax": 0.13,
//   "discount": 0.13,
//   "Lead_ID": "LD000659",
//   "project_ID": "PRID000252",
//   "quote_ID": "prcqt000247",
//   "name": "Gazebo",
//   "description": "Gazebo"
// }

const OrderCard = props => {
  const classes = useStyle();

  const {displayName, orderData, quoteData} = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOpenSurvey = async () => {
    if (displayName === 'Survey') {
      try {
        window.open(
          `${process.env.REACT_APP_TSL_SURVEY_URL}/sitesurvey/${props.surveyID}/tsl`
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      redirectToPage(history, 'paymentHistory', '/paymentHistory', () =>
        dispatch({
          type: setAppBarPageAction,
          data: {appBarPage: 'paymentHistory'},
        })
      );
    }
  };

  return (
    <Card className={classes.cardRoot}>
      <Grid
        container
        style={{width: '100%', padding: '15px 20px'}}
        justifyContent={'space-between'}
      >
        <Grid item sm={12} md={7}>
          <Typography variant="h2">
            {displayName === 'Survey' ? 'Site Survey' : orderData.subject}
          </Typography>
          <Grid item container xs={12} justifyContent="space-between">
            <Typography variant="h3" style={{paddingTop: 10}}>
              {displayName === 'Survey' ? 'Survey ID #' : 'Order ID #'}{' '}
              {orderData.id}
            </Typography>
            <Alert severity="success">{orderData.status}</Alert>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography variant="h3" sx={{fontWeight: 700}}>
              {orderData.name}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Typography variant="h3">{orderData.address}</Typography>
          </Grid>
        </Grid>

        <Divider
          className={classes.secondaryColorDivider}
          sx={{
            display: {sm: 'none', md: 'initial '},
            borderRightWidth: 5,
            margin: '10px 0px 10px 0px',
          }}
        />
        <Divider
          className={classes.secondaryColorDivider}
          sx={{
            display: {sm: 'initial', md: 'none '},
            borderBottomWidth: 5,
            width: 1,
          }}
        />
        {/* Right Container */}
        <Grid
          item
          container
          direction={'column'}
          sm={12}
          md={4}
          style={{textAlign: 'center'}}
          justifyContent="space-between"
        >
          <Grid item container sx={{mt: 2}} justifyContent="space-between">
            <Typography variant="h4" className={classes.greyText}>
              Order Placed On :
            </Typography>
            <Typography variant="h4">
              {DateTime.fromISO(orderData.created_date).toFormat(
                'dd MMM yyyy, hh:mm a'
              )}
            </Typography>
          </Grid>
          {displayName === 'Survey' && (
            <Grid item container sx={{mt: 2}} justifyContent="space-between">
              <Typography variant="h4" className={classes.greyText}>
                Visit Period :
              </Typography>
              <Typography variant="h4" sx={{ml: 1}}>
                {DateTime.fromISO(orderData.from_date).toFormat('dd MMM yyyy') +
                  ' - ' +
                  DateTime.fromISO(orderData.to_date).toFormat('dd MMM yyyy')}
              </Typography>
            </Grid>
          )}
          <Grid item container sx={{mt: 2}} justifyContent="space-between">
            <Typography variant="h4" className={classes.greyText}>
              Order Total :
            </Typography>
            <Typography variant="h4">{orderData.total}</Typography>
          </Grid>

          <Grid item container sx={{mt: 2}} justifyContent="center">
            <Button
              color="secondary"
              variant="contained"
              disabled={!props.surveyID && displayName === 'Survey'}
              onClick={handleOpenSurvey}
            >
              {displayName === 'Survey' ? 'View Report' : 'Payments'}
            </Button>
          </Grid>
        </Grid>

        {/* Line Items table */}
        {quoteData  && (
          <Grid item container xs={12} sx={{mt: 2}}>
            <Accordion sx={{width: '100%'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                {'Order Details'}
              </AccordionSummary>
              <AccordionDetails
                sx={{overflowX: 'scroll', backgroundColor: '#F5F5F7'}}
              >
                {quoteData.lineItems.length > 0 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columnMapping.map((item, index) => (
                          <TableCell key={index}>
                            <b>{item.displayName}</b>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {quoteData.lineItems.map((lineItem, lineItemIndex) => {
                        return (
                          <TableRow key={lineItemIndex}>
                            {columnMapping.map((item, itemIndex) => {
                              if (
                                item.columnName === 'amount' ||
                                item.columnName === 'estimated_annual_savings'
                              ) {
                                return (
                                  <TableCell key={itemIndex}>
                                    {props.currencyFormatter(
                                      numericNullChecker(
                                        lineItem[item.columnName]
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                );
                              } else if (
                                item.columnName === 'coverage' ||
                                item.columnName === 'discount' ||
                                item.columnName === 'tax'
                              ) {
                                return (
                                  <TableCell key={itemIndex}>
                                    {numericNullChecker(
                                      lineItem[item.columnName] * 100
                                    ).toFixed(0) + '%'}
                                  </TableCell>
                                );
                              } else if (
                                item.columnName ===
                                  'annual_energy_production' ||
                                item.columnName === 'estimated_annual_savings'
                              ) {
                                return (
                                  <TableCell key={itemIndex}>
                                    {props.numericFormatter(
                                      numericNullChecker(
                                        lineItem[item.columnName]
                                      )
                                    )}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={itemIndex}>
                                    {lineItem[item.columnName]}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
const mapStateToProps = state => {
  const {currencyFormatter, numericFormatter} = state.app;

  return {
    currencyFormatter,
    numericFormatter,
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);
