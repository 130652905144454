import React from 'react';
import {makeStyles} from '@mui/styles';
import {Card, Divider, Grid, Typography} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
const useStyle = makeStyles(theme => ({
  root: {
    padding: 10,
  },
}));
const ContactUs = () => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Grid
        container
        style={{width: '100%'}}
        justifyContent={'space-around'}
        alignItems={'center'}
      >
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Typography variant="h1" color='primary'>Contact Us</Typography>
        </Grid>

        <Card sx={{width: 1, px: 2, py: 4}}>
          {process.env.REACT_APP_REACH_US_INFO_CONFIG === '1' ? (
            <Grid
              item
              container
              xs={12}
              justifyContent={'space-around'}
              alignItems={'stretch'}
            >
              <Grid item container xs={4}>
                <Grid item container xs={12} mt={2}>
                  <Grid item xs={3} mt={2}>
                    <LocationOnIcon style={{transform: 'scale(2)'}} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h2" mt={0}>
                      USA
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={1}>
                      Arka Energy Inc.
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={0}>
                      5064 Sloan Way
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={0}>
                      Union City
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={0}>
                      CA 94587
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} mt={5}>
                  <Grid item xs={3} mt={2}>
                    <PhoneIcon style={{transform: 'scale(2)'}} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h2" mt={0}>
                      Call
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={1}>
                      +1-650-521 7274
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} mt={5}>
                  <Grid item xs={3} mt={2}>
                    <MailIcon style={{transform: 'scale(2)'}} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h2" mt={0}>
                      Email
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={1}>
                      support@arkaenergy.com
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={1} mt={3}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{borderRightWidth: 5, borderColor: '#f4cd2a'}}
                />
              </Grid>

              <Grid item container xs={4}>
                <Grid item container xs={12} mt={2}>
                  <Grid item xs={3} mt={2}>
                    <LocationOnIcon style={{transform: 'scale(2)'}} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h2" mt={0}>
                      India
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={1}>
                      Arka Energy Inc.
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={0}>
                      # 230 A, 4th Floor, 18th Main road
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={0}>
                      Koramangala 6th Block
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={0}>
                      Bangalore 560095
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} mt={5}>
                  <Grid item xs={3} mt={2}>
                    <PhoneIcon style={{transform: 'scale(2)'}} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h2" mt={0}>
                      Call
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={1}>
                      +91-99000 81880
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} mt={5}>
                  <Grid item xs={3} mt={2}>
                    <MailIcon style={{transform: 'scale(2)'}} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h2" mt={0}>
                      Email
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 300}} mt={1}>
                      support@arkaenergy.com
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
            >
              <Grid item container xs={12}>
                <Grid item container xs={4} mt={2}>
                  <Grid item container xs={12}>
                    <Grid item xs={3} mt={2}>
                      <LocationOnIcon style={{transform: 'scale(2)'}} />
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="h2" mt={0}>
                        USA
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 300}} mt={1}>
                        Arka Energy Inc.
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 300}} mt={0}>
                        5064 Sloan Way
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 300}} mt={0}>
                        Union City
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 300}} mt={0}>
                        CA 94587
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={4} mt={2}>
                  <Grid item container xs={12}>
                    <Grid item xs={3} mt={2}>
                      <PhoneIcon style={{transform: 'scale(2)'}} />
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="h2" mt={0}>
                        Call
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 300}} mt={1}>
                        (415)-968-9014
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={4} mt={2}>
                  <Grid item container xs={12}>
                    <Grid item xs={3} mt={2}>
                      <MailIcon style={{transform: 'scale(2)'}} />
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="h2" mt={0}>
                        Email
                      </Typography>
                      <Typography variant="h4" sx={{fontWeight: 300}} mt={1}>
                        support@arkaenergy.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </div>
  );
};

export default ContactUs;
