import {Alert, Button} from '@mui/material';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {initializePayment as initializaPaymentRequest} from '../util/ApiCalls/service';
import {useSnackbar} from 'notistack';
import ProgressDialog from './ProgressDialog';

function loadScript(src) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const RazorpayPaymentGateway = props => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  const {payment_installment_ID, amount} = props;

  console.log(payment_installment_ID, amount, props.status);
  async function displayRazorpay() {
    enqueueSnackbar('Initializing payment gateway');
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    let roundOffAmount = parseFloat(amount.toFixed(2));

    initializaPaymentRequest({payment_installment_ID, amount: roundOffAmount})
      .then(data => {

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          currency: 'INR',

          amount: roundOffAmount,
          order_id: data.message.result.id,
          name: props.paymentName,
          description: 'Description of payment',
          // image: 'http://localhost:1337/logo.svg',
          handler: function (response) {
            setOpenProgressDialog(true);

            console.log({response});
            if (response.razorpay_payment_id && response.razorpay_order_id) {
              enqueueSnackbar('Payment Successful', {variant: 'success'});
              setTimeout(() => {
                props.getPaymentInstallmentsHandler();
                setOpenProgressDialog(false);
              }, 5000);
            } else {
              enqueueSnackbar('Payment Failed', {variant: 'error'});
            }
          },
          prefill: {
            name:
              data.message.customerInfo.first_name +
              ' ' +
              data.message.customerInfo.surname,
            email: data.message.customerInfo.email,
            phone_number: '9899999999',
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch(error => console.log('Error', error));
  }
  return (
    <div className="App">
      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />
      {props.status === 'captured' ? (
        <Alert severity="success">Paid</Alert>
      ) : (
        <Button
          color="secondary"
          variant="contained"
          onClick={displayRazorpay}
          disabled={props.disabled}
        >
          Pay now
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {userAuthToken} = state.app;
  return {
    userAuthToken,
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RazorpayPaymentGateway);
