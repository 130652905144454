import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import React, {useState} from 'react';
import ProgressDialog from '../../../components/ProgressDialog';
import {
  setAppBarPage as setAppBarPageAction,
  setUserAuthToken as setUserAuthTokenAction,
} from '../../../constants/reduxActions';
import {
  createLead as createLeadRequest,
  signUp as signUpRequest,
} from '../../../util/ApiCalls/service';
import AddressDetails from './AddressDetails';
import EnergyConsumptionDetails from './EnergyConsumptionDetails';
import PersonalDetails from './PersonalDetails';
import ProductPreview from './ProductPreview';
import {useSnackbar} from 'notistack';
import redirectToPage from '../../../util/UtilFunctions/redirectToPage';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';

// 1 - Personal Details
// 2 - Address Details
// 3 - Product Preview
// 4 - Energy Consumption Details

const SignUp = ({
  setCurrentPage,
  pageMapping,
  contactOptionMapping,
  setUserAuthToken,
  setAppBarPage,
}) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const history = useHistory();

  const [currentSignUpStep, setCurrentSignUpStep] = useState(1);
  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  const [signUpErrorDialog, setSignUpErrorDialog] = useState(false);
  const [signUpErrorMessage, setSignUpErrorMessage] = useState([]);
  const [signUpErrorHeader, setSignUpErrorHeader] = useState('');

  const [signUpData, setSignUpData] = useState({});

  const changeStep = forward => {
    if (forward) setCurrentSignUpStep(currentSignUpStep + 1);
    else setCurrentSignUpStep(currentSignUpStep - 1);
  };

  const createLeadOnSignUp = async leadBody => {
    try {
      const body = {leads: [leadBody]};
      await createLeadRequest(body);
    } catch (error) {
      console.log(error);
    }
  };

  const signUpHandler = async body => {
    try {
      setOpenProgressDialog(true);

      let response = await signUpRequest({
        users: [body],
      });

      localStorage.setItem('spotlight-user-token', response.message.token);

      const leadBody = {
        first_name: body.firstName,
        last_name: body.lastName,
        email: body.email,
        gmaps_address_string: body.gmaps_address_string,
        address: body.gmaps_address_string,
        phone_number: body.mobile,
        org_ID: process.env.REACT_APP_ORG_ID,
        latitude: body.latitude,
        longitude: body.longitude,
        user_ID: response.message.users[0].userId,
        roof_size: body.roof_size ?? 0,
        terrace_yard_size: body.terrace_yard_size ?? 0,
        energy_consumption: body.energy_consumption ?? 0,
        previous_month_electricity_bill:
          body.previous_month_electricity_bill ?? 0,
      };

      await createLeadOnSignUp(leadBody);

      enqueueSnackbar(response.metaData.toastMessage, {variant: 'success'});
      setOpenProgressDialog(false);
      redirectToPage(history, 'dashboard', '/dashboard', setAppBarPage);
      setUserAuthToken(response.message.token);
    } catch (err) {
      enqueueSnackbar('Sign Up Issue', {
        variant: 'error',
      });
      setOpenProgressDialog(false);

      setSignUpErrorDialog(true);

      setSignUpErrorHeader('Email Exists.  ');
      setSignUpErrorMessage(['Please sign in or use another email.']);
    }
  };

  const handleSignUp = values => {
    console.log(values, signUpData);
    let interest =
      values.productInterests && values.productInterests.length > 0
        ? values.productInterests.map(item => item.product_ID)
        : [];
    let body = {
      firstName: signUpData.firstName,
      lastName: signUpData.lastName,
      mobile: signUpData.phone.toString(),
      email: signUpData.email ?? '',
      password: signUpData.password,
      gmaps_address_string: signUpData.addressString,
      latitude: signUpData.addressLatLng.lat.toString(),
      longitude: signUpData.addressLatLng.lng.toString(),
      roof_size: values.roofArea ?? 0,
      terrace_yard_size: values.pergolaArea ?? 0,
      energy_consumption: values.energyMode === '2' ? values.energyValue : 0,
      previous_month_electricity_bill:
        values.energyMode === '1' ? values.energyValue : 0,
      profile_complete: true,
      interest,
    };

    signUpHandler(body);
  };

  const backOnSignUpHandler = () => {
    setSignUpErrorDialog(false);
  };

  return (
    <Grid
      container
      sx={{
        width: 1,
        mt: {xs: 0, sm: 0, md: 6, lg: 8},
        px: {xs: 4, sm: 4, md: 6, lg: 8, xl: 10},
      }}
      justifyContent="center"
    >
      <Dialog
        open={signUpErrorDialog}
        onClose={() => setSignUpErrorDialog(true)}
        sx={{p: 2}}
      >
        <DialogTitle>
          <Typography variant="h1" sx={{textTransform: 'capitalize'}}>
            {signUpErrorHeader}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {signUpErrorMessage.length > 0 &&
              signUpErrorMessage.map((msg, index) => (
                <Typography key={index} sx={{mt: 2}} variant="h3">
                  {msg}
                </Typography>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container sx={{width: 1, p: 2}} justifyContent="space-between">
            <Grid item>
              <Button
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  fontWeight: 700,
                  fontSize: '1rem',
                  display: 'none',
                }}
              >
                Forgot Password
              </Button>{' '}
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={backOnSignUpHandler}
              >
                Close
              </Button>

              <Button
                variant="contained"
                color="secondary"
                target="_blank"
                onClick={() => {
                  setSignUpErrorDialog(false);
                  setCurrentPage(pageMapping.forgotPassword);
                }}
                autoFocus
                sx={{ml: 2}}
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <ProgressDialog
        open={openProgressDialog}
        setOpenDialog={setOpenProgressDialog}
      />

      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          {/* Back Button */}
          <Grid item>
            {currentSignUpStep > 2 && (
              <Button
                onClick={() => changeStep(false)}
                style={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  fontSize: '1rem',
                }}
              >
                &lt; Back
              </Button>
            )}
          </Grid>

          {/* Sign In Call To Action  */}
          <Grid item>
            <Grid container sx={{width: 1}} alignItems="center">
              <Typography>Already have an account?</Typography>
              <Button
                style={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  fontSize: '1rem',
                }}
                onClick={() => setCurrentPage(pageMapping.signIn)}
              >
                Sign In
              </Button>
            </Grid>
          </Grid>

          {/* Render Login Screens */}
          <Grid item xs={12} sx={{mt: {xs: 4, sm: 4, md: 8, lg: 8}}}>
            {currentSignUpStep === 1 ? (
              <PersonalDetails
                contactOptionMapping={contactOptionMapping}
                setSignUpData={setSignUpData}
                setCurrentSignUpStep={setCurrentSignUpStep}
                setOpenProgressDialog={setOpenProgressDialog}
                enqueueSnackbar={enqueueSnackbar}
              />
            ) : currentSignUpStep === 2 ? (
              <AddressDetails
                signUpData={signUpData}
                setSignUpData={setSignUpData}
                setCurrentSignUpStep={setCurrentSignUpStep}
              />
            ) : currentSignUpStep === 3 ? (
              <ProductPreview setCurrentSignUpStep={setCurrentSignUpStep} />
            ) : currentSignUpStep === 4 ? (
              <EnergyConsumptionDetails handleSignUp={handleSignUp} />
            ) : (
              <>no content</>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = state => {
  const {} = state.app;
  return {};
};
const mapDispatchToProps = dispatch => ({
  setUserAuthToken: userAuthToken => {
    dispatch({type: setUserAuthTokenAction, data: {userAuthToken}});
  },
  setAppBarPage: appBarPage => {
    dispatch({type: setAppBarPageAction, data: {appBarPage}});
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
