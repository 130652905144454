// Imports for the Roof Top Device Data component
import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@mui/styles';
import {connect} from 'react-redux';
//  import 'chartjs-adapter-luxon';

import {
  Button,
  Grid,
  Card,
  Typography,
  Tab,
  Tabs,
  CardMedia,
  CardContent,
  CardActions,
  FormControlLabel,
  Switch,
  CircularProgress,
  Box,
  Backdrop,
  IconButton,
  Dialog,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';

import BoltIcon from '@mui/icons-material/Bolt';

import DatePicker from '../components/DatePicker';
import {DateTime, Settings} from 'luxon';

import ContactUsImage from '../assets/images/contactUs.webp';
import LearnMoreImage from '../assets/images/learnMore.webp';

import {Bar} from 'react-chartjs-2';

import Chart from 'chart.js/auto';
import {useRef} from 'react';
import {max, sum} from 'lodash';
import {ConnectedTvSharp, RefreshOutlined} from '@mui/icons-material';
import ProgressDialog from '../components/ProgressDialog';

import {
  setPerformanceGraphMode as setPerformanceGraphModeAction,
  setSelectedSystemId as setSelectedSystemIdAction,
  setSystemDetail as setSystemDetailsAction,
  setCurrentStep as setCurrentStepAction,
} from '../constants/reduxActions';
import {useSnackbar} from 'notistack';

import {alpha, styled} from '@mui/material/styles';
import {pink} from '@mui/material/colors';

import {
  getDeviceStatus as getDeviceStatusRequest,
  getSysytemDetailsData,
  getSysytemDetailsData as getSysytemDetailsDataRequest,
} from '../util/ApiCalls/service';
import {getDayts} from '../util/UtilFunctions/timestampGenerator';
import BlockIcon from '@mui/icons-material/Block';

import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';

let pollingJob;
// 5 minutes
let pollingInterval = 1000 * 60 * 5;

Settings.defaultZone = 'Asia/Kolkata';
const useStyle = makeStyles(theme => ({
  mainContainer: {
    marginTop: 50,
    paddingLeft: '13%',
    paddingRight: '13%',
    paddingBottom: '2rem',
    height: 'calc(100vh - 175px)',

    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },
  electricityText: {
    fontWeight: 900,
    paddingRight: 5,
  },
  cardContainer: {
    padding: 15,
    borderRadius: 10,
    width: '100%',
  },
  media: {
    // this is the`className` passed to `CardMedia` later
    height: 250, // as an example I am modifying width and height
    width: '100%',
  },
  rootCard: {
    margin: 'auto',
    width: '100%',
    height: 'calc(100vh - 120px)',
    [theme.breakpoints.down('lg')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '110vh',
    },
    paddingBottom: '25px',
  },
}));

/*
    Function Name           : Report
    Description             : Define the component Report of two graph
    Parameters              : No Params
    Request                 : No Request Object
    Response                : Response consist react element
*/

const graphModes = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',
  lifetime: 'Lifetime',
  // custom: 'Custom',
};

//savings calculation per kWh
const co2savings = 0.709;
const coalSavings = 0.35;

const ColoredSwitch = styled(Switch)(({theme}) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#334D66',
    '&:hover': {
      backgroundColor: alpha('#334D66', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#334D66',
  },
}));
function Report(props) {
  const classes = useStyle();

  const powerDataref = useRef({
    current: null,
    previous: null,
  });
  //console.log(props);
  const [plotData, setPlotData] = useState({
    data: {labels: [], datasets: []},
    mode: graphModes.day,
  });

  const [showPrevGraphData, setShowPrevGraphData] = useState(false);
  const [showCurrentGraphData, setShowCurrentGraphData] = useState(true);
  const [hoveredObjectId, setHoveredObjectId] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const [noSiteFoundFlag, setNoSiteFoundFlag] = useState(false);

  const [date, setDate] = useState(DateTime.now());
  const [dateError, setDateError] = useState('');

  const [selectStatus, setSelectStatus] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);

  const [currentEnergyData, setCurrentEnergyData] = useState(null);
  const [previousEnergyData, setPreviousEnergyData] = useState(null);

  const [deviceStatusData, setDeviceStatusData] = useState(null);

  const [powerForDay, setPowerForDay] = useState(true);

  const [currentUpdatePlotParams, setCurrentUpdatePlotParams] = useState(null);

  const [systemsDetails, setSystemsDetails] = useState();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [, updateState] = React.useState();
  const [selectedSystem, setSelectedSystem] = useState(props.selectedSystemId);
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const selectSystemHandler = event => {
    if (event.target.value !== selectedSystem) {
      setOpenDialog(true);
      setSelectedSystem(event.target.value);
      updatePlot(
        currentUpdatePlotParams.mode,
        currentUpdatePlotParams.from,
        currentUpdatePlotParams.to,
        event.target.value
      );
    }
  };

  useEffect(() => {
    if (props.systemDetail && !selectedSystem) {
      setSelectedSystem(props.systemDetail[0].system_ID);
    }
  }, [props.systemDetail]);

  const getSystemDetails = () => {
    getSysytemDetailsData()
      .then(res => {
        props.setSystemDetail(res.message);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    if (!props.systemDetail) getSystemDetails();
  }, []);

  //console.log(props);

  const getEnergyData = (
    token,
    body,
    currentDataRequest,
    system_ID
  ) => {
    if (props.systemDetail) {
      if (currentDataRequest) {
        enqueueSnackbar('Requested power data');
      }
      let spotlightBackendUrl;
      if (process.env.REACT_APP_MODE == 'PROD') {
        spotlightBackendUrl = 'https://' + window.location.hostname;
      } else {
        spotlightBackendUrl = 'http://localhost:5001';
      }
      return new Promise((resolve, reject) => {
        //console.log(props);
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify(body),
        };

        fetch(
          `${spotlightBackendUrl}/api/data/power${
            system_ID ? `/${system_ID}` : ''
          }`,
          options
        )
          .then(resp => resp.json())
          .then(resp => {
            //console.log(resp.message.length);
            //console.log(resp);
            //console.trace();
            // setShowPrevGraphData(false);
            let tempData = resp.message;

            let timestamps = [];
            let energy = [];
            let power = [];

            if (tempData.length > 0) {
              if (body.data_range === graphModes.day.toLowerCase()) {
                const m = [...tempData];

                tempData = [];

                const dayTimestamps = getDayts(body.from_date);

                dayTimestamps.forEach(ts => {
                  const dataPoint = m.find(data => data.ts === ts);
                  if (dataPoint) {
                    tempData.push(dataPoint);
                  } else {
                    tempData.push({
                      ts,
                      energy: 0,
                      power: 0,
                    });
                  }
                });
              } else if (body.data_range === graphModes.week.toLowerCase()) {
                if (tempData.length < 7) {
                  let lastDate = DateTime.fromISO(
                    tempData[tempData.length - 1].ts
                  );
                  for (let i = tempData.length; i < 7; i++) {
                    lastDate = lastDate.plus({days: 1});
                    tempData = [
                      ...tempData,
                      {
                        ts: lastDate.toISODate(),
                        energy: 0,
                      },
                    ];
                  }
                }
              } else if (body.data_range === graphModes.month.toLowerCase()) {
                if (tempData.length > 0) {
                  const firstDateOfMonth = DateTime.fromISO(
                    tempData[0].ts
                  ).startOf('month');
                  const lastDayOfMonth = firstDateOfMonth
                    .endOf('month')
                    .plus({days: 1});

                  let currentDay = firstDateOfMonth;
                  let newArray = [];

                  while (
                    !currentDay
                      .startOf('day')
                      .equals(lastDayOfMonth.startOf('day'))
                  ) {
                    let x = tempData.filter(item =>
                      DateTime.fromISO(item.ts)
                        .startOf('day')
                        .equals(currentDay)
                    );

                    if (x.length < 1) {
                      newArray = [
                        ...newArray,
                        {ts: currentDay.toJSDate().toISOString(), energy: 0},
                      ];
                    } else {
                      newArray = [...newArray, x[0]];
                    }
                    currentDay = currentDay.plus({days: 1});
                  }
                  tempData = newArray;
                } else {
                  tempData = [];
                }
              } else if (body.data_range === graphModes.year.toLowerCase()) {
                let newArray = [];
                for (let i = 1; i <= 12; i++) {
                  let x = tempData.filter(item => parseInt(item.ts) === i);

                  if (x.length < 1) {
                    newArray = [...newArray, {ts: i, energy: 0}];
                  } else {
                    newArray = [...newArray, x[0]];
                  }
                }
                tempData = newArray;
              } else if (
                body.data_range === graphModes.lifetime.toLowerCase()
              ) {
                if (tempData.length < 6) {
                  let currentYear = parseInt(tempData[tempData.length - 1].ts);
                  for (let i = tempData.length; i < 6; i++) {
                    tempData = [...tempData, {ts: currentYear + 1, energy: 0}];
                    currentYear++;
                  }
                }

                if (currentDataRequest) {
                  enqueueSnackbar('Received progress data', {
                    variant: 'success',
                  });
                }
              }
              //console.log('before find', system_ID);
              let currentSystem = system_ID
                ? props.systemDetail.find(item => item.system_ID === system_ID)
                : props.systemDetail[0];

              //console.log(props.systemDetail);
              //console.log('currentSystem', currentSystem);
              tempData.forEach(item => {
                timestamps = [...timestamps, item.ts];
                energy = [...energy, item.energy];
                power = [
                  ...power,
                  currentSystem.convertToKw ? item.power / 4000 : item.power,
                ];
              });

              resolve({timestamps, energy, power});
            } else {
              setOpenDialog(false);
              resolve({timestamps, energy, power});
              console.log(body);
     
            }
          })
          .catch(err => {
            //console.log(err.message.length);
            //console.log(err);
            //console.trace();
            setOpenDialog(false);
            //console.log(body);
            setPlotData({
              data: {labels: [], datasets: []},
              mode: graphModes[body.data_range],
            });
            if (currentDataRequest) {
              enqueueSnackbar('Error requesting power data', {
                variant: 'error',
              });
            }
          });
      });
    }
  };

  const getTooltipTitle = dateTime => {
    let returnStatement;
    if (plotData.mode === graphModes.day) {
      returnStatement = `${DateTime.fromFormat(dateTime, 'FF').toFormat('t')}`;
    } else if (plotData.mode === graphModes.week) {
      const time = DateTime.fromISO(dateTime);
      returnStatement = `${time.toFormat('MMMM dd')}`;
    } else if (plotData.mode === graphModes.month) {
      const time = DateTime.fromISO(dateTime);
      returnStatement = `${time.toFormat('MMMM dd')}`;
    } else if (plotData.mode === graphModes.year) {
      const time = DateTime.fromFormat(String(dateTime), 'M');
      returnStatement = `${time.toFormat('MMM')}`;
    } else if (plotData.mode === graphModes.lifetime) {
      returnStatement = `${dateTime}`;
    }
    return returnStatement;
  };

  const getDeviceStatus = async () => {
    try {
      const deviceData = await getDeviceStatusRequest();
      setDeviceStatusData(deviceData.message);
    } catch (err) {
      //console.log(err);
    }
  };

  useEffect(() => {
    getDeviceStatus();
  }, []);

  const getDatasetAtEvent = dataset => {
    if (!dataset.length) return;

    const datasetIndex = dataset[0].datasetIndex;
  };

  const getElementAtEvent = element => {
    if (!element.length) return;
  };

  const getElementsAtEvent = elements => {
    if (!elements.length) return;
  };

  useEffect(() => {
    if (
      currentEnergyData &&
      previousEnergyData &&
      plotData &&
      props.systemDetail
    ) {
      updatePlotMode(currentEnergyData, previousEnergyData, plotData.mode);
    }
  }, [powerForDay]);

  const updatePlotMode = (currentEnergy, previousEnergy, mode, showPrevGD) => {
    const isPowerPlot = mode === graphModes.day && powerForDay; // check if it's a day plot. if yes, then plot power graph
    //console.trace();
    if (showPrevGraphData == true) setShowPrevGraphData(false);
    //console.log(showPrevGraphData);

    const datasets = [
      {
        type: 'line',
        label: 'Prev. week Energy Produced',
        data: isPowerPlot ? previousEnergy.power : previousEnergy.energy,
        borderColor: 'rgba(244, 205, 42, 1)',
        backgroundColor: 'rgba(244, 205, 42, 0.2)',
        pointRadius: 0,
        borderWidth: 1,
        fill: true,
        hidden: showPrevGD ? false : true || mode === 'Lifetime',
        tension: 0.5,
      },

      {
        type: mode === graphModes.day ? 'bar' : 'bar',
        label: 'Energy Produced',
        data: isPowerPlot ? currentEnergy.power : currentEnergy.energy,
        backgroundColor: 'rgba(0,32,64,0.8)',
        borderColor: 'rgba(0,32,64, 1)',
        fill: true,
        borderWidth: 1,
        pointRadius: 0,
        hidden: !showCurrentGraphData,
        tension: 0.5,
      },
    ];

    const data = {
      labels: currentEnergy.timestamps,
      datasets,
    };

    powerDataref.current = {current: currentEnergy, previous: previousEnergy};
    setPlotData({data, mode: mode});
    setSelectStatus(false);
    setOpenDialog(false);
  };

  // from and to  should be DateTime objects.
  const updatePlot = async (mode, from, to, selectedSystem) => {
    //console.trace();
    // for refresh
    setCurrentUpdatePlotParams({mode, from, to});

    // reset all the values, before updating. Otherwise user sees old values till the fetch request gets new data.
    // powerDataref.current = {current: null, previous: null};
    // setPlotData({data: {labels: [], datasets: []}, mode: mode});
    console.log(from, to);
    const currentEnergy = await getEnergyData(
      props.userAuthToken,
      {
        data_range: mode.toLowerCase(),
        from_date: from.toUTC().toString(),
        to_date: to.toUTC().toString(),
        year: to.toFormat('yyyy'),
      },
      true,
      selectedSystem
    );

    //setShowPrevGraphData(false);

    setCurrentEnergyData(currentEnergy);
    updatePlotMode(currentEnergy, currentEnergy, mode, false);

    let prevFrom, prevTo;
    if (mode === graphModes.day) {
      prevFrom = from.minus({days: 1});
      prevTo = prevFrom.endOf('day');
    } else if (mode === graphModes.week) {
      prevFrom = from.minus({days: 7});
      prevTo = prevFrom.endOf('week');
    } else if (mode === graphModes.month) {
      let monthBackMoment = from.minus({months: 1});
      prevFrom = monthBackMoment.startOf('month');
      prevTo = monthBackMoment.endOf('month');
    } else if (mode === graphModes.year) {
      let yearBackMoment = from.minus({years: 1});
      prevFrom = yearBackMoment.startOf('year');
      prevTo = yearBackMoment.endOf('year');
    } else if (mode === graphModes.lifetime) {
      let yearBackMoment = from.minus({years: 1});
      prevFrom = yearBackMoment.startOf('year');
      prevTo = yearBackMoment.endOf('year');
    }
    console.log(currentEnergy);

    const previousEnergy = await getEnergyData(
      props.userAuthToken,
      {
        data_range: mode.toLowerCase(),
        from_date: prevFrom.toUTC().toString(),
        to_date: prevTo.toUTC().toString(),
        year: prevTo.toFormat('yyyy'),
      },
      false,
      selectedSystem
    );

    console.log(previousEnergy,currentEnergy)
    if(previousEnergy.length === 0 && currentEnergy.length=== 0){
      powerDataref.current = {
        current: null,
        previous: null,
      };
      setPlotData({
        data: {labels: [], datasets: []},
        mode: plotData.mode,
      });
    }
    //console.log(previousEnergy);
    setPreviousEnergyData(previousEnergy);
    //setShowPrevGraphData(false);
    updatePlotMode(currentEnergy, previousEnergy, mode, false);
  };

  const handleChangeTab = (event, value) => {
    setShowPrevGraphData(false);
    setTabValue(value);
    setSelectStatus(true);
    setOpenDialog(true);

    let mode, from, to;
    const date = DateTime.now();

    if (value === 0) {
      mode = graphModes.day;
      from = date.startOf('day');
      to = date.endOf('day');
    } else if (value === 1) {
      mode = graphModes.week;
      from = date.startOf('week');
      to = date.endOf('week');
    } else if (value === 2) {
      mode = graphModes.month;
      from = date.startOf('month');
      to = date.endOf('month');
    } else if (value === 3) {
      mode = graphModes.year;
      from = date.startOf('year');
      to = date.endOf('year');
    } else if (value === 4) {
      mode = graphModes.lifetime;
      from = date.startOf('year');
      to = date.endOf('year');
    } else if (value === 5) {
      mode = graphModes.custom;
    }

    setDate(from);

    updatePlot(mode, from, to, selectedSystem);
  };

  // useEffect(() => {}, [tabValue]);

  useEffect(() => {
    const {data, mode} = plotData;
    const {datasets, labels} = data;

    const tempDatasets = [
      {...datasets[0], hidden: !showPrevGraphData},
      {...datasets[1], hidden: !showCurrentGraphData},
    ];

    // console.log({data: {datasets: tempDatasets, labels}, mode});
    setPlotData({data: {datasets: tempDatasets, labels}, mode});
  }, [showCurrentGraphData, showPrevGraphData]);

  useEffect(() => {
    //console.trace();
    // console.log(showPrevGraphData);
    setShowPrevGraphData(false);
    // console.log(showPrevGraphData);
  }, [currentEnergyData, previousEnergyData]);
  // useEffect(() => {
  //   currencyEquivalent = getCurrencyEquivalent(plotData.mode);
  // }, [powerDataref]);

  useEffect(() => {
    if (props.systemDetail) {
      setOpenDialog(true);
      if (props.performanceGraphMode === graphModes.day) {
        updatePlot(
          graphModes.day,
          DateTime.now().startOf('day'),
          DateTime.now().endOf('day'),
          props.selectedSystemId
        );
        setTabValue(0);
      } else if (props.performanceGraphMode === graphModes.month) {
        updatePlot(
          graphModes.month,
          DateTime.now().startOf('month'),
          DateTime.now().endOf('month'),
          props.selectedSystemId
        );
        setTabValue(2);
      } else if (props.performanceGraphMode === graphModes.year) {
        updatePlot(
          graphModes.year,
          DateTime.now().startOf('year'),
          DateTime.now().endOf('year'),
          null
        );
        setTabValue(3);
      } else if (props.performanceGraphMode === graphModes.lifetime) {
        updatePlot(
          graphModes.lifetime,
          DateTime.now().startOf('year'),
          DateTime.now().endOf('year'),
          props.selectedSystemId
        );
        setTabValue(4);
      } else {
        updatePlot(
          graphModes.day,
          DateTime.now().startOf('day'),
          DateTime.now().endOf('day'),
          props.selectedSystemId
        );
      }

      return () => {
        props.setPerformanceGraphMode(null);
      };
    }
  }, [props.systemDetail]);

  // d is in IST---> new Date()
  const formatDate = d => {
    const date = d;

    let formatedDate;

    if (plotData.mode === graphModes.day) {
      formatedDate = `${date.toFormat('MMMM dd, yyyy')}`;
    } else if (plotData.mode === graphModes.week) {
      formatedDate = `${date.toFormat('MMM dd - ')} ${date
        .plus({days: 6})
        .toFormat('MMM dd')}`;
    } else if (plotData.mode === graphModes.month) {
      formatedDate = `${date.toFormat('MMMM yyyy')}`;
    } else if (plotData.mode === graphModes.year) {
      formatedDate = `${date.toFormat('yyyy')}`;
    }
    return formatedDate;
  };

  const formatPrevDate = d => {
    let returnDate;

    const date = DateTime.fromJSDate(new Date(d));
    if (plotData.mode === graphModes.day) {
      returnDate = date.minus({days: 1});
    } else if (plotData.mode === graphModes.week) {
      returnDate = date.minus({days: 7});
    } else if (plotData.mode === graphModes.month) {
      returnDate = date.minus({months: 1});
    } else if (plotData.mode === graphModes.year) {
      returnDate = date.minus({years: 1});
    }
    return formatDate(returnDate);
  };
  const formatPowerConsumption = v => {
    if (v === undefined || v === null) {
      return v;
    }

    return Math.round(v * 100) / 100;
  };

  const getDisplayValues = (mode, dayType, index, isCummulativeOn) => {
    //console.log(index, powerDataref.current);
    if (!powerDataref.current[dayType]) return `- -:- -`;

    let value;

    if (mode === graphModes.day) {
      if (index === null) {
        value = max(powerDataref.current[dayType]['energy']);
      } else if (isCummulativeOn) {
        value = powerDataref.current[dayType]['energy'][index];
      } else {
        value = powerDataref.current[dayType]['power'][index];
      }
    } else {
      if (index === null) {
        value = sum(powerDataref.current[dayType]['energy']);
      } else {
        value = powerDataref.current[dayType]['energy'][index];
      }
    }

    if (value === undefined) {
      return `- -:- -`;
    }

    value = formatPowerConsumption(value);

    return `${value} kWh`;
  };

  const getCurrencyEquivalent = mode => {
    //console.log(powerDataref, !powerDataref.current.current);
    if (!powerDataref.current.current) return {energy: 0.0, currency: 0};
    let value;
    if (mode === graphModes.day) {
      value = max(powerDataref.current.current['energy']);
    } else {
      value = sum(powerDataref.current.current['energy']);
    }
    value = formatPowerConsumption(value);
    return {energy: value, currency: value * 8};
  };

  const nanCheck = value => (isNaN(value) ? 0 : value);

  const currencyEquivalent = getCurrencyEquivalent(plotData.mode);
  const handleClose = () => {
    setOpen(false);
  };

  const getUpdatedData = () => {
    updatePlot(
      currentUpdatePlotParams.mode,
      currentUpdatePlotParams.from,
      currentUpdatePlotParams.to,
      selectedSystem
    );
  };

  const refreshData = () => {
    setOpenDialog(true);
    getUpdatedData();
    getDeviceStatus();
  };

  useEffect(() => {
    pollingJob = setTimeout(() => {
      getUpdatedData();
    }, pollingInterval);
    return () => {
      clearInterval(pollingJob);
    };
  });

  return (
    <div>
      {noSiteFoundFlag ? (
        <div>
          <Grid
            container
            justifyContent="center"
            className={classes.mainContainer}
          >
            <Grid container justifyContent="center" item md={12} lg={6}>
              <Card
                raised
                style={{width: '60%', height: '400px'}}
                className={classes.cardContainer}
              >
                <CardMedia
                  image={ContactUsImage}
                  title="Contact Us"
                  className={classes.media}
                />
                <CardContent>
                  <Typography variant="h3">
                    Intelligent Roofs! We are an R&D team building high
                    performance, solar roofs.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    href="https://arka.energy/"
                  >
                    Contact Us
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid container justifyContent="center" item md={12} lg={6}>
              <Card
                raised
                style={{width: '60%', height: '400px'}}
                className={classes.cardContainer}
              >
                <CardMedia
                  image={LearnMoreImage}
                  title="Learn More"
                  className={classes.media}
                />
                <CardContent>
                  <Typography variant="h3">
                    Intelligent Roofs! We are an R&D team building high
                    performance, solar roofs.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    href="https://arka.energy/"
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          {/*Custom CircularProgress Bar */}
          <Dialog open={openDialog}></Dialog>

          {/* <ProgressDialog open={openDialog} setOpenDialog={setOpenDialog} /> */}
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              alignItems: {xs: 'flex-start', sm: 'flex-start', md: 'center'},
              flexDirection: {
                xs: 'column-reverse',
                sm: 'column-reverse',
                md: 'initial',
              },
            }}
          >
            <Grid
              item
              container
              sx={{backgroundColor: 'none'}}
              justifyContent={'space-between'}
            >
              {/* {console.log(handleChangeTab)} */}
              <Tabs
                value={tabValue}
                indicatorColor="secondary"
                textColor="primary"
                onChange={handleChangeTab}
                aria-label="scrollable auto tabs example"
                variant="scrollable"
                scrollButtons="auto"
                style={{marginBottom: '3px'}}
                disabled={openDialog}
              >
                <Tab
                  label={graphModes.day}
                  sx={{minWidth: '5px'}}
                  disabled={openDialog}
                />
                <Tab
                  label={graphModes.week}
                  sx={{minWidth: '5px'}}
                  disabled={openDialog}
                />
                <Tab
                  label={graphModes.month}
                  sx={{minWidth: '5px'}}
                  disabled={openDialog}
                />
                <Tab
                  label={graphModes.year}
                  sx={{minWidth: '5px'}}
                  disabled={openDialog}
                />
                <Tab
                  label={graphModes.lifetime}
                  sx={{minWidth: '5px'}}
                  disabled={openDialog}
                />

                {/* <Tab label={graphModes.custom} disabled /> */}
              </Tabs>
              <Grid item>
                <Grid container alignItems={'center'}>
                  <IconButton onClick={refreshData} color="primary">
                    <RefreshOutlined />
                  </IconButton>
                  {deviceStatusData &&
                    deviceStatusData.map(item => {
                      if (item.system_ID === selectedSystem) {
                        if (item.connectionState === 'Connected') {
                          if (!openDialog) {
                            return <WifiIcon sx={{color: 'green', mr: 1}} />;
                          } else {
                            return <WifiOffIcon sx={{color: 'red', mr: 1}} />;
                          }
                        } else {
                          return <WifiOffIcon sx={{color: 'red', mr: 1}} />;
                        }
                      }
                    })}
                  {props.systemDetail && props.systemDetail.length > 1 ? (
                    <FormControl>
                      <Select
                        size="small"
                        sx={{minWidth: 100}}
                        value={selectedSystem}
                        onChange={selectSystemHandler}
                      >
                        {props.systemDetail.map((item, index) => (
                          <MenuItem value={item.system_ID}>
                            {item.system_ID}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Typography variant="h3" sx={{color: 'black'}}>
                      {selectedSystem}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Root Grid container */}
          <Grid container spacing={2} className={classes.rootCard}>
            {/* Left Tab contains graph, details and datepicker  */}
            <Grid
              container
              item
              md={12}
              lg={12}
              xl={12}
              spacing={1}
              justifyContent="center"
              style={{height: '100%'}}
            >
              {/* Savings container */}
              <Grid
                container
                item
                sm={12}
                md={plotData.mode === graphModes.lifetime ? 12 : 6}
                sx={{
                  height: {sm: 'initial', md: '33%'},
                  backgroundColor: 'none',
                }}
              >
                <Card
                  className={classes.cardContainer}
                  raised
                  sx={{py: 3, px: 3}}
                >
                  <Grid container height="100%" sx={{width: 1}}>
                    {/* Top Labels container */}
                    <Grid container item xs={12} justifyContent="space-between">
                      <Grid item xs={6}>
                        <Typography variant="h2">Savings</Typography>
                      </Grid>
                      <Grid container item xs={6} justifyContent="flex-end">
                        {/* <BoltIcon fontSize="large" color="secondary" />
                        <Typography
                          color="secondary"
                          variant="h2"
                          className={classes.electricityText}
                        >
                          {isNaN(currencyEquivalent.energy)
                            ? 0
                            : currencyEquivalent.energy}
                        </Typography>
                        <Typography color="secondary" variant="h2">
                          kWh
                        </Typography> */}
                      </Grid>
                    </Grid>
                    {/*Savings Values */}
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{textAlign: 'center', mt: {xs: 2, sm: 2, md: 0}}}
                    >
                      {/* Electricity Bills */}
                      <Grid item xs={4} sx={{px: {xs: 1}}}>
                        <Typography variant="h2" color="secondary">
                          {props.currencySymbol +
                            nanCheck(currencyEquivalent.currency).toFixed(2)}
                        </Typography>
                        <Typography variant="h4">
                          {'in electricity bills'}
                        </Typography>
                      </Grid>
                      {/* carbon emissions avoided */}
                      <Grid item xs={4}>
                        <Typography variant="h2" color="secondary">
                          {nanCheck(
                            currencyEquivalent.energy * co2savings
                          ).toFixed(2) + ' kgs'}
                        </Typography>
                        <Typography variant="h4">
                          {' '}
                          in CO<sub>2</sub> emissions avoided
                        </Typography>
                      </Grid>
                      {/* Coal Saved */}
                      <Grid item xs={4}>
                        <Typography variant="h2" color="secondary">
                          {nanCheck(
                            currencyEquivalent.energy * coalSavings
                          ).toFixed(2) + ' kgs'}
                        </Typography>
                        <Typography variant="h4"> of coal saved</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              {/* Details and Date Picker */}
              {plotData.mode !== graphModes.lifetime && (
                <Grid
                  container
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  sx={{
                    height: {sm: 'initial', md: '33%'},
                    backgroundColor: 'none',
                  }}
                >
                  <Card className={classes.cardContainer} raised>
                    <Grid container height="100%">
                      <Grid item container xs={12}>
                        <Grid
                          container
                          item
                          xs={12}
                          alignItems="center"
                          sx={{
                            mb: {xs: 1, sm: 1, md: 0},
                            backgroundColor: 'none',
                          }}
                        >
                          {plotData.mode === graphModes.day && (
                            <Grid item container>
                              <Grid
                                item
                                md={8}
                                sm={8}
                                xs={12}
                                // Equal spacing for all types of devices.
                                style={{
                                  textAlign: 'center',
                                  paddingBottom: {xs: 0, sm: 0, md: 0, lg: 0},
                                }}
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                sx={{backgroundColor: 'none'}}
                              >
                                <DatePicker
                                  current={date}
                                  setDate={value => {
                                    setDate(value);
                                    //setShowPrevGraphData(false);
                                  }}
                                  setSelectStatus={value => {
                                    setSelectStatus(value);
                                  }}
                                  getPowerData={updatePlot}
                                  selectedSystem={selectedSystem}
                                  errorText={dateError}
                                  graphMode={plotData.mode}
                                  graphModes={graphModes}
                                  minDate={new Date('2020-07-09')}
                                  setOpenDialog={setOpenDialog}
                                  disabled={openDialog}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                  }}
                                  // maxDate={'2021-08-30'}
                                />
                              </Grid>

                              <Grid
                                item
                                md={4}
                                sm={4}
                                xs={12}
                                container
                                justifyContent={'center'}
                                alignItems="center"
                                sx={{
                                  mt: {xs: 1, sm: 1, md: 0},
                                  backgroundColor: 'none',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={!powerForDay}
                                      onChange={() =>
                                        setPowerForDay(!powerForDay)
                                      }
                                      name="checkedB"
                                      color="secondary"
                                      sx={{mt: {sm: 0}}}
                                      disabled={openDialog}
                                      size="small"
                                    />
                                  }
                                  label={
                                    <Grid container>
                                      <Typography
                                        variant="h4"
                                        sx={{
                                          display: {
                                            xs: 'initial',
                                            sm: 'initial',
                                            md: 'initial',
                                          },
                                        }}
                                      >
                                        {'Cumulative '}
                                      </Typography>
                                      &nbsp;
                                      <Typography variant="h4">
                                        {'Energy'}
                                      </Typography>
                                    </Grid>
                                  }
                                />
                              </Grid>
                            </Grid>
                          )}
                          {plotData.mode !== graphModes.day && (
                            <Grid
                              item
                              //md={8}
                              //sm={8}
                              xs={12}
                              // Equal spacing for all types of devices.
                              style={{
                                textAlign: 'center',
                                paddingBottom: {xs: 0, sm: 0, md: 0, lg: 0},
                              }}
                              display="flex"
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              sx={{backgroundColor: 'none'}}
                            >
                              <DatePicker
                                current={date}
                                setDate={value => {
                                  setDate(value);
                                  // setShowPrevGraphData(false);
                                }}
                                setSelectStatus={value => {
                                  setSelectStatus(value);
                                }}
                                getPowerData={updatePlot}
                                selectedSystem={selectedSystem}
                                errorText={dateError}
                                graphMode={plotData.mode}
                                graphModes={graphModes}
                                minDate={new Date('2020-07-09')}
                                setOpenDialog={setOpenDialog}
                                disabled={openDialog}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                }}
                                // maxDate={'2021-08-30'}
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          alignItems="center"
                          sx={{}}
                        >
                          {/* Todays power hover tooltip */}
                          <Grid container item xs={6} sm={6} md={6}>
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="center"
                              alignItems="flex-end"
                            >
                              <BoltIcon fontSize="large" color="secondary" />
                              <Typography
                                color="secondary"
                                variant="h2"
                                className={classes.electricityText}
                              >
                                {/* {console.log(powerForDay)} */}
                                {getDisplayValues(
                                  plotData.mode,
                                  'current',
                                  hoveredObjectId,
                                  !powerForDay
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="center"
                            >
                              <Grid
                                item
                                container
                                justifyContent="center"
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{backgroundColor: 'none'}}
                              >
                                {/* {console.log(showCurrentGraphData)} */}
                                <FormControlLabel
                                  control={
                                    <ColoredSwitch
                                      checked={showCurrentGraphData}
                                      onChange={() =>
                                        setShowCurrentGraphData(
                                          !showCurrentGraphData
                                        )
                                      }
                                      name="checkedB"
                                      disabled={openDialog}
                                      size="small"
                                    />
                                  }
                                  label={
                                    <Typography variant="h4">
                                      {formatDate(date)}
                                    </Typography>
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* Yesterdays power hover tooltip */}
                          <Grid
                            container
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            justifyContent="center"
                          >
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="center"
                              alignItems="flex-end"
                            >
                              <BoltIcon fontSize="large" color="secondary" />
                              <Typography
                                color="secondary"
                                variant="h2"
                                className={classes.electricityText}
                              >
                                {getDisplayValues(
                                  plotData.mode,
                                  'previous',
                                  hoveredObjectId,
                                  !powerForDay
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="center"
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                container
                                justifyContent={'center'}
                              >
                                {' '}
                                {/* {console.log(showPrevGraphData)} */}
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={showPrevGraphData}
                                      onChange={() =>
                                        setShowPrevGraphData(!showPrevGraphData)
                                      }
                                      name="checkedB"
                                      color="secondary"
                                      disabled={openDialog}
                                      size="small"
                                    />
                                  }
                                  label={
                                    <Typography variant="h4">
                                      {formatPrevDate(date)}
                                    </Typography>
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* Comparison radio buttons */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )}

              {/* Graph */}
              <Grid
                container
                item
                md={12}
                xs={12}
                justifyContent="center"
                sx={{maxHeight: {xs: '58%', md: '66%', height: 'inherit'}}}
              >
                <Card
                  raised
                  style={{
                    padding: 10,
                    borderRadius: 10,
                    height: '101%',
                    width: '100%',
                  }}
                >
                  {/* {console.log(plotData, openDialog)} */}
                  {openDialog ? (
                    <Grid
                      container
                      sx={{
                        width: 1,

                        height: '100%',
                      }}
                      justifyContent="center"
                      alignItems={'center'}
                    >
                      <Grid item style={{textAlign: 'center'}}>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  ) : plotData &&
                    plotData.data &&
                    plotData.data.labels &&
                    plotData.data.labels.length > 0 ? (
                    <Bar
                      data={plotData.data}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        onClick: (event, chartElement) => {
                          if (
                            plotData.mode === graphModes.day ||
                            chartElement.length === 0
                          )
                            return;

                          event.native.target.style.cursor = 'default';

                          setOpenDialog(true);

                          if (chartElement.length > 0) {
                            //get clicked item data

                            let mode, from, to, tValue, pickerDate;

                            if (
                              plotData.mode === graphModes.week ||
                              plotData.mode === graphModes.month
                            ) {
                              const tempDate = DateTime.fromISO(
                                plotData.data.labels[chartElement[0].index]
                              );
                              from = tempDate.startOf('day');
                              to = tempDate.endOf('day');
                              mode = graphModes.day;
                              pickerDate = tempDate;
                              tValue = 0;
                            } else if (plotData.mode === graphModes.year) {
                              const tempDate = date.startOf('year').plus({
                                month:
                                  plotData.data.labels[chartElement[0].index] -
                                  1,
                              });

                              from = tempDate.startOf('month');
                              to = tempDate.endOf('month');
                              mode = graphModes.month;
                              pickerDate = tempDate;
                              tValue = 2;
                            } else if (plotData.mode === graphModes.lifetime) {
                              const tempDate = DateTime.fromObject({
                                year: plotData.data.labels[
                                  chartElement[0].index
                                ],
                              });
                              mode = graphModes.year;
                              from = tempDate.startOf('year');
                              to = tempDate.endOf('year');
                              pickerDate = tempDate;
                              tValue = 3;
                            }

                            setDate(pickerDate);
                            setTabValue(tValue);
                            updatePlot(mode, from, to, selectedSystem);
                          }
                        },
                        onHover: (event, chartElement) => {
                          if (plotData.mode !== graphModes.day) {
                            if (chartElement.length == 1) {
                              event.native.target.style.cursor = 'pointer';
                            }
                            if (chartElement.length == 0) {
                              event.native.target.style.cursor = 'default';
                            }
                          }
                          setHoveredObjectId(null);
                        },
                        plugins: {
                          title: {
                            display: false,
                          },
                          legend: {
                            display: false,
                          },

                          tooltip: {
                            callbacks: {
                              label: function (tooltipItem, data) {
                                setHoveredObjectId(tooltipItem.dataIndex);
                                // {
                                //   console.log(tooltipItem);
                                // }
                                return (
                                  parseFloat(tooltipItem.raw).toFixed(2) + 'kWh'
                                );
                              },
                              title: function (tooltipItem) {
                                return (
                                  `Production for ` +
                                  getTooltipTitle(tooltipItem[0].label)
                                );
                              },
                            },
                          },
                        },
                        scales: {
                          y: {
                            grace: '20%',
                            beginAtZero: true,
                            title: {
                              display: true,

                              font: {
                                weight: 550,
                              },
                              text: 'Energy (kWh)',
                            },
                          },

                          x: {
                            ...(plotData.mode === graphModes.day && {
                              type: 'time',

                              adapters: {
                                date: {
                                  zone: 'UTC+05:30',
                                },
                              },

                              time: {
                                // tooltipFormat: ' t',
                                displayFormats: {
                                  hour: 't',
                                },
                              },
                              min: date
                                .startOf('day')
                                .plus({hours: 4})
                                .toUTC()
                                .toString(),
                              max: date
                                .endOf('day')
                                .minus({hours: 3})
                                .toUTC()
                                .toString(),
                            }),

                            ticks: {
                              autoSkipPadding: 10,
                              maxRotation: 0,

                              ...(plotData.mode !== graphModes.day && {
                                callback: function (val, index, labels) {
                                  const label = this.getLabelForValue(val);

                                  // convert utc to local time

                                  if (plotData.mode === graphModes.week) {
                                    const time = DateTime.fromISO(label);
                                    return time.toFormat('EEE');
                                  } else if (
                                    plotData.mode === graphModes.month
                                  ) {
                                    const time = DateTime.fromISO(label);
                                    return time.day;
                                  } else if (
                                    plotData.mode === graphModes.year
                                  ) {
                                    const time = DateTime.fromFormat(
                                      String(label),
                                      'M'
                                    ).toFormat('MMM');

                                    return time;
                                  }

                                  return label;
                                },
                              }),
                            },
                          },
                        },
                      }}
                      // getDatasetAtEvent={getDatasetAtEvent}
                      // getElementAtEvent={getElementAtEvent}
                      // getElementsAtEvent={getElementsAtEvent}
                    />
                  ) : (
                    <Grid
                      container
                      sx={{
                        width: 1,

                        height: '100%',
                      }}
                      justifyContent="center"
                      alignItems={'center'}
                    >
                      <Grid item style={{textAlign: 'center'}}>
                        <BlockIcon
                          color="secondary"
                          sx={{transform: 'scale(5)'}}
                        ></BlockIcon>
                        <Typography variant="h2" sx={{mt: 5}}>
                          No data found for requested time frame
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Grid>
          {/* </Body> */}
        </div>
      )}
    </div>
  );
}
const mapStateToProps = state => {
  const {
    userAuthToken,
    performanceGraphMode,
    systemDetail,
    selectedSystemId,
    currencySymbol,
  } = state.app;
  return {
    userAuthToken,
    performanceGraphMode,
    systemDetail,
    selectedSystemId,
    currencySymbol,
  };
};
const mapDispatchToProps = dispatch => ({
  setPerformanceGraphMode: performanceGraphMode => {
    dispatch({
      type: setPerformanceGraphModeAction,
      data: {performanceGraphMode},
    });
  },
  setSelectedSystemId: selectedSystemId => {
    dispatch({
      type: setSelectedSystemIdAction,
      data: {selectedSystemId},
    });
  },
  setSystemDetail: systemDetail => {
    dispatch({
      type: setSystemDetailsAction,
      data: {systemDetail},
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Report);
